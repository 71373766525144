function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { CREDITS, CREDIT_CARD, GIVEAWAY_PAY_POPUP_FLOW } from 'swag-common/constants/payment-methods';
import { emailSelector, userIdSelector, userSelector } from 'swag-client-common/redux/user/user.selectors';
import { saveGiveawayModelActionFactory, updateActiveGiveawayModelActionFactory } from 'swag-client-common/redux/partials/giveaway-selected/giveaway-selected.actions';
import { DEMO_INVENTORY_WARNING_POPUP, GIVEAWAY_PAYMENT_SUCCESS_POPUP, GIVEAWAY_BILLING_POPUP, GIVEAWAY_FEES_POPUP, GIVEAWAY_NOTECARD_NOT_ENOUGH_POPUP, GIVEAWAY_NOTECARD_OUT_OF_STOCK_POPUP } from 'swag-client-common/constants';
import { INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
import { InventoryApi } from '../../../api/inventory.api';
import { inventoryPaymentBillingFormSelector, paymentMethodIdSelector, isManualPayment, isAutoPaySelected, selectedPaymentMethodIdSelector } from '../../payment-methods/payment-methods.selectors';
import { checkAccessRights, checkShutdownRestriction } from '../../../utils/enterprise-access-wrapper';
import { giveawayPaymentSelectedRecipientsListSelector, giveawayTotalCostWithPromocodeSelector, giveawayPaymentSearchAndFilterResultsSelector, payFeesSelectedRecipientsCountSelector, giveawayPaymentProductsMapSelector } from '../selectors/giveaway-pay-fees-popup.selectors';
import { giveawayIdSelector, selectedGiveawaySelector } from '../selectors/common.selectors';
import { promocodeSelector, promocodeValueSelector } from '../../payment/payment.selectors';
import { paymentMethodSelector, selectedInventorySelector, isSelectedDemoInventorySelector, isCreditsMoreToBuyFlowSelector } from '../../inventory/inventory.selectors';
import { removePromocode } from '../../payment/payment.actions';
import { STRIPE_FORM_SUBMIT_SUCCESS, STRIPE_FORM_SUBMIT_ERROR } from '../../stripe-form/stripe-form.actions';
import { getInventoryCredits, resetCreditsMoreToBuyFlow, setInventoryPurchaseFlow } from '../../inventory/inventory.actions';
import { getPaymentMethods, preparePaymentToInventoryProceed, preselectCreditCard } from '../../payment-methods/payment-methods.actions';
import { giveawayEditedModelInitialState } from '../reducers/giveaway-edited-model.reducer';
import { giveawayNotecardSelector } from '../selectors/giveaway-selected.selectors';
import { updateProductDetails, updateProductInStock } from '../../products/products.actions';
import { productsListSelector } from '../../products/products.selectors';
import { mapProductsWithUpdatedQuantities } from '../giveaway.utils';
import { GIVEAWAY_ADMIN_PREFIX } from './giveaway-common.actions';
import { restoreFilterAndSearchValues, setGiveawayPayPopupFlowAction } from './giveaway-pay-fees-popup.actions';
export const RESET_GIVEAWAY_PAYMENT = `${GIVEAWAY_ADMIN_PREFIX}RESET_GIVEAWAY_PAYMENT`;
export const resetGiveawayPayment = () => ({
  type: RESET_GIVEAWAY_PAYMENT
});
export const PAY_FOR_GIVEAWAY_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}PAY_FOR_GIVEAWAY_SUCCESS`;
export const payForGiveawayRequest = createAsyncLoadedAction({
  transformData: ({
    token,
    savedCard
  }, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const giveawayId = giveawayIdSelector(state);
    const promocode = promocodeValueSelector(state);
    const selectedIds = giveawayPaymentSelectedRecipientsListSelector(state);
    const paymentMethod = paymentMethodSelector(state);
    const searchResults = giveawayPaymentSearchAndFilterResultsSelector(state);
    const ids = [];
    if (searchResults.length && selectedIds.length) {
      ids.push(...selectedIds.filter(id => searchResults.includes(id)));
    } else {
      ids.push(...selectedIds);
    }
    const saveAutopay = isAutoPaySelected(state);
    const commonBody = {
      inventoryId,
      giveawayId,
      promocode,
      saveAutopay,
      paymentMethod,
      selectedIds: ids,
      isAllSelected: false
    };
    const form = inventoryPaymentBillingFormSelector(state);
    if (savedCard) {
      const cardId = paymentMethodIdSelector(state);
      return _objectSpread({}, commonBody, {
        cardId
      });
    }
    if (form) {
      const {
        savePaymentDetails
      } = form.values;
      return _objectSpread({}, commonBody, {
        stripeToken: token.id,
        savePaymentDetails
      });
    }
    return _objectSpread({}, commonBody, {
      stripeToken: token ? token.id : null
    });
  },
  fn: InventoryApi.giveaway.payFees,
  success: ({
    isApprovalFlow,
    charge,
    giveawayId
  }) => async (dispatch, getState) => {
    const state = getState();
    const promocode = promocodeSelector(state);
    const amount = giveawayTotalCostWithPromocodeSelector(state);
    const paymentMethod = paymentMethodSelector(state);
    const payFeesRecipientsCount = payFeesSelectedRecipientsCountSelector(state);
    const payFeesRecipientsProductsMap = giveawayPaymentProductsMapSelector(state);
    dispatch(restoreFilterAndSearchValues());
    dispatch({
      type: STRIPE_FORM_SUBMIT_SUCCESS
    });
    const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(state);
    if (isCreditsToBuyMoreFlow) {
      dispatch(resetCreditsMoreToBuyFlow());
    }
    dispatch(setInventoryPurchaseFlow(null));
    dispatch(removePromocode());
    const userId = userIdSelector(state);
    const email = emailSelector(state);
    const inventoryId = selectedInventorySelector(state);
    dispatch({
      type: PAY_FOR_GIVEAWAY_SUCCESS,
      payload: {
        userId,
        email,
        inventoryId,
        giveawayId,
        amount: charge.amount
      }
    });
    const giveaway = selectedGiveawaySelector(getState());
    if (giveaway && charge) {
      dispatch(saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(_objectSpread({}, giveaway, {
        charges: [...giveaway.charges, charge]
      })));
    }
    if (amount === 0 && promocode && !isApprovalFlow) {
      return dispatch(showPopup({
        popup: 'freePaymentSuccessPopup'
      }));
    }
    dispatch(showPopup({
      popup: GIVEAWAY_PAYMENT_SUCCESS_POPUP,
      isApprovalFlow
    }));
    if (paymentMethod === CREDITS) {
      const inventoryId = selectedInventorySelector(state);
      dispatch(getInventoryCredits({
        inventoryId
      }));
    }

    // update giveaway notecards quantity
    const giveawayNotecard = giveawayNotecardSelector(state);
    if (giveawayNotecard) {
      const {
        _id,
        inStock = 0
      } = giveawayNotecard;
      const nextInStock = payFeesRecipientsCount > inStock ? 0 : inStock - payFeesRecipientsCount;
      dispatch(updateProductInStock({
        productId: _id,
        inStock: nextInStock
      }));
    }

    // update giveaway products quantity
    const inventoryProducts = productsListSelector(state);
    const updatedProductsMap = mapProductsWithUpdatedQuantities(inventoryProducts, payFeesRecipientsProductsMap);
    Object.keys(updatedProductsMap).forEach(productId => dispatch(updateProductDetails(_objectSpread({
      _id: productId
    }, updatedProductsMap[productId]))));
  },
  postSuccess: (dispatch, getState, {
    saveAutopay,
    paymentMethod
  }, {
    charge
  }) => {
    dispatch(updateActiveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)({
      charge,
      saveAutopay,
      paymentMethod
    }));
  },
  error: error => dispatch => {
    dispatch(setInventoryPurchaseFlow(null));
    dispatch({
      type: STRIPE_FORM_SUBMIT_ERROR
    });
    dispatch(showNotification({
      text: error.message || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const payForGiveaway = checkAccessRights(payForGiveawayRequest, DemoInventoryWarningTypes.GiveawayPayment);
export const handleInventoryPaymentForGiveawaySubmit = (stripe, card) => (dispatch, getState) => {
  const isManualCard = isManualPayment(getState());
  if (isManualCard && card) {
    dispatch(preparePaymentToInventoryProceed(stripe, card, token => dispatch(payForGiveaway({
      token
    }))));
  }
  if (!isManualCard) {
    dispatch(payForGiveaway({
      savedCard: true
    }));
  }
};
export const deselectCompletedGiveaway = () => saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(giveawayEditedModelInitialState);
export const processPayment = () => (dispatch, getState) => {
  const state = getState();
  const isDemo = isSelectedDemoInventorySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayPayment
    }));
  }
  dispatch(setInventoryPurchaseFlow(INVENTORY_PURCHASE_FLOWS.GIVEAWAY));
  dispatch(showPopup({
    popup: GIVEAWAY_BILLING_POPUP
  }));
};
export const processGiveawayFeesPayment = (isNotecardsQuantityCheckSkipped = false) => (dispatch, getState) => {
  const state = getState();
  const giveawayNotecard = giveawayNotecardSelector(state);
  if (giveawayNotecard && !isNotecardsQuantityCheckSkipped) {
    const {
      inStock = 0
    } = giveawayNotecard || {};
    if (inStock === 0) {
      dispatch(showPopup({
        popup: GIVEAWAY_NOTECARD_OUT_OF_STOCK_POPUP
      }));
      return;
    }
    const recipientsQuantity = payFeesSelectedRecipientsCountSelector(state);
    if (recipientsQuantity > inStock) {
      dispatch(showPopup({
        popup: GIVEAWAY_NOTECARD_NOT_ENOUGH_POPUP
      }));
      return;
    }
  }
  dispatch(setGiveawayPayPopupFlowAction(GIVEAWAY_PAY_POPUP_FLOW.PAY_BY_LINK));
  const amount = giveawayTotalCostWithPromocodeSelector(state);
  if (amount === 0) {
    return dispatch(payForGiveaway({}));
  }
  dispatch(preselectCreditCard());
  dispatch(processPayment());
};
export const processGiveawayFeesPaymentWrappedIntoCheckShutdown = checkShutdownRestriction(processGiveawayFeesPayment);
export const UPDATE_AUTOPAY_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}UPDATE_AUTOPAY_SUCCESS`;
const updateAutopayRequest = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const giveaway = selectedGiveawaySelector(state);
    const inventoryId = selectedInventorySelector(state);
    if (!data) {
      return {
        inventoryId,
        giveawayId: giveaway === null || giveaway === void 0 ? void 0 : giveaway._id
      };
    }
    const {
      token,
      savedCard,
      paymentMethod
    } = data;
    const user = userSelector(state);
    const commonBody = {
      inventoryId,
      giveawayId: giveaway === null || giveaway === void 0 ? void 0 : giveaway._id,
      paymentMethod
    };
    const stripeData = token || savedCard;
    const resetAutopay = !stripeData && paymentMethod === CREDIT_CARD;
    if (paymentMethod === CREDITS || resetAutopay) {
      return commonBody;
    }
    if (savedCard) {
      const cardId = selectedPaymentMethodIdSelector(state);
      return _objectSpread({}, commonBody, {
        cardId
      });
    }
    return _objectSpread({}, commonBody, {
      stripeToken: token ? token.id : null,
      userId: user === null || user === void 0 ? void 0 : user._id
    });
  },
  fn: InventoryApi.giveaway.updateAutopay,
  success: ({
    giveawayId,
    autopayCard,
    autopayPaymentMethod
  }) => (dispatch, getState) => {
    const giveaway = selectedGiveawaySelector(getState());
    dispatch(saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(_objectSpread({}, giveaway, {
      autopayCard,
      autopayPaymentMethod
    })));
    const state = getState();
    const userId = userIdSelector(state);
    const email = emailSelector(state);
    const inventoryId = selectedInventorySelector(state);
    dispatch({
      type: UPDATE_AUTOPAY_SUCCESS,
      payload: {
        giveawayId,
        autopayCard,
        autopayPaymentMethod,
        userId,
        email,
        inventoryId,
        giveawayType: giveaway === null || giveaway === void 0 ? void 0 : giveaway.giveawayType
      }
    });
    const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(getState());
    if (isCreditsToBuyMoreFlow) {
      dispatch(resetCreditsMoreToBuyFlow());
    }
    const action = autopayPaymentMethod ? 'enabled' : 'disabled';
    dispatch(hidePopup());
    dispatch(showNotification({
      text: `Autopay has been ${action}`
    }));
    dispatch(getPaymentMethods());
  },
  error: error => dispatch => {
    dispatch(showNotification({
      text: error.message || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
    dispatch(hidePopup());
  }
});
export const updateAutopay = isEdit => (dispatch, getState) => {
  const state = getState();
  const isDemo = isSelectedDemoInventorySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayPayment
    }));
  }
  const giveaway = selectedGiveawaySelector(getState());
  const isAutopayGiveaway = giveaway === null || giveaway === void 0 ? void 0 : giveaway.autopayPaymentMethod;
  if (!isAutopayGiveaway || isEdit) {
    return dispatch(showPopup({
      popup: GIVEAWAY_FEES_POPUP
    }));
  }
  dispatch(updateAutopayRequest({
    paymentMethod: CREDIT_CARD
  }));
};
export const handleGiveawayAutopaySaveCard = (stripe, card) => (dispatch, getState) => {
  const isManualCard = isManualPayment(getState());
  const paymentMethod = paymentMethodSelector(getState());
  if (paymentMethod === CREDITS) {
    return dispatch(saveGiveawayAutopayCard({
      paymentMethod
    }));
  }
  if (isManualCard) {
    dispatch(preparePaymentToInventoryProceed(stripe, card, token => {
      dispatch(saveGiveawayAutopayCard({
        token,
        paymentMethod
      }));
      dispatch({
        type: STRIPE_FORM_SUBMIT_SUCCESS
      });
    }));
  } else {
    dispatch(saveGiveawayAutopayCard({
      savedCard: true,
      paymentMethod
    }));
  }
};
export const saveGiveawayAutopayCard = checkAccessRights(updateAutopayRequest, DemoInventoryWarningTypes.GiveawayPayment);