function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { SETTINGS_HISTORY_POPUP } from 'swag-client-common/constants';
import { SettingsTypes } from 'swag-common/interfaces';
import { shippingMarginSelector, spaceShippingMarginSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { SettingsApi } from '../../api/settings.api';
export const PREFIX = 'SETTINGS_';
export const UPDATE_SUPER_SPEED_SUCCESS = `${PREFIX}UPDATE_SUPER_SPEED_SUCCESS`;
export const UPDATE_SUPER_SPEED_ERROR = `${PREFIX}UPDATE_SUPER_SPEED_ERROR`;
export const updateSuperSpeedSettings = createAsyncAction({
  fn: SettingsApi.updateSuperSpeed,
  success: config => ({
    type: UPDATE_SUPER_SPEED_SUCCESS,
    payload: {
      config
    }
  }),
  error: errorAction(UPDATE_SUPER_SPEED_ERROR),
  postError: (dispatch, getState, error) => {
    if (error.status !== 403) {
      dispatch(showNotification({
        text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
      }));
    }
  },
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: 'Settings were successfully updated'
    }));
  }
});
export const GET_SETTINGS_REQUEST = `${PREFIX}GET_SETTINGS_REQUEST`;
export const GET_SETTINGS_SUCCESS = `${PREFIX}GET_SETTINGS_SUCCESS`;
export const GET_SETTINGS_FAILURE = `${PREFIX}GET_SETTINGS_FAILURE`;
export const getSettings = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SETTINGS_REQUEST
    });
  },
  transformData: () => ({
    types: [SettingsTypes.DefaultWarehouse, SettingsTypes.ShippingMargin, SettingsTypes.SpaceShippingMargin]
  }),
  fn: SettingsApi.getSettings,
  success: data => dispatch => {
    dispatch({
      type: GET_SETTINGS_SUCCESS,
      payload: data.settings
    });
  },
  error: error => ({
    type: GET_SETTINGS_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const UPDATE_DEFAULT_WAREHOUSE_REQUEST = `${PREFIX}UPDATE_DEFAULT_WAREHOUSE_REQUEST`;
export const UPDATE_DEFAULT_WAREHOUSE_SUCCESS = `${PREFIX}UPDATE_DEFAULT_WAREHOUSE_SUCCESS`;
export const UPDATE_DEFAULT_WAREHOUSE_FAILURE = `${PREFIX}UPDATE_DEFAULT_WAREHOUSE_FAILURE`;
export const updateDefaultWarehouse = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_DEFAULT_WAREHOUSE_REQUEST
    });
  },
  fn: SettingsApi.updateDefaultWarehouse,
  success: data => dispatch => {
    dispatch({
      type: UPDATE_DEFAULT_WAREHOUSE_SUCCESS,
      payload: data.defaultWarehouse
    });
  },
  error: error => ({
    type: UPDATE_DEFAULT_WAREHOUSE_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const UPDATE_SHIPPING_MARGIN = `${PREFIX}UPDATE_SHIPPING_MARGIN`;
export const updateShippingMargin = payload => ({
  type: UPDATE_SHIPPING_MARGIN,
  payload
});
export const UPDATE_SPACE_SHIPPING_MARGIN = `${PREFIX}UPDATE_SPACE_SHIPPING_MARGIN`;
export const updateSpaceShippingMargin = payload => ({
  type: UPDATE_SPACE_SHIPPING_MARGIN,
  payload
});
export const SAVE_SHIPPING_MARGIN_REQUEST = `${PREFIX}SAVE_SHIPPING_MARGIN_REQUEST`;
export const SAVE_SHIPPING_MARGIN_SUCCESS = `${PREFIX}SAVE_SHIPPING_MARGIN_SUCCESS`;
export const SAVE_SHIPPING_MARGIN_FAILURE = `${PREFIX}SAVE_SHIPPING_MARGIN_FAILURE`;
export const saveShippingMargin = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SAVE_SHIPPING_MARGIN_REQUEST
    });
  },
  transformData: (_data, getState) => {
    const shippingMargin = shippingMarginSelector(getState()) || {};
    return Object.keys(shippingMargin).reduce((acc, key) => _objectSpread({}, acc, {
      [key]: shippingMargin[key] / 100
    }), {
      domesticShippingMargin: 0,
      internationalShippingMargin: 0,
      taxesAndDutiesMargin: 0
    });
  },
  fn: SettingsApi.updateShippingMargin,
  success: data => dispatch => {
    dispatch({
      type: SAVE_SHIPPING_MARGIN_SUCCESS,
      payload: data.config
    });
  },
  error: error => ({
    type: SAVE_SHIPPING_MARGIN_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const SAVE_SPACE_SHIPPING_MARGIN_REQUEST = `${PREFIX}SAVE_SPACE_SHIPPING_MARGIN_REQUEST`;
export const SAVE_SPACE_SHIPPING_MARGIN_SUCCESS = `${PREFIX}SAVE_SPACE_SHIPPING_MARGIN_SUCCESS`;
export const SAVE_SPACE_SHIPPING_MARGIN_FAILURE = `${PREFIX}SAVE_SPACE_SHIPPING_MARGIN_FAILURE`;
export const saveSpaceShippingMargin = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: SAVE_SPACE_SHIPPING_MARGIN_REQUEST
    });
  },
  transformData: (data, getState) => {
    const shippingMargin = spaceShippingMarginSelector(getState()) || {};
    return Object.keys(shippingMargin).reduce((acc, key) => _objectSpread({}, acc, {
      [key]: shippingMargin[key] / 100
    }), {
      domesticShippingMargin: 0,
      internationalShippingMargin: 0,
      taxesAndDutiesMargin: 0
    });
  },
  fn: SettingsApi.updateSpaceShippingMargin,
  success: data => dispatch => {
    dispatch({
      type: SAVE_SPACE_SHIPPING_MARGIN_SUCCESS,
      payload: data.config
    });
  },
  error: error => ({
    type: SAVE_SPACE_SHIPPING_MARGIN_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const GET_SETTINGS_HISTORY_REQUEST = `${PREFIX}GET_SETTINGS_HISTORY_REQUEST`;
export const GET_SETTINGS_HISTORY_SUCCESS = `${PREFIX}GET_SETTINGS_HISTORY_SUCCESS`;
export const GET_SETTINGS_HISTORY_FAILURE = `${PREFIX}GET_SETTINGS_HISTORY_FAILURE`;
export const getSettingsHistory = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_SETTINGS_HISTORY_REQUEST
    });
  },
  fn: SettingsApi.getSettingsHistory,
  success: data => dispatch => {
    dispatch({
      type: GET_SETTINGS_HISTORY_SUCCESS,
      payload: data.settingsHistory
    });
    dispatch(showPopup({
      popup: SETTINGS_HISTORY_POPUP
    }));
  },
  error: error => ({
    type: GET_SETTINGS_HISTORY_FAILURE,
    payload: {
      error
    },
    error
  })
});
export const GET_REPLACEMENT_PRICE_RANGE_SUCCESS = `${PREFIX}GET_REPLACEMENT_PRICE_RANGE_SUCCESS`;
export const GET_REPLACEMENT_PRICE_RANGE_ERROR = `${PREFIX}GET_REPLACEMENT_PRICE_RANGE_ERROR`;
export const getReplacementPriceRange = createAsyncAction({
  fn: SettingsApi.replacementPriceRange,
  success: payload => ({
    type: GET_REPLACEMENT_PRICE_RANGE_SUCCESS,
    payload
  }),
  error: errorAction(GET_REPLACEMENT_PRICE_RANGE_ERROR)
});
export const GET_HUBSPOT_CALENDAR_LINKS_SUCCESS = `${PREFIX}GET_HUBSPOT_CALENDAR_LINKS_SUCCESS`;
export const GET_HUBSPOT_CALENDAR_LINKS_ERROR = `${PREFIX}GET_HUBSPOT_CALENDAR_LINKS_ERROR`;
export const getHubspotCalendarLinks = createAsyncAction({
  fn: SettingsApi.getHubspotCalendarLinks,
  success: payload => ({
    type: GET_HUBSPOT_CALENDAR_LINKS_SUCCESS,
    payload
  }),
  error: errorAction(GET_HUBSPOT_CALENDAR_LINKS_ERROR)
});