import { calculateSetupFeeValue, getMaxSetupFee } from 'swag-common/business-logic';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
export const getSetupFeeForLogoProduct = (item, items, relatedItems, isItemSample) => {
  const {
    logos,
    texts,
    prod,
    prodTime,
    asRelatedItemId
  } = item;
  const multiplier = getItemPriceMultiplier(item);
  let setupFee = calculateSetupFeeValue({
    product: prod,
    logos,
    texts,
    isSample: isItemSample,
    prodTime,
    multiplier,
    curatedBrandStoreProductId: item.curatedBrandStoreProductId
  });
  if (relatedItems.length > 1) {
    const fee = getMaxSetupFee(items);
    setupFee = fee[asRelatedItemId];
  }
  if (!!item.setupFeeDeductionDiscount && !!setupFee) {
    const calculatedSetupFee = setupFee - item.setupFeeDeductionDiscount;
    setupFee = calculatedSetupFee >= 0 ? calculatedSetupFee : 0;
  }
  return setupFee;
};