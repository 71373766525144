import _uniqBy from "lodash/uniqBy";
import _isString from "lodash/isString";
var _class;
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { getMaxScreenPrice } from 'swag-common/utils/get-max-screen-price.util';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { DEFAULT_RESELLER_COMMISSION_PERCENT, SWAG_PROFIT_PERCENT } from 'swag-common/constants/reseller.constants';
import { transformProductSettingsForFeature } from 'swag-common/utils/product-settings/transform-product-settings-for-feature';
import { getDefaultProductSettings } from 'swag-common/utils/items/get-item-selected-product-settings.util';
import { DesignUploadMode } from '../interfaces';
import { LOGO_UPLOAD_BLANK_OPTIONS, PRODUCTION_TIME_STANDARD_INDEX, SIZE_STANDARD_INDEX, SPECIAL_PRODUCT_TYPES, VARIANT_STANDARD_INDEX } from '../constants/product.constants';
import { BundleType } from '../constants/main-swag.constants';
import { DISCOUNT_VALUE, PRINT_RUN_COLORS } from '../config/constants';
import { PromocodeMeasurementEnum } from '../constants/promocode';
import getTotalSetupFeeForAllItems from '../business-logic/get-total-setup-fee-for-all-items.logic';
import calculateSetupFeeValue from '../business-logic/calculate-setup-fee-value.logic';
import { getDiscountWithAdditionalValue } from '../business-logic/order/get-discount-with-additional-value.service';
import { getDataForGettingItemShippingCost, getItemShippingCost } from '../business-logic/order/get-item-shipping-cost.service';
import { calculateSetupFeeDeductionDiscount } from '../business-logic/order/calculate-setup-fee-deduction-discount.logic';
import { getShipment, isCancelledItem } from './order';
import { getTotalQuantityForSharedItems, isBox } from './order/item-is-box.util';
import * as colorsUtils from './colors';
import { applyMultiplier } from './tenant-price-settings';
import { findReplacementSettingsForReplacementItem } from './items/get-replacement-item-settings';
const DEFAULT_PRINTING_METHOD = 'screenprinting';
const pad = int => `${int < 10 ? '0' : ''}${int}`;
export class Price {
  static safelyGetNumber(value) {
    if (_isString(value)) {
      return parseInt(value, 10);
    }
    return value;
  }
  static getShipmentChargeNumber(value) {
    return Math.ceil(Price.safelyGetNumber(value));
  }
  static formatPriceNumber(price) {
    return Number((price / 100).toFixed(2));
  }
  static getTotal(items, discount, measurement, additionalDiscountAmount = 0) {
    const deduction = Price.getDeductions(items);
    const sumOrder = items.reduce((prev, el) => prev + el.price, 0);
    const totalWithDiscount = Math.round(Price.applyDiscount(sumOrder, discount, measurement, additionalDiscountAmount));
    return totalWithDiscount - deduction;
  }
  static getTotalWithAllDiscounts(subTotal, taxes = 0, discount = 0, setupFeeDiscount = 0, shipmentDiscount = 0) {
    const total = subTotal + taxes - discount - setupFeeDiscount - shipmentDiscount;
    return total > 0 ? total : 0;
  }
  static getTotalForRelatedProducts(items, discount, measurement) {
    const {
      sampleDeductionDiscount = 0,
      setupFeeDeductionDiscount = 0
    } = items[0];
    const deductionDiscount = sampleDeductionDiscount + setupFeeDeductionDiscount;
    const sumOrder = items.reduce((prev, el) => prev + (el.blendedPrice || el.price || 0), 0);
    const sumWithoutDeduction = sumOrder - deductionDiscount;
    return Math.round(Price.applyDiscount(sumWithoutDeduction, discount, measurement));
  }
  static getTotalWithoutDiscounts(items) {
    return Math.round(items.reduce((prev, el) => prev + el.price, 0));
  }
  static getSwagSpaceOrderTotal(items) {
    return Math.round(items.reduce((prev, el) => prev + (el.swagSpaceTotal || 0), 0));
  }
  static getItemPrice(item, discount, measurement) {
    let itemPrice = item.price;
    if (item.sampleDeductionDiscount) {
      itemPrice -= item.sampleDeductionDiscount;
    }
    if (item.setupFeeDeductionDiscount) {
      itemPrice -= item.setupFeeDeductionDiscount;
    }
    itemPrice = Price.applyDiscount(itemPrice, discount, measurement);
    return itemPrice;
  }
  static getPriceOfTaxableItems(items) {
    return items.reduce((sum, item) => {
      if (Price.isItemTaxable(item)) {
        if (item.products && item.products.length) {
          return sum + item.products.reduce((acc, product) => {
            if (Price.isItemTaxable(product) && !Array.isArray(product.price)) {
              return acc + product.price;
            }
            return acc;
          }, item.price);
        } //sumItem * quantity + tax - discount;

        return sum + item.price;
      }
      return sum;
    }, 0);
  }
  static getTotalTaxes(order) {
    if (Number.isFinite(order.taxesPrice)) {
      return order.taxesPrice;
    }
    const {
      items,
      promocodeDiscount,
      taxes,
      measurement
    } = order;
    const discount = promocodeDiscount ? Price.safelyGetNumber(promocodeDiscount) : 0;
    if (parseFloat(taxes) === 0) {
      return 0;
    }
    const priceOfTaxableItems = Price.getPriceOfTaxableItems(items);
    const taxesPrice = Price.applyDiscount(priceOfTaxableItems, discount, measurement) * taxes;
    return Math.round(taxesPrice);
  }
  static getItemTax({
    order,
    item
  }) {
    if (Number.isFinite(item.tax)) {
      return item.tax;
    }
    const {
      items
    } = order;
    const totalTaxesPrice = Price.getTotalTaxes(order);
    const numberOfTaxableItems = items.filter(i => Price.isItemTaxable(i)).length;
    return Price.isItemTaxable(item) ? totalTaxesPrice / numberOfTaxableItems : 0;
  }
  static isItemTaxable(item) {
    return Number.isFinite(item.tax) || !(item.ksFlow || item.isNyTaxFree);
  }
  static applyDiscount(price, discount = 0, measurement = PromocodeMeasurementEnum.PERCENTAGES, additionalDiscountAmount = 0) {
    const isPercentage = measurement === PromocodeMeasurementEnum.PERCENTAGES;
    const roundedDiscount = Math.round(discount);
    const discountAmount = (isPercentage ? price * roundedDiscount / 100 : Number(roundedDiscount)) + additionalDiscountAmount;
    const priceAfterApplyingDiscount = roundedDiscount ? price - discountAmount : price;
    return Math.sign(priceAfterApplyingDiscount) === 1 ? Math.round(priceAfterApplyingDiscount) : 0;
  }
  static getDiscountCoefficient(order) {
    const {
      items
    } = order;
    const price = Price.getTotalWithoutDiscounts(items);
    const discount = Price.getDiscountWithAdditional(order);
    const coefficient = price === 0 ? 0 : discount / price;
    return 1 - parseFloat(coefficient.toFixed(5));
  }
  static getAbsoluteDiscountAmount(price, discount, measurement) {
    if (measurement === PromocodeMeasurementEnum.PERCENTAGES) {
      return Math.round(price * (discount / 100));
    } else {
      return price - discount < 0 ? price : discount;
    }
  }
  static getDiscount(items, discount = 0, measurement = PromocodeMeasurementEnum.PERCENTAGES, setupFeeDiscount = 0) {
    const price = Price.getTotalWithoutDiscounts(items);
    const deduction = Price.getDeductions(items);
    const finalPrice = price - deduction - setupFeeDiscount;
    return Price.getAbsoluteDiscountAmount(finalPrice, discount, measurement);
  }
  static getReplacementDiscount(items = []) {
    return items.reduce((result, item) => {
      if (item.replacementItemStatus && !isCancelledItem(item)) {
        const replacementItemSettings = findReplacementSettingsForReplacementItem(item);
        if (replacementItemSettings) {
          var _replacementItemSetti;
          result += (_replacementItemSetti = replacementItemSettings === null || replacementItemSettings === void 0 ? void 0 : replacementItemSettings.replacementDiscount) !== null && _replacementItemSetti !== void 0 ? _replacementItemSetti : 0;
        }
      }
      return result;
    }, 0);
  }
  static getDeductions(items) {
    const sampleDeductionDiscount = Price.getSampleDeductionDiscount(items);
    const setupFeeDeductionDiscount = Price.getSetupFeeDeductionDiscount(items);
    return sampleDeductionDiscount + setupFeeDeductionDiscount;
  }
  static getShipmentDiscount(order) {
    const {
      isConfirmed,
      items,
      IS_FREE_SHIPPING
    } = order;
    if (isConfirmed) {
      const itemsToCalculateShipping = items.filter(item => item.IS_FREE_SHIPPING);
      const {
        shipmentCustomerTotal
      } = Price.getTotalShipment(_objectSpread({}, order, {
        items: itemsToCalculateShipping
      }));
      return shipmentCustomerTotal;
    } else if (IS_FREE_SHIPPING) {
      if (IS_FREE_SHIPPING) {
        return getShipment(order);
      }
    }
    return 0;
  }
  static getSetupFeeDiscount(order, featureFlags) {
    const {
      IS_FREE_SETUP_FEE,
      isConfirmed,
      items
    } = order;
    const itemsToCalculate = isConfirmed ? items.filter(item => item.IS_FREE_SETUP_FEE) : items;
    if (IS_FREE_SETUP_FEE || itemsToCalculate.some(item => item.IS_FREE_SETUP_FEE)) {
      return Math.max(0, getTotalSetupFeeForAllItems(itemsToCalculate, featureFlags) - calculateSetupFeeDeductionDiscount(_uniqBy(itemsToCalculate.filter(isBox).reduce((acc, item) => {
        return [...acc, item, ...(item.products || [])];
      }, []), item => String(item._id))));
    }
    return 0;
  }
  static getAdditionalDiscountValues(order, featureFlags) {
    return {
      shipmentDiscount: Price.getShipmentDiscount(order),
      setupFeeDiscount: Price.getSetupFeeDiscount(order, featureFlags)
    };
  }
  static getDiscountWithAdditional(order, featureFlags) {
    const {
      items,
      promocodeDiscount,
      measurement
    } = order;
    const {
      shipmentDiscount,
      setupFeeDiscount
    } = Price.getAdditionalDiscountValues(order, featureFlags);
    const sampleDeductionDiscount = Price.getSampleDeductionDiscount(items);
    return getDiscountWithAdditionalValue(items, promocodeDiscount, measurement, setupFeeDiscount, shipmentDiscount, sampleDeductionDiscount);
  }
  static getSampleDeductionDiscount(items) {
    const sampleDeductionDiscount = items.reduce((prev, el) => {
      if (el.sampleDeductionDiscount) {
        return prev + el.sampleDeductionDiscount;
      }
      return prev;
    }, 0);
    return Math.round(sampleDeductionDiscount);
  }
  static getSetupFeeDeductionDiscount(items) {
    const setupFeeDeductionDiscount = items.reduce((prev, el) => {
      const setupFeeDiscount = el.setupFeeDeductionDiscount || 0;
      const relatedItemsCount = items.filter(i => i.asRelatedItemId && i.asRelatedItemId === el.asRelatedItemId).length;
      if (relatedItemsCount > 1) {
        return prev + setupFeeDiscount / relatedItemsCount;
      }
      return prev + setupFeeDiscount;
    }, 0);
    return Math.round(setupFeeDeductionDiscount);
  }
  static getTotalShipment(order) {
    const items = [...order.items];
    const orderKSFlow = order.ksFlow;
    const shipmentPrices = {
      shipmentUpsTotal: 0,
      shipmentCustomerTotal: 0,
      ksFulfillment: 0
    };
    items.forEach(item => {
      const itemKSFlow = item.ksFlow;
      const isBoxShipmentCharge = !!item.boxShipmentCharge;
      const shipmentCharges = isBoxShipmentCharge ? [item.boxShipmentCharge] : item.shipmentCharges;
      if (itemKSFlow) {
        return;
      }
      if (isItemBlankSampleOrPrintedSample(item)) {
        return;
      }
      if (shipmentCharges && Array.isArray(shipmentCharges)) {
        const charge = isBoxShipmentCharge ? shipmentCharges[0] : shipmentCharges.find(el => {
          if (!el) {
            return false;
          }
          return el.serviceCode === item.serviceCode;
        });
        if (charge) {
          shipmentPrices.shipmentUpsTotal += Price.getShipmentChargeNumber(charge.upsCharge);
          shipmentPrices.shipmentCustomerTotal += Price.getShipmentChargeNumber(charge.totalCharges);
          if (isBox(item) && item.fullBoxShipmentCharge) {
            shipmentPrices.shipmentUpsTotal += Price.getShipmentChargeNumber(item.fullBoxShipmentCharge.upsCharge);
            shipmentPrices.shipmentCustomerTotal += Price.getShipmentChargeNumber(item.fullBoxShipmentCharge.totalCharges);
          }
        }
      }
    });
    if (orderKSFlow) {
      shipmentPrices.ksFulfillment = Price.getShipmentChargeNumber(order.ksFulfillment);
    }
    return shipmentPrices;
  }
  static getResellerAdminPriceToCharge(order) {
    const shipmentPrices = Price.getTotalShipment(order);
    const shipmentPrice = shipmentPrices.shipmentCustomerTotal || 0;
    const ksFulfillment = shipmentPrices.ksFulfillment;
    const swagSpaceTotalPrice = order.swagSpaceTotalPrice || 0;
    const swagSpaceTaxesPrice = order.swagSpaceTaxesPrice || 0;
    return swagSpaceTotalPrice + swagSpaceTaxesPrice + shipmentPrice + ksFulfillment;
  }
  static getPriceToCharge(order, featureFlags) {
    const taxesPrice = order.taxesPrice || 0;
    const shipmentPrices = Price.getTotalShipment(order);
    const shipmentPrice = shipmentPrices.shipmentCustomerTotal || 0;
    const ksFulfillment = shipmentPrices.ksFulfillment;
    const discount = Price.getDiscountWithAdditional(order);
    const {
      setupFeeDiscount
    } = Price.getAdditionalDiscountValues(order, featureFlags);
    const deduction = Price.getSetupFeeDeductionDiscount(order.items);
    return Price.getPriceToChargeByParts(order.total, taxesPrice, shipmentPrice, ksFulfillment, discount, setupFeeDiscount, deduction);
  }
  static getPriceToChargeByParts(total, taxesPrice, shipmentPrice, ksFulfillment, discount, setupFeeDiscount, deduction) {
    const priceToCharge = total + taxesPrice + shipmentPrice + ksFulfillment - discount - setupFeeDiscount - deduction;
    if (priceToCharge < 0) {
      return 0;
    }
    return priceToCharge;
  }
  static getPriceOfOrder(order, featureFlags) {
    const total = Price.getTotalWithoutDiscounts(order.items);
    const {
      shipmentCustomerTotal,
      ksFulfillment
    } = Price.getTotalShipment(order);
    const taxesPrice = Price.getTotalTaxes(order);
    const {
      setupFeeDiscount,
      shipmentDiscount
    } = Price.getAdditionalDiscountValues(order, featureFlags);
    const deduction = Price.getSetupFeeDeductionDiscount(order.items);
    const discount = Price.getDiscountWithAdditional(order);
    const replacementDiscount = Price.getReplacementDiscount(order.items);
    return Price.getPriceToChargeByParts(total, taxesPrice, shipmentCustomerTotal, ksFulfillment, discount + replacementDiscount, setupFeeDiscount + shipmentDiscount, deduction);
  }
  static getPriceOfOrderWithoutPromocodeDiscount(order) {
    const discount = Price.getDiscountWithAdditional(order);
    return Price.getPriceOfOrder(order) + discount;
  }
  static checkIfItemPriceCalculationPossible(item) {
    var _item$prod$productSet;
    return (isItemBlankSampleOrPrintedSample(item) || item.isCustomizedBlank || item.colors > 0) && !!((_item$prod$productSet = item.prod.productSettings) !== null && _item$prod$productSet !== void 0 && _item$prod$productSet.length);
  }
  static getQuantityIndex(quantity, quantities = []) {
    return quantities.reduce((res, v, i) => v <= quantity ? i : res, 0);
  }
  static getBasePrice(orderItem, product, totalQuantityForSharedItems) {
    var _product$price, _product$price$varian, _product$price$varian2;
    const quantityToUseForBreak = totalQuantityForSharedItems || orderItem.quantity;
    const variantIndex = product.variantDependentPrice ? orderItem.variantInd || 0 : 0;
    const sizeIndex = product.sizeDependentPrice ? orderItem.sizeInd || 0 : 0;
    const quantityIndex = Price.getQuantityIndex(quantityToUseForBreak, product.quantities);
    return ((_product$price = product.price) === null || _product$price === void 0 ? void 0 : (_product$price$varian = _product$price[variantIndex]) === null || _product$price$varian === void 0 ? void 0 : (_product$price$varian2 = _product$price$varian[sizeIndex]) === null || _product$price$varian2 === void 0 ? void 0 : _product$price$varian2[quantityIndex]) || 0;
  }
  static getColorIndex(quantity, colors) {
    const max = Math.max.apply(null, colors);
    if (quantity < max) {
      return colors.findIndex(c => c == quantity);
    }
    return colors.findIndex(c => c == max);
  }
  static getAdditionalColorsQuantity(quantity, colors) {
    const max = Math.max.apply(null, colors);
    const res = quantity < max ? 0 : quantity - max;
    return res;
  }
  static getPrintRunPrice({
    colors,
    quantity,
    productSettings,
    blendedQuantity
  }) {
    var _productSettings$prin, _productSettings$prin2, _productSettings$prin3, _productSettings$prin4;
    let colorsQuantity = typeof colors === 'string' ? parseInt(colors, 10) : colors;
    if (!colorsQuantity || colorsQuantity === 0) {
      return 0;
    }
    if (productSettings.addOneColor) {
      colorsQuantity += 1;
    }
    if (productSettings.colorIndependentPrice) {
      colorsQuantity = 1;
    }
    const quantityIndex = blendedQuantity ? Price.getQuantityIndex(blendedQuantity, productSettings.printRunQuantities) : Price.getQuantityIndex(quantity, productSettings.printRunQuantities);
    const colorIndex = Price.getColorIndex(colorsQuantity, PRINT_RUN_COLORS);
    const additionalColorsQuantity = Price.getAdditionalColorsQuantity(colorsQuantity, PRINT_RUN_COLORS);
    if (colorIndex < 0) {
      return 0;
    }
    let printRunPrice = productSettings.printRunPrices && ((_productSettings$prin = (_productSettings$prin2 = productSettings.printRunPrices) === null || _productSettings$prin2 === void 0 ? void 0 : (_productSettings$prin3 = _productSettings$prin2[colorIndex]) === null || _productSettings$prin3 === void 0 ? void 0 : _productSettings$prin3[quantityIndex]) !== null && _productSettings$prin !== void 0 ? _productSettings$prin : 0);
    printRunPrice += ((_productSettings$prin4 = productSettings.printRunMorePrice) !== null && _productSettings$prin4 !== void 0 ? _productSettings$prin4 : 0) * additionalColorsQuantity;
    return printRunPrice;
  }
  static getTimeCoefficient(prodTime, productionTimeList) {
    var _productionTimeList$, _productionTimeList$2;
    if (productionTimeList[prodTime]) {
      return productionTimeList[prodTime].cost;
    }
    return (_productionTimeList$ = (_productionTimeList$2 = productionTimeList[0]) === null || _productionTimeList$2 === void 0 ? void 0 : _productionTimeList$2.cost) !== null && _productionTimeList$ !== void 0 ? _productionTimeList$ : 1;
  }
  static getDiscountK(discount) {
    return discount ? DISCOUNT_VALUE : 0;
  }
  static getMarginCoefficient(product, bundleType) {
    switch (bundleType) {
      case BundleType.DYNAMIC:
        {
          var _product$spaceMargin;
          return ((_product$spaceMargin = product === null || product === void 0 ? void 0 : product.spaceMargin) !== null && _product$spaceMargin !== void 0 ? _product$spaceMargin : 0) / 100;
        }
      default:
        {
          var _product$margin;
          return ((_product$margin = product === null || product === void 0 ? void 0 : product.margin) !== null && _product$margin !== void 0 ? _product$margin : 0) / 100;
        }
    }
  }
  static getScreensAmountForImage(productSettings, colorsNumber, logosQuantity = 1) {
    const {
      colorIndependentPrice,
      colorIndependentScreenPrice,
      addOneColor
    } = productSettings;
    if (!colorsNumber) {
      return 0;
    }
    if (colorIndependentPrice || colorIndependentScreenPrice) {
      return 1;
    }
    if (addOneColor) {
      return colorsNumber + logosQuantity;
    }
    return colorsNumber;
  }
  static pickPrintingMethod(orderItem, logo) {
    const selectedMethod = logo.colors && logo.colors.printingMethod;
    const printingMethod = selectedMethod || orderItem.defaultPrintingMethod;
    return Price.printingPricesCalculators[printingMethod] ? printingMethod : DEFAULT_PRINTING_METHOD;
  }
  static getScreenPriceForItem({
    item,
    setupFee
  }) {
    if (isBox(item) || !setupFee) {
      return 0;
    }
    return Price.getScreenPriceForRelatedProduct(setupFee, item.blendedQuantity || item.quantity);
  }
  static getScreenPriceForRelatedProduct(totalScreensPrice, blendedQuantity) {
    return totalScreensPrice / blendedQuantity;
  }
  static getItemPriceDeductionAmountFromSetupFeeDeduction({
    setupFeeDeductionDiscount,
    quantity,
    blendedQuantity
  }) {
    if (!setupFeeDeductionDiscount) {
      return 0;
    }
    if (!blendedQuantity) {
      return setupFeeDeductionDiscount;
    }
    const coefficient = quantity / blendedQuantity;
    return Math.round(setupFeeDeductionDiscount * coefficient);
  }
  static getPrice({
    orderItem,
    product,
    blendedQuantity,
    screenPrice,
    totalQuantityForSharedItems,
    bundleType,
    multiplier,
    featureFlags
  }) {
    var _orderItem$prod2, _orderItem$prod2$prod, _orderItem$prod2$prod2, _orderItem$curatedBra2;
    if (product) {
      var _orderItem$prod, _orderItem$prod$produ;
      if ((_orderItem$prod = orderItem.prod) !== null && _orderItem$prod !== void 0 && (_orderItem$prod$produ = _orderItem$prod.productSettings) !== null && _orderItem$prod$produ !== void 0 && _orderItem$prod$produ.length) {
        orderItem.prod.productSettings = transformProductSettingsForFeature(orderItem.prod.productSettings, product, featureFlags);
      } else {
        orderItem.prod.productSettings = getDefaultProductSettings(product, featureFlags);
      }
    }
    if (product && (product === null || product === void 0 ? void 0 : product.designUploadMode) === DesignUploadMode.box) {
      return Price.getBoxPrice({
        orderItem,
        product,
        bundleType
      });
    }
    const {
      quantity,
      discount,
      prodTime,
      isSample,
      isPrintedSample
    } = orderItem;
    if (!Price.checkIfItemPriceCalculationPossible(orderItem) && (product === null || product === void 0 ? void 0 : product.specialProductType) !== SPECIAL_PRODUCT_TYPES.GIFT_GIVEAWAY_NOTECARD) {
      throw new Error(`Couldn't calculate item price because item ${orderItem._id} doesn't have all required data`);
    }
    if (isSample || isPrintedSample) {
      const samplePrice = isSample ? product === null || product === void 0 ? void 0 : product.samplePrice : product === null || product === void 0 ? void 0 : product.printedSamplePrice;
      return samplePrice || 0;
    }
    if (!quantity) {
      return 0;
    }
    const productionTimeList = ((_orderItem$prod2 = orderItem.prod) === null || _orderItem$prod2 === void 0 ? void 0 : (_orderItem$prod2$prod = _orderItem$prod2.productSettings) === null || _orderItem$prod2$prod === void 0 ? void 0 : (_orderItem$prod2$prod2 = _orderItem$prod2$prod[0]) === null || _orderItem$prod2$prod2 === void 0 ? void 0 : _orderItem$prod2$prod2.productionTimeList) || [];
    const timeCoefficient = orderItem.isCustomizedBlank ? Price.getTimeCoefficient(0, productionTimeList) : Price.getTimeCoefficient(prodTime, productionTimeList);
    const discountCoefficient = Price.getDiscountK(discount);
    const marginCoefficient = Price.getMarginCoefficient(product, bundleType);
    const basePrice = Price.getBasePrice(orderItem, product, totalQuantityForSharedItems);
    const totalBasePrice = basePrice * quantity;
    const printingPrice = Object.keys(orderItem.logos || {}).reduce((price, side) => {
      if (!orderItem.logos[side].length) {
        return price;
      }
      const colorsPerSide = colorsUtils.getUniqueColorsNumberForSide(orderItem.logos, orderItem.texts, side);
      const printingMethod = Price.pickPrintingMethod(orderItem, orderItem.logos[side][0]);
      const printingPrices = Price.printingPricesCalculators[printingMethod](orderItem, product, colorsPerSide, blendedQuantity, screenPrice);
      return price + printingPrices;
    }, 0);
    let totalPrice = 0;
    totalPrice += totalBasePrice + printingPrice;
    if (marginCoefficient !== 0) {
      totalPrice /= 1 - marginCoefficient;
    }
    totalPrice += totalPrice * timeCoefficient;
    if (product !== null && product !== void 0 && product.swagLogoDiscount) {
      totalPrice -= totalPrice * discountCoefficient;
    }

    // Price correction to be divisible on items count
    const itemPrice = Math.ceil(totalPrice / quantity);
    const originalPrice = Math.round(itemPrice * quantity);
    if (!multiplier) {
      var _orderItem$curatedBra;
      return Price.applyCBSSetupFeeDeduction({
        basePrice: originalPrice,
        product: orderItem.prod,
        texts: orderItem.texts,
        logos: orderItem.logos,
        prodTime: orderItem.prodTime,
        featureFlags,
        curatedBrandStoreProductId: (_orderItem$curatedBra = orderItem.curatedBrandStoreProductId) === null || _orderItem$curatedBra === void 0 ? void 0 : _orderItem$curatedBra.toString()
      });
    }
    const priceWithMultiplier = Price.getPriceWithMultiplier({
      basePrice: originalPrice,
      multiplier,
      quantity: orderItem.quantity
    });
    return Price.applyCBSSetupFeeDeduction({
      basePrice: priceWithMultiplier,
      product: orderItem.prod,
      texts: orderItem.texts,
      logos: orderItem.logos,
      prodTime: orderItem.prodTime,
      featureFlags,
      multiplier,
      curatedBrandStoreProductId: (_orderItem$curatedBra2 = orderItem.curatedBrandStoreProductId) === null || _orderItem$curatedBra2 === void 0 ? void 0 : _orderItem$curatedBra2.toString()
    });
  }
  static applyCBSSetupFeeDeduction({
    product,
    basePrice,
    logos = {},
    texts,
    isSample = false,
    prodTime = 0,
    multiplier = 0,
    featureFlags,
    curatedBrandStoreProductId
  }) {
    if (!curatedBrandStoreProductId) {
      return basePrice;
    }
    const setupFee = calculateSetupFeeValue({
      product,
      logos,
      texts,
      isSample,
      prodTime,
      multiplier,
      featureFlags
    });
    return basePrice - setupFee;
  }
  static getPriceWithMultiplier({
    basePrice,
    multiplier,
    quantity
  }) {
    const totalPrice = Number(applyMultiplier(basePrice, multiplier));

    // Price correction to be divisible on items count
    const itemPrice = Math.ceil(totalPrice / quantity);
    const roundedPrice = Math.round(itemPrice * quantity);
    return roundedPrice;
  }
  static getSwagProfitAmount({
    basePrice,
    quantity,
    productMarginReduction,
    tierCommission = 0,
    bonusCommission = 0,
    withRounding = false,
    isSample = false
  }) {
    if (isSample) {
      return basePrice;
    }
    const additionalProfit = productMarginReduction ? DEFAULT_RESELLER_COMMISSION_PERCENT - productMarginReduction : 0;
    const swagProfit = SWAG_PROFIT_PERCENT + additionalProfit - tierCommission - bonusCommission;
    const swagProfitAmount = basePrice * (swagProfit / 100);
    if (withRounding) {
      const swagProfitAmountPerItem = Math.round(swagProfitAmount / quantity);
      return swagProfitAmountPerItem * quantity;
    }
    return swagProfitAmount;
  }

  // 1520 => 15.20
  static convertPriceFromCentsToDollars(priceInCents) {
    return Number((priceInCents / 100).toFixed(2));
  }

  // 15.20 => 1500
  static convertPriceFromDollarsToCents(priceInCents) {
    return Number((priceInCents * 100).toFixed(0));
  }
  static getBoxPrice({
    orderItem,
    product,
    isBoxItemsPriceAdded = true,
    bundleType,
    multiplier = 0
  }) {
    var _product$productSetti, _product$productSetti2;
    const {
      products,
      quantity = 0,
      discount,
      prodTime
    } = orderItem;
    const productionTimeList = (product === null || product === void 0 ? void 0 : (_product$productSetti = product.productSettings) === null || _product$productSetti === void 0 ? void 0 : (_product$productSetti2 = _product$productSetti[0]) === null || _product$productSetti2 === void 0 ? void 0 : _product$productSetti2.productionTimeList) || [];
    const {
      swagLogoDiscount,
      assemblyFee
    } = product;
    const baseBoxPrice = Price.getBasePrice(orderItem, product);
    const timeCoefficient = Price.getTimeCoefficient(prodTime, productionTimeList);
    const discountCoefficient = Price.getDiscountK(discount);
    const marginCoefficient = Price.getMarginCoefficient(product, bundleType);
    // multiplier should not be applied to assemblyFee
    const assemblyFeePrice = assemblyFee !== null && assemblyFee !== void 0 ? assemblyFee : 0;
    const baseBoxPriceWithMultiplier = Number(applyMultiplier(baseBoxPrice, multiplier));
    let totalPrice = (baseBoxPriceWithMultiplier + assemblyFeePrice) * quantity;
    if (marginCoefficient !== 0) {
      totalPrice /= 1 - marginCoefficient;
    }
    if (swagLogoDiscount) {
      totalPrice -= totalPrice * discountCoefficient;
    }
    totalPrice += totalPrice * timeCoefficient;
    if (isBoxItemsPriceAdded) {
      totalPrice += Price.getBoxItemsPrice(products);
    }
    return Math.round(totalPrice);
  }
  static getBoxItemsPrice(items) {
    return items && items.reduce((total, item) => {
      if (item.removedFromBox) {
        return total;
      }
      return total + (item.priceForItem || 0);
    }, 0);
  }
  static getItemShipping(item, items) {
    const {
      shipmentCharges
    } = item;
    if (!shipmentCharges) {
      return;
    }
    return getItemShippingCost(getDataForGettingItemShippingCost(item, items));
  }
  static getItemShippingWithoutBoxContent(item, items) {
    const isBoxContentExcluded = true;
    const {
      shipmentCharges
    } = item;
    if (!shipmentCharges) {
      return 0;
    }
    return getItemShippingCost(getDataForGettingItemShippingCost(item, items), isBoxContentExcluded);
  }
  static getInteger(value) {
    if (value < 0) {
      return Math.ceil(value / 100);
    }
    return Math.floor(value / 100);
  }
  static priceIntegerAndFract(int, addDecimal) {
    let value = int || 0;
    if (addDecimal) {
      value = value * 100;
    }
    const integer = Price.getInteger(value);
    const valueToFract = value < 0 ? value * -1 : value;
    const fract = pad(Math.floor(valueToFract) % 100);
    return {
      integer,
      fract
    };
  }
  static addCommaToNumber(number) {
    let integerToProcess = number;
    let signPrefix = '';
    if (Number(number) < 0) {
      signPrefix = '-';
      integerToProcess = Number(integerToProcess) * -1;
    }
    let integer = `${integerToProcess}`;
    for (let i = integer.length - 3; i > 0; i -= 3) {
      integer = `${integer.slice(0, i)},${integer.slice(i)}`;
    }
    return `${signPrefix}${integer}`;
  }
  static formatNumber(number, {
    addComma,
    addDecimal
  } = {
    addComma: true,
    addDecimal: false
  }) {
    const {
      integer,
      fract
    } = Price.priceIntegerAndFract(+number, addDecimal);
    const formatInteger = addComma ? Price.addCommaToNumber(integer) : integer;
    return `${formatInteger}.${fract}`;
  }
  static formatPrice(int, {
    addComma = true,
    onlyInteger = false
  } = {}) {
    const {
      integer,
      fract
    } = Price.priceIntegerAndFract(int);
    const number = addComma ? Price.addCommaToNumber(integer) : integer;
    return onlyInteger ? `$${number}` : `$${number}.${fract}`;
  }
  static formatPriceCSV(int, {
    addComma
  } = {
    addComma: true
  }) {
    return `"${Price.formatPrice(int, {
      addComma
    })}"`;
  }
  static getItemPriceWithoutSetUpFee({
    price,
    quantity,
    setupFee
  }) {
    return (price - setupFee) / (quantity || 0);
  }
  static getTotalItemPriceWithoutSetupFee(item) {
    const {
      prod,
      price,
      logos,
      texts,
      isPrintedSample,
      curatedBrandStoreProductId
    } = item;
    const multiplier = getItemPriceMultiplier(item);
    const setupFee = calculateSetupFeeValue({
      product: prod,
      logos,
      texts,
      isSample: isPrintedSample,
      multiplier,
      curatedBrandStoreProductId
    });
    return price - setupFee;
  }
  static calculateItemPrice(item, IS_FREE_SETUP_FEE) {
    const {
      price,
      setupFeeDeductionDiscount,
      sampleDeductionDiscount
    } = item;
    if (IS_FREE_SETUP_FEE) {
      return Price.getTotalItemPriceWithoutSetupFee(item);
    }
    const deduction = sampleDeductionDiscount || setupFeeDeductionDiscount || 0;
    return price - deduction;
  }
  static getDiscountLessThanTotal(total, discount) {
    if (total - discount < 0) {
      return total;
    }
    return discount;
  }
  static getItemPricePerUnit(item) {
    const {
      price,
      quantity,
      setupFeeDeductionDiscount = 0
    } = item;
    return (price - setupFeeDeductionDiscount) / (quantity || 1);
  }
  static getPricePerItem({
    assemblyFee,
    quantity,
    screenPrice,
    totalPriceForItems
  }) {
    const setupFeeToUseForPricePerItemCalculation = screenPrice * quantity;
    const totalAssemblyFeeForBox = assemblyFee ? assemblyFee * quantity : 0;
    return (totalPriceForItems - totalAssemblyFeeForBox - setupFeeToUseForPricePerItemCalculation) / quantity;
  }
  static addDecimalToNumber(number) {
    return +number * 100;
  }
  static getPaymentPrices(order, featureFlags) {
    const {
      items,
      promocodeDiscount,
      measurement
    } = order;
    const total = Price.getTotalWithoutDiscounts(order.items);
    const taxes = Price.getTotalTaxes(order);
    const shipmentPrices = Price.getTotalShipment(order);
    const priceToCharge = Price.getPriceToCharge(order, featureFlags);
    const discountAmount = Price.getDiscount(items, promocodeDiscount, measurement);
    return {
      total,
      taxes,
      shipment: shipmentPrices.shipmentCustomerTotal,
      discount: discountAmount,
      grandTotal: priceToCharge
    };
  }
  static calculateDefaultEdgePrice({
    product,
    bundleType = BundleType.SWAG,
    featureFlags
  }) {
    return Price.calculateEdgePrice({
      product,
      orderItem: {
        sizeInd: SIZE_STANDARD_INDEX,
        variantInd: VARIANT_STANDARD_INDEX,
        prodTime: PRODUCTION_TIME_STANDARD_INDEX
      },
      bundleType,
      featureFlags
    });
  }
  static calculateEdgePrice({
    product,
    orderItem: {
      sizeInd,
      prodTime,
      variantInd
    },
    bundleType,
    featureFlags
  }) {
    var _ref, _product$productSetti3;
    const {
      minQuant,
      quantities = [],
      maxPrintColors
    } = product;

    // @TODO: rename elastic product.embellishmentMethodsData to product.productSettings field
    const productSettings = (_ref = (_product$productSetti3 = product.productSettings) !== null && _product$productSetti3 !== void 0 ? _product$productSetti3 : product.embellishmentMethodsData) !== null && _ref !== void 0 ? _ref : [];
    const minColors = 1;
    const marginCoefficient = this.getMarginCoefficient(product, bundleType);
    const minPriceBreakQuantity = quantities[quantities.length - 1];
    const ignoreLogos = product.logoUploadBlankOption === LOGO_UPLOAD_BLANK_OPTIONS.ONLY_BLANK;
    const minPriceItem = {
      colors: minColors,
      logos: ignoreLogos ? {} : {
        front: [{
          colors: {
            colorsToPrint: ['Color']
          },
          preview: 'non-empty-string',
          original: 'non-empty-string'
        }]
      },
      prodTime,
      sizeInd,
      variantInd
    };
    const maxPriceItem = {
      quantity: minQuant,
      blendedQuantity: minQuant,
      prodTime,
      sizeInd,
      variantInd
    };
    const priceRanges = productSettings.map(setting => {
      const settingMaxQuant = Math.max(setting.screenPrice / 0.49 * (marginCoefficient + 1), minPriceBreakQuantity);
      const minPriceItemTotal = Price.getPrice({
        orderItem: _objectSpread({}, minPriceItem, {
          quantity: settingMaxQuant,
          blendedQuantity: settingMaxQuant,
          prod: {
            productSettings: [setting]
          }
        }),
        product: product,
        blendedQuantity: settingMaxQuant,
        bundleType,
        featureFlags
      });
      const minPrice = Math.round(minPriceItemTotal / settingMaxQuant);
      const printRunPrices = setting.printRunPrices || [];
      const lastNonEmptyPrintPriceIndex = printRunPrices.reduce((firstEmpty, pricesPerColorAmount, index) => {
        if (printRunPrices[index][0] !== 0) {
          return index;
        }
        return firstEmpty;
      }, 0) + 1;
      const maxColors = Math.max(Math.min(maxPrintColors || lastNonEmptyPrintPriceIndex, lastNonEmptyPrintPriceIndex), 1);
      const maxPriceItemTotal = Price.getPrice({
        orderItem: _objectSpread({}, maxPriceItem, {
          prod: {
            productSettings: [setting]
          },
          colors: maxColors,
          logos: ignoreLogos ? {} : {
            front: [{
              colors: {
                colorsToPrint: new Array(maxColors).fill('Color').map((color, index) => color + index)
              },
              preview: 'non-empty-string',
              original: 'non-empty-string'
            }]
          }
        }),
        product: product,
        blendedQuantity: minQuant,
        bundleType,
        featureFlags
      });
      const maxPrice = Math.round(maxPriceItemTotal / minQuant);
      return {
        minPrice,
        maxPrice
      };
    });
    const initialMinValues = {
      minPrice: Infinity,
      maxPrice: -Infinity
    };
    const {
      minPrice,
      maxPrice
    } = priceRanges.reduce((acc, current) => {
      return {
        minPrice: Math.min(acc.minPrice, current.minPrice),
        maxPrice: Math.max(acc.maxPrice, current.maxPrice)
      };
    }, initialMinValues);
    return {
      minPrice,
      maxPrice
    };
  }
  static formatPriceToString(value, skipConverting) {
    const price = value || 0;
    if (skipConverting) {
      return price.toFixed(2);
    }
    return (price / 100).toFixed(2);
  }
  static getOrderItemBasePrice({
    item,
    items,
    bundleType,
    featureFlags
  }) {
    const maxScreenPrices = getMaxScreenPrice(items, featureFlags);
    const {
      asRelatedItemId,
      blendedQuantity,
      prod
    } = item;
    if (!prod) {
      return item.price;
    }
    if (prod.designUploadMode === DesignUploadMode.box) {
      return Price.getBoxPrice({
        orderItem: item,
        product: prod,
        isBoxItemsPriceAdded: false,
        bundleType
      });
    }
    const screenPrice = asRelatedItemId ? maxScreenPrices[asRelatedItemId] : null;
    const totalQuantityForSharedItems = getTotalQuantityForSharedItems(item, items);
    return Price.getPrice({
      orderItem: item,
      product: prod,
      blendedQuantity: blendedQuantity || 0,
      screenPrice,
      totalQuantityForSharedItems,
      bundleType,
      featureFlags
    });
  }
  static getOrderItemsBasePrices({
    items,
    bundleType
  }) {
    return items.map(item => {
      return _objectSpread({}, item, {
        price: Price.getOrderItemBasePrice({
          item,
          items,
          bundleType
        })
      });
    });
  }
}
_class = Price;
_defineProperty(Price, "printingPricesCalculators", {
  dtg: (orderItem, product) => {
    const {
      quantity
    } = orderItem;
    const printRunPrice = product.dtgPrintingPrice;
    return printRunPrice * quantity;
  },
  screenprinting: (orderItem, product, colorsNumber, blendedQuantity, screenPrice) => {
    const {
      quantity
    } = orderItem;
    const productSettings = orderItem.prod.productSettings[0];
    const printRunPrice = _class.getPrintRunPrice({
      colors: colorsNumber,
      quantity,
      blendedQuantity,
      productSettings
    });
    const totalPrintRunPrice = printRunPrice * quantity;
    const totalScreens = _class.getScreensAmountForImage(productSettings, colorsNumber);
    let totalProductScreenPrice = totalScreens * productSettings.screenPrice;
    if (screenPrice) {
      totalProductScreenPrice = screenPrice;
    }
    if (blendedQuantity) {
      // for related products we share screen price across items
      totalProductScreenPrice = _class.getScreenPriceForRelatedProduct(totalProductScreenPrice, blendedQuantity) * quantity;
    }
    return totalProductScreenPrice + totalPrintRunPrice;
  }
});