export const getItemsForAnalytics = shipout => {
  var _shipout$products;
  if (!(shipout !== null && shipout !== void 0 && shipout.products)) {
    return [];
  }
  const itemsIds = ((_shipout$products = shipout.products) === null || _shipout$products === void 0 ? void 0 : _shipout$products.map(({
    swagProductId
  }) => {
    var _swagProductId$_id;
    return (_swagProductId$_id = swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId._id) !== null && _swagProductId$_id !== void 0 ? _swagProductId$_id : null;
  }).filter(id => id !== null)) || [];
  return itemsIds;
};
export const getGiveawayItemsForAnalytics = (giveaway, productsList) => {
  if (!(giveaway !== null && giveaway !== void 0 && giveaway.products)) {
    return [];
  }
  const productsIds = giveaway.products.map(productId => productId);
  const items = productsList.filter(({
    parentId
  }) => productsIds.includes(parentId));
  const itemsIds = items.map(({
    swagProductId
  }) => {
    var _swagProductId$_id2;
    return (_swagProductId$_id2 = swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId._id) !== null && _swagProductId$_id2 !== void 0 ? _swagProductId$_id2 : null;
  }).filter(id => id !== null) || [];
  return itemsIds;
};
export const getPendingApprovalItemsForAnalytics = (distribution, productsList) => {
  if (!(distribution !== null && distribution !== void 0 && distribution.products)) {
    return [];
  }
  const distributionProductsIds = (distribution === null || distribution === void 0 ? void 0 : distribution.products.map(({
    parentId
  }) => parentId)) || [];
  const items = productsList.filter(({
    parentId
  }) => distributionProductsIds.includes(parentId));
  const itemsIds = items.map(({
    swagProductId
  }) => {
    var _swagProductId$_id3;
    return (_swagProductId$_id3 = swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId._id) !== null && _swagProductId$_id3 !== void 0 ? _swagProductId$_id3 : null;
  }).filter(id => id !== null);
  return itemsIds;
};