export const getGiveawayInfoMergedFields = giveaway => {
  if (!giveaway || !giveaway.draft) {
    return giveaway;
  }
  const draft = giveaway.draft;
  return {
    variantCapMap: draft.variantCapMap || giveaway.variantCapMap,
    isOneProduct: draft.isOneProduct || giveaway.isOneProduct,
    name: draft.name || giveaway.name,
    productChoice: draft.productChoice || giveaway.productChoice,
    productQuantity: draft.productQuantity || giveaway.productQuantity,
    colors: draft.colors || giveaway.colors,
    logo: draft.logo || giveaway.logo,
    primaryColor: draft.primaryColor || giveaway.primaryColor,
    domains: draft.domains || giveaway.domains || [],
    description: Object.prototype.hasOwnProperty.call(draft, 'description') ? draft.description : giveaway.description,
    isCapEnabled: Object.prototype.hasOwnProperty.call(draft, 'isCapEnabled') ? draft.isCapEnabled : giveaway.isCapEnabled,
    capValue: Object.prototype.hasOwnProperty.call(draft, 'capValue') ? draft.capValue : giveaway.capValue,
    linksCompleted: giveaway.linksCompleted || 0,
    autopayCard: giveaway.autopayCard || null,
    autopayPaymentMethod: giveaway.autopayPaymentMethod || null,
    notecard: draft.notecard,
    giveawayType: draft.giveawayType || giveaway.giveawayType,
    budget: draft.budget || giveaway.budget,
    passedSteps: draft.passedSteps || giveaway.passedSteps
  };
};