import _isEqual from "lodash/isEqual";
import { createSelector } from 'reselect';
import { LANDING_TYPES, OTHER_HUBSPOT_FORMS } from 'swag-common/constants/landings';
import { ACTIONS, WAREHOUSE_CODES_LABELS } from 'swag-common/interfaces';
import { convertDateToHistoryFormat } from 'swag-common/utils/date-lib';
import { getSuperSpeedFromLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
import { formatHistoryText } from 'swag-client-common/utils/action-history-format.util';
import { isShipoutPreShutdown, isShipoutPreShutdownOrShutdown, isShipoutShutdown } from 'swag-common/utils/shipout-shutdown/is-shipout-preshutdown-state.util';
export const settingsSelector = state => state.settings;
export const superSpeedSettingsSelector = createSelector(settingsSelector, settings => settings.superSpeed);
export const isAvailableSuperSpeedSelector = createSelector(superSpeedSettingsSelector, ({
  enabled
}) => enabled);
export const isSuperSpeedActiveSelector = createSelector(isAvailableSuperSpeedSelector, getSuperSpeedFromLS, (isAvailableSuperSpeed, showSuperSpeedFromLS) => {
  return isAvailableSuperSpeed && showSuperSpeedFromLS;
});
export const defaultWarehouseSelector = createSelector(settingsSelector, settings => settings.defaultWarehouse);
export const initialShippingMarginSelector = createSelector(settingsSelector, settings => settings.initialShippingMargin);
export const initialSpaceShippingMarginSelector = createSelector(settingsSelector, settings => settings.initialSpaceShippingMargin);
export const shippingMarginSelector = createSelector(settingsSelector, settings => settings.shippingMargin);
export const spaceShippingMarginSelector = createSelector(settingsSelector, settings => settings.spaceShippingMargin);
export const rootHistorySelector = createSelector(settingsSelector, settings => settings.history);
export const isSaveShippingMarginButtonDisabledSelector = createSelector(initialShippingMarginSelector, shippingMarginSelector, (initialShippingMargin, shippingMargin) => _isEqual(initialShippingMargin, shippingMargin));
export const isSpaceSaveShippingMarginButtonDisabledSelector = createSelector(initialSpaceShippingMarginSelector, spaceShippingMarginSelector, (initialSpaceShippingMargin, spaceShippingMargin) => _isEqual(initialSpaceShippingMargin, spaceShippingMargin));
export const settingsHistorySelector = createSelector(rootHistorySelector, settingsHistory => {
  const history = [];
  settingsHistory.forEach(item => {
    const {
      data,
      createdAt,
      action
    } = item;
    const _id = '_id' in item ? item._id : '';
    if (action === ACTIONS.CHANGE_DEFAULT_WAREHOUSE && 'oldValue' in data) {
      const {
        oldValue,
        newValue,
        adminEmail
      } = data;
      history.push({
        _id,
        createdAt: convertDateToHistoryFormat(createdAt),
        title: 'Change default warehouse',
        description: `Default warehouse was changed from "${WAREHOUSE_CODES_LABELS[oldValue]}" to "${WAREHOUSE_CODES_LABELS[newValue]}" by ${adminEmail}`
      });
    }
    if (action === ACTIONS.UPDATE_SHIPPING_MARGIN && 'shippingMarginInfo' in data) {
      const {
        shippingMarginInfo
      } = data;
      history.push({
        _id,
        createdAt: convertDateToHistoryFormat(createdAt),
        title: 'Update shipping margin',
        description: formatHistoryText(shippingMarginInfo, data.adminEmail)
      });
    }
    if (action === ACTIONS.UPDATE_SPACE_SHIPPING_MARGIN && 'spaceShippingMarginInfo' in data) {
      const {
        spaceShippingMarginInfo
      } = data;
      history.push({
        _id,
        createdAt: convertDateToHistoryFormat(createdAt),
        title: 'Update space shipping margin',
        description: formatHistoryText(spaceShippingMarginInfo, data.adminEmail, true)
      });
    }
  });
  return history;
});
export const isLoadingSelector = createSelector(settingsSelector, settings => settings.isLoading);
export const isLoadedSelector = createSelector(settingsSelector, settings => settings.isLoaded);
export const isShippingSettingsHiddenSelector = createSelector(isLoadingSelector, defaultWarehouseSelector, shippingMarginSelector, spaceShippingMarginSelector, (isLoading, defaultWarehouse, shippingMargin, spaceShippingMargin) => isLoading && (!defaultWarehouse || !shippingMargin || !spaceShippingMargin));
export const replacementPriceRangeSelector = createSelector(settingsSelector, settings => settings.replacementPriceRange);
export const replacementPriceRangeValueSelector = createSelector(replacementPriceRangeSelector, config => {
  var _config$replacementPr;
  return (_config$replacementPr = config === null || config === void 0 ? void 0 : config.replacementPriceRange) !== null && _config$replacementPr !== void 0 ? _config$replacementPr : null;
});
export const hubspotCalendarLinksSelector = createSelector(settingsSelector, settings => (settings === null || settings === void 0 ? void 0 : settings.hubspotCalendarLinks) || {});
export const inBoxHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.INABOX] || '');
export const giveawayHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.GIVEAWAY] || '');
export const inventoryHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.INVENTORY] || '');
export const shopifyHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[LANDING_TYPES.SHOPIFY] || '');
export const requestDemoHubspotCalendarLinkSelector = createSelector(hubspotCalendarLinksSelector, hubspotCalendarLinks => hubspotCalendarLinks[OTHER_HUBSPOT_FORMS.REQUEST_DEMO] || '');
export const shipoutShutdownSettingsSelector = createSelector(settingsSelector, settings => settings.shipoutShutdown);
export const shipoutShutdownStateSelector = createSelector(settingsSelector, settings => settings.shipoutShutdown.state);
export const isShipoutInPreShutdownOrShutdownState = createSelector(shipoutShutdownSettingsSelector, ({
  state
}) => isShipoutPreShutdownOrShutdown(state));
export const isShipoutInPreShutdownState = createSelector(shipoutShutdownSettingsSelector, ({
  state
}) => isShipoutPreShutdown(state));
export const isShipoutInShutdownStateSelector = createSelector(shipoutShutdownSettingsSelector, ({
  state
}) => isShipoutShutdown(state));
export const shipoutShutdownDatesSelector = createSelector(shipoutShutdownSettingsSelector, ({
  startDate,
  endDate
}) => ({
  startDate,
  endDate
}));