function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { PRODUCTION_TIME_STANDART_TEXT } from 'swag-common/config/constants';
import { SUPER_SPEED_PRODUCTION_OPTION } from 'swag-common/constants/product.constants';
import { checkIsWholeBoxSuperSpeed } from './items/check-is-whole-box-super-speed.util';
export const defaultProdTime = {
  name: PRODUCTION_TIME_STANDART_TEXT,
  time: 0,
  cost: 0
};
function getMaxStandartProductionTime(items, boxProductionTime) {
  const maxProductionTime = items.reduce((acc, item) => {
    var _item$prod, _item$prod$productSet, _item$prod$productSet2;
    const productionTimeList = ((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$productSet = _item$prod.productSettings) === null || _item$prod$productSet === void 0 ? void 0 : (_item$prod$productSet2 = _item$prod$productSet[0]) === null || _item$prod$productSet2 === void 0 ? void 0 : _item$prod$productSet2.productionTimeList) || [];
    const standartProductionTime = productionTimeList.find(pt => pt.name === PRODUCTION_TIME_STANDART_TEXT);
    if (!standartProductionTime || standartProductionTime.time < acc.time) {
      return acc;
    }
    return standartProductionTime;
  }, boxProductionTime);
  return maxProductionTime;
}
function getMaxSelectedProductionTime(items, boxProductionTime) {
  const maxProductionTime = items.reduce((acc, item) => {
    var _item$prod2, _item$prod2$productSe, _item$prod2$productSe2;
    const productionTimeList = ((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : (_item$prod2$productSe = _item$prod2.productSettings) === null || _item$prod2$productSe === void 0 ? void 0 : (_item$prod2$productSe2 = _item$prod2$productSe[0]) === null || _item$prod2$productSe2 === void 0 ? void 0 : _item$prod2$productSe2.productionTimeList) || [];
    const selectedProductionTime = productionTimeList[item.prodTime];
    if (!selectedProductionTime || selectedProductionTime.time < acc.time) {
      return acc;
    }
    return selectedProductionTime;
  }, boxProductionTime);
  return maxProductionTime;
}
export const getBoxProductionTimeList = (selectedItem, items) => {
  var _selectedItem$prod, _selectedItem$prod$pr, _selectedItem$prod$pr2, _selectedItem$prod$pr3;
  const boxItems = items.filter(item => item.boxItemsId === selectedItem.boxItemsId);
  const boxProductionTime = ((_selectedItem$prod = selectedItem.prod) === null || _selectedItem$prod === void 0 ? void 0 : (_selectedItem$prod$pr = _selectedItem$prod.productSettings) === null || _selectedItem$prod$pr === void 0 ? void 0 : (_selectedItem$prod$pr2 = _selectedItem$prod$pr[0]) === null || _selectedItem$prod$pr2 === void 0 ? void 0 : (_selectedItem$prod$pr3 = _selectedItem$prod$pr2.productionTimeList) === null || _selectedItem$prod$pr3 === void 0 ? void 0 : _selectedItem$prod$pr3[0]) || defaultProdTime;
  const isSuperSpeedBox = checkIsWholeBoxSuperSpeed([selectedItem, ...boxItems]);
  if (isSuperSpeedBox) {
    const maxProductionTimeOption = getMaxSelectedProductionTime(boxItems, boxProductionTime);
    return [_objectSpread({}, maxProductionTimeOption, {
      name: SUPER_SPEED_PRODUCTION_OPTION
    })];
  }
  const maxStandartProductionTimeOption = getMaxStandartProductionTime(boxItems, boxProductionTime);
  return [maxStandartProductionTimeOption];
};
export const getBundleProductionTimeList = (selectedItem, bundleItems) => {
  const boxProductionTime = selectedItem.prod.productSettings[0].productionTimeList[0] || defaultProdTime;
  const maxStandartProductionTimeOption = getMaxStandartProductionTime(bundleItems, boxProductionTime);
  return [maxStandartProductionTimeOption];
};