export const editFieldTypeCreator = storePrefix => `${storePrefix}EDIT_FIELD`;
export const editFieldActionFactory = storePrefix => (fieldName, value) => ({
  type: editFieldTypeCreator(storePrefix),
  payload: {
    fieldName,
    value
  }
});
export const editShippingCountryFactory = storePrefix => value => dispatch => {
  dispatch({
    type: editFieldTypeCreator(storePrefix),
    payload: {
      fieldName: 'country',
      value
    }
  });
};
export const editShippingStateFactory = storePrefix => value => dispatch => {
  dispatch({
    type: editFieldTypeCreator(storePrefix),
    payload: {
      fieldName: 'state',
      value
    }
  });
};
export const updateShippingNameFactory = storePrefix => () => (dispatch, getState) => {
  const {
    delivery: {
      fname,
      lname,
      shippingNameUntouched
    }
  } = getState();
  if (shippingNameUntouched) {
    dispatch(editFieldActionFactory(storePrefix)('lnameShipping', lname));
    dispatch(editFieldActionFactory(storePrefix)('fnameShipping', fname));
  }
};
export const shippingNameTouchedTypeCreator = storePrefix => `${storePrefix}SHIPPING_NAME_TOUCHED`;
export const shippingNameEditActionFactory = storePrefix => () => ({
  type: shippingNameTouchedTypeCreator(storePrefix)
});
export const SET_SELECTED_ADDRESS_SUCCESS = 'SET_SELECTED_ADDRESS_SUCCESS';
export const setAddressAction = address => {
  return {
    type: SET_SELECTED_ADDRESS_SUCCESS,
    payload: {
      address: address || {
        state: 'AL',
        city: '',
        zip: ''
      }
    }
  };
};
export const SET_SHIPPING_VALIDATION_ERROR = 'SET_SHIPPING_VALIDATION_ERROR';
export const setShippingValidationError = (field, value) => {
  return {
    type: SET_SHIPPING_VALIDATION_ERROR,
    payload: {
      field,
      value
    }
  };
};