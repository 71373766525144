function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getDefaultGiveawayDescription, GiveawayProductChoice } from 'swag-common/constants/giveaway.constants';
import { addProductsToGiveawayTypeCreator, addProductsWithColorsToGiveawayTypeCreator, addProductWithColorsToGiveawayTypeCreator, removeProductWithColorsFromGiveawayTypeCreator } from 'swag-client-common/redux/partials/giveaway-products/giveaway-products.actions';
import { saveActiveGiveawayTypeCreator } from 'swag-client-common/redux/partials/giveaway-selected/giveaway-selected.actions';
import { GiveawayTypes } from 'swag-common/interfaces/giveaway';
import { getGiveawayInfoMergedFields } from 'swag-common/business-logic/giveaway/get-giveaway-info-merged-fields';
import { ADD_GIVEAWAY_SINGLE_LINK_DOMAIN, CHANGE_GIVEAWAY_DESCRIPTION, CHANGE_GIVEAWAY_NAME, REMOVE_GIVEAWAY_SINGLE_LINK_DOMAIN, SET_GIVEAWAY_CAP, SET_GIVEAWAY_CAP_NOT_VALIDATED, SET_GIVEAWAY_CAP_VALIDATED, SET_GIVEAWAY_LOGO, SET_GIVEAWAY_PRIMARY_COLOR, SET_GIVEAWAY_PRODUCT_CHOICE, SET_GIVEAWAY_PRODUCT_QUANTITY, TOGGLE_COLOR_GIVEAWAY, TOGGLE_IS_GIVEAWAY_CAP_ENABLED, SET_GIVEAWAY_TYPE } from '../actions/giveaway.actions';
import { GIVEAWAY_ADMIN_PREFIX } from '../actions/giveaway-common.actions';
export const giveawayEditedModelInitialState = {
  name: '',
  productChoice: GiveawayProductChoice.all,
  productQuantity: 1,
  colors: {},
  primaryColor: '',
  logo: '',
  domains: [],
  description: getDefaultGiveawayDescription(GiveawayTypes.swag),
  isCapEnabled: false,
  capValue: 1,
  submissions: 1,
  isValidatedCap: true,
  giveawayType: GiveawayTypes.swag
};
export const giveawayEditedModelReducerFactory = storePrefix => (state = giveawayEditedModelInitialState, action) => {
  switch (action.type) {
    case addProductsWithColorsToGiveawayTypeCreator(GIVEAWAY_ADMIN_PREFIX):
      {
        return _objectSpread({}, state, {
          colors: _objectSpread({}, action.payload.colors),
          capValue: action.payload.capValue,
          productQuantity: 1
        });
      }
    case addProductWithColorsToGiveawayTypeCreator(GIVEAWAY_ADMIN_PREFIX):
      {
        return _objectSpread({}, state, {
          colors: _objectSpread({}, state.colors, action.payload.colors),
          capValue: action.payload.capValue
        });
      }
    case removeProductWithColorsFromGiveawayTypeCreator(GIVEAWAY_ADMIN_PREFIX):
      {
        const {
          productId,
          capValue
        } = action.payload;
        const nextColors = _objectSpread({}, state.colors);
        delete nextColors[productId];
        return _objectSpread({}, state, {
          colors: nextColors,
          capValue
        });
      }
    case addProductsToGiveawayTypeCreator(GIVEAWAY_ADMIN_PREFIX):
      {
        return _objectSpread({}, state, {
          capValue: action.payload.capValue || state.capValue
        });
      }
    case CHANGE_GIVEAWAY_NAME:
      {
        return _objectSpread({}, state, {
          name: action.payload
        });
      }
    case CHANGE_GIVEAWAY_DESCRIPTION:
      {
        return _objectSpread({}, state, {
          description: action.payload
        });
      }
    case SET_GIVEAWAY_PRODUCT_CHOICE:
      {
        return _objectSpread({}, state, {
          productChoice: action.payload
        });
      }
    case SET_GIVEAWAY_PRODUCT_QUANTITY:
      {
        return _objectSpread({}, state, {
          productQuantity: Number(action.payload) || 1
        });
      }
    case TOGGLE_COLOR_GIVEAWAY:
      {
        const {
          colors: newColors
        } = action.payload;
        return _objectSpread({}, state, {
          colors: _objectSpread({}, state.colors, newColors)
        });
      }
    case SET_GIVEAWAY_PRIMARY_COLOR:
      return _objectSpread({}, state, {
        primaryColor: action.payload
      });
    case SET_GIVEAWAY_LOGO:
      return _objectSpread({}, state, {
        logo: action.payload
      });
    case ADD_GIVEAWAY_SINGLE_LINK_DOMAIN:
      return _objectSpread({}, state, {
        domains: Array.from(new Set([...state.domains, action.payload]))
      });
    case REMOVE_GIVEAWAY_SINGLE_LINK_DOMAIN:
      return _objectSpread({}, state, {
        domains: state.domains.filter(d => d !== action.payload)
      });
    case TOGGLE_IS_GIVEAWAY_CAP_ENABLED:
      return _objectSpread({}, state, {
        isCapEnabled: !state.isCapEnabled
      });
    case SET_GIVEAWAY_CAP:
      return _objectSpread({}, state, {
        capValue: action.payload
      });
    case saveActiveGiveawayTypeCreator(storePrefix):
      {
        const payload = action.payload;
        const giveawayInfo = getGiveawayInfoMergedFields(payload);
        return {
          name: giveawayInfo.name,
          productChoice: giveawayInfo.productChoice,
          productQuantity: giveawayInfo.productQuantity || 1,
          colors: giveawayInfo.colors,
          primaryColor: giveawayInfo.primaryColor,
          logo: giveawayInfo.logo,
          domains: giveawayInfo.domains || [],
          description: giveawayInfo.description || getDefaultGiveawayDescription(giveawayInfo.giveawayType),
          isCapEnabled: giveawayInfo.isCapEnabled || false,
          capValue: giveawayInfo.capValue || giveawayInfo.linksCompleted || state.capValue,
          submissions: giveawayInfo.linksCompleted || 0,
          isValidatedCap: state.isValidatedCap,
          giveawayType: giveawayInfo.giveawayType
        };
      }
    case SET_GIVEAWAY_CAP_VALIDATED:
      return _objectSpread({}, state, {
        isValidatedCap: true
      });
    case SET_GIVEAWAY_CAP_NOT_VALIDATED:
      return _objectSpread({}, state, {
        isValidatedCap: false
      });
    case SET_GIVEAWAY_TYPE:
      {
        return _objectSpread({}, state, {
          giveawayType: action.payload
        });
      }
    default:
      return state;
  }
};