function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isVariantInStock } from './is-variant-in-stock.util';
export function spreadQuantitiesToProducts(variants, quantityToSpread) {
  const inStockVariants = variants.filter(isVariantInStock);
  const totalAvailableQuantity = inStockVariants.reduce((acc, {
    quantity = 0,
    inProductionQuantity = 0,
    inReserveQuantity = 0
  }) => acc + quantity + inProductionQuantity - inReserveQuantity, 0);

  /**
   * handle equal variants quantities and quantity
   */
  if (totalAvailableQuantity === quantityToSpread) {
    return inStockVariants.reduce((acc, {
      _id,
      quantity = 0,
      inProductionQuantity = 0,
      inReserveQuantity = 0
    }) => {
      return _objectSpread({}, acc, {
        [String(_id)]: quantity + inProductionQuantity - inReserveQuantity
      });
    }, {});
  }
  const inStockSorted = inStockVariants.map(variant => {
    const {
      quantity = 0,
      inProductionQuantity = 0,
      inReserveQuantity = 0
    } = variant;
    return _objectSpread({}, variant, {
      ratio: (quantity + inProductionQuantity - inReserveQuantity) / totalAvailableQuantity
    });
  }).sort((a, b) => {
    const compare = b.ratio - a.ratio;
    if (compare === 0) {
      return a.sizeIndex - b.sizeIndex;
    }
    return compare;
  });
  const quantities = inStockSorted.reduce((acc, variant) => {
    return _objectSpread({}, acc, {
      [String(variant._id)]: Math.round(variant.ratio * quantityToSpread)
    });
  }, {});

  /**
   * Could be some additional or negative values
   * due to floating numbers problem in js
   * So it is necessary to fix that problem
   */
  const diff = quantityToSpread - Object.values(quantities).reduce((acc, val) => acc + val, 0);
  if (diff === 0) {
    return quantities;
  }
  if (diff > 0) {
    return inStockSorted.slice(0, diff).reduce((acc, variant) => {
      return _objectSpread({}, acc, {
        [String(variant._id)]: acc[String(variant._id)] + 1
      });
    }, quantities);
  }
  const acc = inStockSorted.filter(v => quantities[String(v._id)] > 0).slice(diff).reduce((acc, variant) => {
    return _objectSpread({}, acc, {
      [String(variant._id)]: acc[String(variant._id)] - 1
    });
  }, quantities);
  return acc;
}