export const dynamicContactsSelector = () => {
  var _storeSettings$addres;
  const {
    swag: {
      storeSettings
    }
  } = window;
  return {
    email: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyEmail,
    phone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyPhone,
    companyShowPhone: storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.companyShowPhone,
    countryCode: storeSettings === null || storeSettings === void 0 ? void 0 : (_storeSettings$addres = storeSettings.address) === null || _storeSettings$addres === void 0 ? void 0 : _storeSettings$addres.billingCountry,
    isChatAvailable: !!(storeSettings !== null && storeSettings !== void 0 && storeSettings.intercomAppId)
  };
};