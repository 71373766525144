import _reset from "swag-client-common/redux-form-actions/reset";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { find } from 'lodash/collection';
import { DEMO_INVENTORY_WARNING_POPUP, PAYMENT_ACCEPTED_POPUP, PAYMENT_AWAIT_APPROVE_POPUP, PFI_PAYMENT_POPUP, PULL_FROM_INVENTORY_POPUP, TRANSFER_PRODUCTS_POPUP } from 'swag-client-common/constants';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { showManualAddressEnterPopup } from 'swag-client-common/redux/partials/autocomplete/autocomplete.action';
import { checkIsAddressValidationNeeded, getAddressesFromStateForAutocompleteSelection, getAutocompleteAddressPayload } from 'swag-client-common/redux/partials/autocomplete/autocomplete.utils';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { checkIsApparelInBundle, checkIsBundleAddon } from 'swag-client-common/utils/product.utils';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { isAddressNotFoundByError, isAddressSuggestedByError } from 'swag-client-common/utils/manual-address-enter.utils';
import getBoxVariantSizeName from 'swag-common/business-logic/custom-store-product/get-box-variant-size-name.logic';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
import { CREDITS } from 'swag-common/constants/payment-methods';
import { DeliveryError } from 'swag-common/interfaces';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { spreadQuantitiesToProducts } from 'swag-common/utils/custom-store-product/spread-quantities-to-products.utils';
import { checkIsShippingRateFlat } from 'swag-common/utils/distributions/check-is-shipping-rate-flat.service';
import { FlatRatesTypes } from 'swag-common/constants/shipping.constants';
import { emailSelector, userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { userClickedSoaButton } from 'swag-client-common/redux/analytics/analytics.actions';
import { hideLoader, showLoader } from '../../../common/redux/common/common.actions';
import ShipoutApi from '../../api/shipout.api';
import { NO_APPAREL_SIZE } from '../../components/partials/swag-bundle/swag-bundle-notecard-step/constants';
import { getCustomProductsWarehouseQuantities } from '../../utils/advance-distributions.utils';
import { checkAccessRights, checkAccountStatus, checkShutdownRestriction } from '../../utils/enterprise-access-wrapper';
import { setManualAddressEnterAction, setSelectedAddress } from '../autocomplete/autocomplete.actions';
import { isAddressEnteredManuallySelector, selectedOptionSelector } from '../autocomplete/autocomplete.selectors';
import { getInventoryCredits, getPendingActivitiesAction, resetCreditsMoreToBuyFlow, setInventoryPurchaseFlow } from '../inventory/inventory.actions';
import { isCreditsMoreToBuyFlowSelector, isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { preparePaymentToInventoryProceed, preselectCreditCard } from '../payment-methods/payment-methods.actions';
import { inventoryPaymentBillingFormSelector, isManualPayment, paymentMethodIdSelector } from '../payment-methods/payment-methods.selectors';
import { gatherBillingAddressInfo, removePromocode } from '../payment/payment.actions';
import { paymentStateSelector, promocodeSelector } from '../payment/payment.selectors';
import { deselectAllProducts, deselectSimpleProduct, toggleSelectionProduct } from '../products/products.actions';
import { selectedProductsSelector, singleBundleContentVariantsSelector, singleProductPossibleSizesSelector, singleProductSelector, singleProductSingleColorVariantsSelector } from '../products/products.selectors';
import { PULL_FROM_INVENTORY_REDUX_FORM } from '../redux.constants';
import { isShipoutPaymentMethodDefault, shipoutPaymentMethodSelector } from '../send-to-multiple-addresses/send-to-multiple-addresses.selectors';
import { STRIPE_FORM_SUBMIT_ERROR, STRIPE_FORM_SUBMIT_SUCCESS } from '../stripe-form/stripe-form.actions';
import { bundleSizesSelector } from '../swag-bundles/swag-bundles.selectors';
import { getCommonSizeIndexFromVariant, getVariantBySizeName } from '../../utils/custom-products.utils';
import { getItemsForAnalytics } from '../../utils/get-items-for-analytics';
import { selectedProductsOutOfStockSelector, selectedProductsOutOfStockWithoutProductionSelector } from '../products/products-stocks.selectors';
import { PFITotalChargesSelector, draftShipoutIdSelector, formValuesSelector, nonEmptyQuantitiesSelector, onlyPositiveQuantitiesSelector, pfiIdSelector, selectedShippingMethodSelector, shippingAddressUntouchedSelector, singleBundleTotalQuantitySelector, singleQuantitiesByProductIdMapSelector, bundleQuantitiesSelector, quantitiesByCustomProductIdMapSelector, bundlesMapByQuantitySelector } from './pull-from-inventory.selectors';
const PREFIX = 'PULL_FROM_INVENTORY_';
export const CHANGE_QUANTITIES = `${PREFIX}CHANGE_QUANTITIES`;
export const CHANGE_QUANTITY_BY_PRODUCT_MAP = `${PREFIX}CHANGE_QUANTITY_BY_PRODUCT_MAP`;
export const CHANGE_QUANTITIES_BY_PRODUCT_MAP = `${PREFIX}CHANGE_QUANTITIES_BY_PRODUCT_MAP`;
export const SET_QUANTITIES = `${PREFIX}SET_QUANTITIES`;
export const preselectQuantities = () => (dispatch, getState) => {
  const selectedProducts = selectedProductsSelector(getState());
  const productsWithoutBundles = selectedProducts.filter(product => !isBundle(product.variants[0]));
  const quantities = productsWithoutBundles.reduce((acc, {
    variants
  }) => {
    return variants.reduce((_acc, {
      _id
    }) => _objectSpread({}, _acc, {
      [_id.toString()]: 0
    }), acc);
  }, {});
  dispatch({
    type: SET_QUANTITIES,
    payload: quantities
  });
};
const startTPWithSelectedProducts = () => (dispatch, getState) => {
  const state = getState();
  if (isSelectedDemoInventorySelector(state)) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.TransferProducts
    }));
  }
  const selectedProducts = selectedProductsSelector(state);
  const selectedProductsOutOfStock = selectedProductsOutOfStockWithoutProductionSelector(state);
  if (selectedProductsOutOfStock.length > 0) {
    dispatch(toggleSelectionProduct({
      productId: selectedProducts[0]._id,
      isSelected: false
    }));
    return dispatch(showNotification({
      text: `Products that are not in stock cannot be transferred: ${selectedProductsOutOfStock.map(({
        name
      }) => name).join(', ')}`,
      hideIn: 8000
    }));
  }
  dispatch(preselectQuantities());
  dispatch(showPopup({
    popup: TRANSFER_PRODUCTS_POPUP,
    deselectProductIdOnPopupClose: selectedProducts.length > 1 ? null : selectedProducts[0]
  }));
};
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST`;
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS`;
export const GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE = `${PREFIX}GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE`;
const getCustomProductsWarehouseQuantitiesSoa = successHandler => {
  return getCustomProductsWarehouseQuantities({
    requestActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_REQUEST,
    successActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_SUCCESS,
    failureActionType: GET_CUSTOM_PRODUCTS_WAREHOUSE_QUANTITIES_FAILURE,
    successHandler
  });
};
export const onTPSelectedProducts = checkAccountStatus(getCustomProductsWarehouseQuantitiesSoa(startTPWithSelectedProducts));
const startPFIWithSelectedProducts = btnLocation => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  const email = emailSelector(getState());
  const inventoryId = selectedInventorySelector(getState()) || '';
  dispatch(userClickedSoaButton({
    userId,
    email,
    inventoryId,
    btnLocation
  }));
  const selectedProductsOutOfStock = selectedProductsOutOfStockSelector(getState());
  if (selectedProductsOutOfStock.length > 0) {
    dispatch(deselectSimpleProduct());
    return dispatch(showNotification({
      text: `Products that are not in stock can not be pulled from inventory: ${selectedProductsOutOfStock.map(({
        name
      }) => name).join(', ')}`,
      hideIn: 8000
    }));
  }
  dispatch(preselectQuantities());
  dispatch(showPopup({
    popup: PULL_FROM_INVENTORY_POPUP
  }));
};
export const startPFIWithSelectedProductsWrappedIntoCheckAccountStatus = btnLocation => checkAccountStatus(getCustomProductsWarehouseQuantitiesSoa(() => startPFIWithSelectedProducts(btnLocation)))();
export const onPFISelectedProducts = checkShutdownRestriction(startPFIWithSelectedProductsWrappedIntoCheckAccountStatus);
export const RESET_USER_INPUT = `${PREFIX}RESET_USER_INPUT`;
export const resetUserInput = () => ({
  type: RESET_USER_INPUT
});
export const changeSingleQuantity = ({
  productId,
  colorValue,
  sizeIndex,
  quantity
}) => (dispatch, getState) => {
  const state = getState();
  const product = singleProductSelector(state, {
    productId
  });
  const size = singleProductPossibleSizesSelector(state, {
    productId
  });
  const variant = product.variants.find(v => {
    if (isBox(product.swagProductId)) {
      return getBoxVariantSizeName(v) === size.options[sizeIndex];
    }
    return v.colorValue === colorValue && v.sizeIndex === sizeIndex;
  });
  if (!variant) {
    return;
  }
  dispatch({
    type: CHANGE_QUANTITIES,
    payload: {
      [String(variant._id)]: quantity
    }
  });
  dispatch(resetReadyToPay());
};
export const handleBundleSingleQuantityChange = ({
  productId,
  sizeIndex,
  quantity
}) => (dispatch, getState) => {
  const state = getState();
  const bundleVariants = singleBundleContentVariantsSelector(state, {
    productId
  });
  const apparels = bundleVariants.filter(variant => checkIsApparelInBundle(variant));
  const alreadySelectedVariants = [];
  apparels.forEach(colorApparel => {
    var _mainQuantities$sizeI;
    const parentIdColorKey = `${colorApparel.parentId}_${colorApparel === null || colorApparel === void 0 ? void 0 : colorApparel.colorValue}`;
    if (alreadySelectedVariants.find(key => key === parentIdColorKey)) {
      return;
    }
    alreadySelectedVariants.push(parentIdColorKey);
    const apparelsForColor = apparels.filter(apparel => apparel.colorValue === colorApparel.colorValue && apparel.parentId === colorApparel.parentId).map(variant => _objectSpread({}, variant, {
      _id: variant.customProductId,
      quantity: variant.inStockQuantity
    }));
    const commonSizes = bundleSizesSelector(state, {
      productId
    }) || [];
    const sizeName = commonSizes[sizeIndex];
    const variant = getVariantBySizeName({
      variants: apparelsForColor,
      sizeName
    });
    if (!variant) {
      return;
    }
    const nonApparels = bundleVariants.filter(variant => !checkIsApparelInBundle(variant));
    const totalQuantity = singleBundleTotalQuantitySelector(state, {
      productId
    });
    const mainQuantities = singleQuantitiesByProductIdMapSelector(state, {
      productId
    });
    const nonApparelTotalQuantity = totalQuantity - ((_mainQuantities$sizeI = mainQuantities[sizeIndex]) !== null && _mainQuantities$sizeI !== void 0 ? _mainQuantities$sizeI : 0) + quantity;
    const quantities = nonApparels.reduce((acc, bundleAddonVariant) => _objectSpread({}, acc, {
      [String(bundleAddonVariant.customProductId)]: nonApparelTotalQuantity * bundleAddonVariant.quantityPerBundle
    }), {
      [String(variant.customProductId)]: quantity * variant.quantityPerBundle
    });
    dispatch({
      type: CHANGE_QUANTITY_BY_PRODUCT_MAP,
      payload: {
        productId,
        quantitiesByColor: quantities,
        mainQuantities: {
          [commonSizes[sizeIndex]]: quantity
        }
      }
    });
  });
};
export const handleColorQuantityChange = ({
  productId,
  colorValue,
  quantity
}) => (dispatch, getState) => {
  const variants = singleProductSingleColorVariantsSelector(getState(), {
    productId,
    selectedColor: colorValue
  });
  spreadAndChangeQuantitiesAction(dispatch, variants, quantity);
  dispatch(resetReadyToPay());
};
export const handleBundleQuantityChange = ({
  productId,
  quantity
}) => (dispatch, getState) => {
  const state = getState();
  const bundleContentVariants = singleBundleContentVariantsSelector(state, {
    productId
  });
  const apparel = bundleContentVariants.filter(variant => checkIsApparelInBundle(variant));
  const nonApparelNoAddonVariants = bundleContentVariants.filter(variant => !checkIsApparelInBundle(variant) && !checkIsBundleAddon(variant));
  const variants = [...apparel, ...nonApparelNoAddonVariants];
  const nonApparelVariants = bundleContentVariants.filter(variant => !checkIsApparelInBundle(variant));
  const alreadySelectedVariants = [];
  let spreadQuantities = {};
  const quantities = {};
  let mainQuantities = {};
  variants.forEach(colorApparel => {
    const parentIdColorKey = `${colorApparel.parentId}_${colorApparel === null || colorApparel === void 0 ? void 0 : colorApparel.colorValue}`;
    if (alreadySelectedVariants.find(key => key === parentIdColorKey)) {
      return;
    }
    alreadySelectedVariants.push(parentIdColorKey);
    const variantsByColor = variants.filter(apparel => apparel.colorValue === colorApparel.colorValue && apparel.parentId === colorApparel.parentId).map(variant => _objectSpread({}, variant, {
      _id: variant.customProductId
    }));
    const commonSizes = bundleSizesSelector(state, {
      productId
    }) || [];
    if (Object.keys(spreadQuantities).length === 0) {
      const {
        filteredVariants
      } = variantsByColor.reduce((acc, apparel) => {
        const commonSizeIndex = getCommonSizeIndexFromVariant({
          variant: apparel,
          commonSizes
        });
        if (commonSizeIndex === -1) {
          return acc;
        }
        const existVariants = acc.filteredVariants || [];
        return _objectSpread({}, acc, {
          filteredVariants: [...existVariants, apparel]
        });
      }, {
        filteredVariants: []
      });
      if (filteredVariants.length > 0) {
        const variantsWithQuantity = filteredVariants.map(v => _objectSpread({}, v, {
          quantity: v.inStockQuantity,
          inReserveQuantity: (v.inReserveProductionQuantity || 0) + (v.inReserveStockQuantity || 0)
        }));
        spreadQuantities = spreadQuantitiesToProducts(variantsWithQuantity, quantity);
      } else {
        // @todo need to add interface for FE usage and remove typecasting
        spreadQuantities = {
          [variantsByColor[0].customProductId]: quantity
        };
      }
      mainQuantities = Object.keys(spreadQuantities).reduce((acc, customProductId) => {
        const variant = variants.find(pr => String(pr.customProductId) === String(customProductId));
        if (variant) {
          const sizeIndex = getCommonSizeIndexFromVariant({
            variant,
            commonSizes
          });
          let size = NO_APPAREL_SIZE;
          let quantity = spreadQuantities[customProductId];
          if (commonSizes.length > 0) {
            size = commonSizes[sizeIndex];
            quantity = spreadQuantities[customProductId] || 0;
          }
          return _objectSpread({}, acc, {
            [size]: quantity
          });
        }
        return acc;
      }, {});
    }
    variantsByColor.forEach(apparel => {
      const {
        customProductId,
        quantityPerBundle
      } = apparel;
      const commonSizeIndex = getCommonSizeIndexFromVariant({
        variant: apparel,
        commonSizes
      });
      if (customProductId) {
        const size = commonSizes.length !== 0 ? commonSizes[commonSizeIndex] : NO_APPAREL_SIZE;
        const quantity = mainQuantities[size] || 0;
        quantities[customProductId.toString()] = quantity * quantityPerBundle;
      }
    });
    const nonApparelQuantities = nonApparelVariants.reduce((acc, variant) => _objectSpread({}, acc, {
      [String(variant.customProductId)]: quantity * variant.quantityPerBundle
    }), {});
    dispatch({
      type: CHANGE_QUANTITIES_BY_PRODUCT_MAP,
      payload: {
        productId,
        quantitiesByColor: _objectSpread({}, quantities, nonApparelQuantities),
        mainQuantities
      }
    });
  });
  dispatch(resetReadyToPay());
};
function spreadAndChangeQuantitiesAction(dispatch, variants, quantity) {
  const quantities = spreadQuantitiesToProducts(variants, quantity);
  dispatch({
    type: CHANGE_QUANTITIES,
    payload: quantities
  });
}
export function isZipStateMismatchError(err) {
  return err.status === 400 && err.code === DeliveryError.zipStateMismatch;
}
function getErrorHandlerAction(err) {
  switch (true) {
    case isAddressSuggestedByError(err):
      {
        return openManualAddressEnterPopup(err.address);
      }
    case isAddressNotFoundByError(err):
      {
        return openManualAddressErrorPopup();
      }
    case isZipStateMismatchError(err):
      {
        return showNotification({
          text: `We were unable to calculate your shipping costs.
          Postal code doesn't match the state.
          Please correct your information below to continue.`,
          hideIn: 8000
        });
      }
    default:
      return showNotification({
        text: err.message
      });
  }
}
export const CALCULATE_COSTS_STARTED = `${PREFIX}CALCULATE_COSTS_STARTED`;
export const CALCULATE_COSTS_SUCCESS = `${PREFIX}CALCULATE_COSTS_SUCCESS`;
export const CALCULATE_COSTS_ERROR = `${PREFIX}CALCULATE_COSTS_ERROR`;
export const calculateShipmentPackagingCost = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CALCULATE_COSTS_STARTED
    });
  },
  transformData: (data = {}, getState) => {
    const state = getState();
    const address = formValuesSelector(state);
    const isAutoCompleteTurnedOff = isAddressEnteredManuallySelector(state);
    const isAddressEnteredManually = !checkIsAddressValidationNeeded({
      isAutoCompleteTurnedOff,
      isManualAddressConfirmed: Boolean(data.isAddressEnteredManually),
      shippingForm: address,
      autocompleteSelection: selectedOptionSelector(state),
      shippingAddressUntouched: shippingAddressUntouchedSelector(state)
    });
    const draftShipoutId = draftShipoutIdSelector(state);
    let selectedShippingMethod = selectedShippingMethodSelector(state);
    const bundlesProductsMap = bundleQuantitiesSelector(state);
    const bundleIdProductsMap = quantitiesByCustomProductIdMapSelector(state);
    const bundlesMap = bundlesMapByQuantitySelector(state);
    if (bundlesMap && Object.values(bundlesMap).find(val => val > 1) && selectedShippingMethod === FlatRatesTypes.Domestic) {
      selectedShippingMethod = '03';
    }
    return {
      productsMap: nonEmptyQuantitiesSelector(state),
      bundlesProductsMap,
      bundleIdProductsMap,
      inventoryId: selectedInventorySelector(state),
      receiver: _objectSpread({}, address, {
        isAddressEnteredManually
      }),
      draftShipoutId,
      selectedShippingMethod,
      bundlesMap
    };
  },
  fn: ShipoutApi.calculateShipoutCosts,
  success: payload => (dispatch, getState) => {
    const state = getState();
    const newPayload = _objectSpread({}, payload);
    const selectedShippingMethod = selectedShippingMethodSelector(state);
    const shippingMethodInResponsePossibleDelivers = find(payload.possibleDeliveries, {
      serviceCode: selectedShippingMethod
    });
    const flatRate = find(payload.possibleDeliveries, checkIsShippingRateFlat);
    if (!!selectedShippingMethod && !!shippingMethodInResponsePossibleDelivers) {
      newPayload.selectedShippingMethod = selectedShippingMethod;
      newPayload.shippingPrice = shippingMethodInResponsePossibleDelivers.shippingPrice;
    }
    if (flatRate && !selectedShippingMethod) {
      newPayload.shippingPrice = flatRate.shippingPrice;
      newPayload.selectedShippingMethod = flatRate.serviceCode;
    }
    dispatch({
      type: CALCULATE_COSTS_SUCCESS,
      payload: newPayload
    });
  },
  error: (err, _, dispatch) => {
    dispatch(getErrorHandlerAction(err));
    return errorAction(CALCULATE_COSTS_ERROR)(err);
  }
});
export const RESET_READY_TO_PAY = `${PREFIX}RESET_READY_TO_PAY`;
export const resetReadyToPay = () => ({
  type: RESET_READY_TO_PAY
});
export const handlePaymentRequest = () => (dispatch, getState) => {
  const state = getState();
  const isCreditCardPaymentMethod = isShipoutPaymentMethodDefault(state);
  const isDemo = isSelectedDemoInventorySelector(state);
  const totalCostToPay = PFITotalChargesSelector(state);
  if (!isDemo) {
    dispatch(setInventoryPurchaseFlow(INVENTORY_PURCHASE_FLOWS.SOA));
  }
  if (totalCostToPay === 0) {
    dispatch(hidePopup());
    return dispatch(payForPFICreditCard());
  }
  if (isCreditCardPaymentMethod) {
    if (isDemo) {
      return dispatch(showPopup({
        popup: DEMO_INVENTORY_WARNING_POPUP,
        warningType: DemoInventoryWarningTypes.Products
      }));
    }
    dispatch(gatherBillingAddressInfo());
    dispatch(preselectCreditCard());
    return dispatch(showPopup({
      popup: PFI_PAYMENT_POPUP
    }));
  }
  dispatch(showLoader());
  dispatch(payForPFIInvoice({
    stripeToken: null
  }));
};
export const handleInventoryPaymentForPFISubmit = (stripe, card) => (dispatch, getState) => {
  const isManualCard = isManualPayment(getState());
  if (isManualCard) {
    dispatch(preparePaymentToInventoryProceed(stripe, card, token => dispatch(payForPFICreditCard({
      stripeToken: token.id
    }))));
  } else {
    dispatch(payForPFICreditCard({
      savedCard: true
    }));
  }
};
export const closePFIDrawer = (withHide = true) => dispatch => {
  dispatch(_reset(PULL_FROM_INVENTORY_REDUX_FORM));
  dispatch(setManualAddressEnterAction({
    isAddressEnteredManually: false
  }));
  if (withHide) {
    dispatch(hidePopup());
  }
};
export const PFI_PAYMENT_STARTED = `${PREFIX}PFI_PAYMENT_STARTED`;
export const PFI_PAYMENT_SUCCESS = `${PREFIX}PFI_PAYMENT_SUCCESS`;
export const PFI_PAYMENT_ERROR = `${PREFIX}PFI_PAYMENT_ERROR`;
export const payForPFIFactory = ({
  showNotifications
}) => {
  const request = createAsyncLoadedAction({
    preFn: dispatch => {
      dispatch({
        type: PFI_PAYMENT_STARTED
      });
    },
    transformData: ({
      stripeToken,
      savedCard
    } = {
      stripeToken: null
    }, getState) => {
      const state = getState();
      const promocode = promocodeSelector(state);
      const paymentMethod = shipoutPaymentMethodSelector(state);
      const fromInventoryId = selectedInventorySelector(state);
      const draftShipoutId = draftShipoutIdSelector(state);
      const bundlesProductsMap = bundleQuantitiesSelector(state);
      const bundleIdProductsMap = quantitiesByCustomProductIdMapSelector(state);
      const commonBody = {
        productsMap: onlyPositiveQuantitiesSelector(state),
        bundlesProductsMap,
        bundleIdProductsMap,
        deliverTo: formValuesSelector(state),
        fromInventoryId,
        inventoryId: fromInventoryId,
        paymentMethod,
        promocode: promocode ? promocode.code : null,
        selectedShippingMethod: selectedShippingMethodSelector(state),
        draftShipoutId,
        bundlesMap: bundlesMapByQuantitySelector(state)
      };
      if (savedCard) {
        const cardId = paymentMethodIdSelector(state);
        return _objectSpread({}, commonBody, {
          cardId
        });
      }
      const form = inventoryPaymentBillingFormSelector(state);
      if (form) {
        const {
          savePaymentDetails
        } = form.values;
        return _objectSpread({}, commonBody, {
          stripeToken,
          savePaymentDetails
        });
      }
      return _objectSpread({}, commonBody, {
        stripeToken
      });
    },
    fn: ShipoutApi.pullFromInventory,
    success: payload => (dispatch, getState) => {
      dispatch({
        type: STRIPE_FORM_SUBMIT_SUCCESS
      });
      const state = getState();
      const userId = userIdSelector(state);
      const email = emailSelector(state);
      const inventoryId = selectedInventorySelector(state);
      const items = getItemsForAnalytics(payload);
      dispatch({
        type: PFI_PAYMENT_SUCCESS,
        payload: _objectSpread({}, payload, {
          analytics: {
            userId,
            email,
            inventoryId,
            totalPaid: payload.totalAmountPaid,
            items
          }
        })
      });
      dispatch(setInventoryPurchaseFlow(null));
      const paymentMethod = shipoutPaymentMethodSelector(state);
      if (paymentMethod === CREDITS) {
        const inventoryId = selectedInventorySelector(state);
        dispatch(getInventoryCredits({
          inventoryId
        }));
      }
    },
    error: () => dispatch => {
      dispatch({
        type: STRIPE_FORM_SUBMIT_ERROR
      });
      dispatch({
        type: PFI_PAYMENT_ERROR
      });
      dispatch(setInventoryPurchaseFlow(null));
    },
    postError: (dispatch, getState, err) => {
      dispatch(hideLoader());
      dispatch(removePromocode());
      dispatch(showNotification({
        text: err.message
      }));
    },
    postSuccess: (dispatch, getState, _, res) => {
      const state = getState();
      const inventoryId = selectedInventorySelector(state);
      dispatch(hideLoader());
      dispatch(getPendingActivitiesAction({
        inventoryId
      }));
      const totalCharges = PFITotalChargesSelector(state);
      const promocode = promocodeSelector(state);
      const pfiId = pfiIdSelector(state);
      dispatch(removePromocode());
      const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(state);
      if (isCreditsToBuyMoreFlow) {
        dispatch(resetCreditsMoreToBuyFlow());
      }
      dispatch(closePFIDrawer());
      if (showNotifications) {
        dispatch(showNotification({
          text: 'Please check your email for an invoice',
          dataAttr: res._id
        }));
        return dispatch(hidePopup());
      }
      if (!totalCharges && promocode) {
        return dispatch(showPopup({
          popup: 'freePaymentSuccessPopup',
          params: {
            pfiId
          }
        }));
      } else {
        const popup = res && res.isApprovalFlow ? PAYMENT_AWAIT_APPROVE_POPUP : PAYMENT_ACCEPTED_POPUP;
        return dispatch(showPopup({
          popup
        }));
      }
    }
  });
  return checkAccessRights(request, DemoInventoryWarningTypes.Products);
};
export const payForPFICreditCard = payForPFIFactory({
  showNotifications: false
});
export const payForPFIInvoice = payForPFIFactory({
  showNotifications: true
});
export const onPullFromInventoryPaymentPopupClose = () => (dispatch, getState) => {
  const paymentState = paymentStateSelector(getState());
  if (paymentState === PAYMENT_STATES.SUCCESS) {
    dispatch(deselectAllProducts());
    return dispatch(hidePopup());
  }
  dispatch(hidePopup());
  setTimeout(() => dispatch(showPopup({
    popup: PULL_FROM_INVENTORY_POPUP
  })), 100);
};
export const SELECT_SHIPPING_METHOD = `${PREFIX}SELECT_SHIPPING_METHOD`;
export const selectShippingMethod = serviceCode => ({
  type: SELECT_SHIPPING_METHOD,
  payload: serviceCode
});
export const proceedSelectShippingMethod = serviceCode => (dispatch, getState) => {
  const selectedShippingMethod = selectedShippingMethodSelector(getState());
  if (serviceCode !== selectedShippingMethod) {
    dispatch(selectShippingMethod(serviceCode));
    dispatch(calculateShipmentPackagingCost({
      selectShippingMethod: serviceCode
    }));
  }
};
const proceedAutocompletePFIAddressSelection = (addresses, type, isRecalculateAdded) => dispatch => {
  const {
    address,
    isAddressEnteredManually
  } = getAutocompleteAddressPayload(addresses, type);
  dispatch(showPopup({
    popup: PULL_FROM_INVENTORY_POPUP
  }));
  dispatch(setSelectedAddress(address));
  if (isRecalculateAdded) {
    dispatch(calculateShipmentPackagingCost({
      isAddressEnteredManually
    }));
  }
};
export const SHIPPING_ADDRESS_TOUCHED = `${PREFIX}SHIPPING_ADDRESS_TOUCHED`;
export const editShippingAddress = () => ({
  type: SHIPPING_ADDRESS_TOUCHED
});
function openManualAddressEnterPopup(address) {
  return (dispatch, getState) => {
    const {
      enteredAddress,
      suggestedAddress
    } = getAddressesFromStateForAutocompleteSelection(formValuesSelector(getState()), address);
    dispatch(showManualAddressEnterPopup({
      confirmAddresses: [enteredAddress, suggestedAddress],
      onConfirmAddress: type => dispatch => {
        dispatch(proceedAutocompletePFIAddressSelection([enteredAddress, suggestedAddress], type, true));
      },
      onEnterAddress: () => dispatch => {
        dispatch(showPopup({
          popup: PULL_FROM_INVENTORY_POPUP
        }));
      }
    }));
  };
}
function openManualAddressErrorPopup() {
  return (dispatch, getState) => {
    const {
      enteredAddress
    } = getAddressesFromStateForAutocompleteSelection(formValuesSelector(getState()));
    dispatch(showManualAddressEnterPopup({
      confirmAddresses: [enteredAddress],
      onConfirmAddress: type => dispatch => {
        dispatch(proceedAutocompletePFIAddressSelection([enteredAddress], type, true));
      },
      onEnterAddress: () => dispatch => {
        dispatch(showPopup({
          popup: PULL_FROM_INVENTORY_POPUP
        }));
      }
    }));
  };
}