import { createSelector } from 'reselect';
import { INVENTORY_TABLE_ROWS_PER_PAGE } from 'swag-common/constants/inventory';
import { isApprovalFlowEnabledSelector, isInventoryAdminSelector, isLoadedInventoryEnteredSelector } from '../inventory/inventory.selectors';
import { advancedDistributionsSelector } from '../advanced-distributions/advanced-distributions.selectors';
const rootSelector = state => state.approvalDistributions;
export const distributionsListSelector = createSelector(rootSelector, state => state.list);
export const distributionsPageSelector = createSelector(rootSelector, state => state.page);
export const distributionsTotalSelector = createSelector(rootSelector, state => state.total);
export const selectedDistributionsStatusSelector = createSelector(rootSelector, state => state.status);
export const isDistributionsLoadedSelector = createSelector(rootSelector, isLoadedInventoryEnteredSelector, (state, isLoaded) => !state.isLoading && isLoaded);
export const isPaginationShownSelector = createSelector(distributionsTotalSelector, total => total > INVENTORY_TABLE_ROWS_PER_PAGE);
export const isDistributionsEmptySelector = createSelector(rootSelector, state => state.isEmpty);
export const hasPendingApprovals = createSelector(rootSelector, state => state.hasPending);
export const shouldShowPendingTab = createSelector(isInventoryAdminSelector, isApprovalFlowEnabledSelector, hasPendingApprovals, (isAdmin, isApprovalFlow, hasPendingApprovals) => isAdmin && (isApprovalFlow || hasPendingApprovals));
export const rejectingDistributionIdSelector = createSelector(rootSelector, state => state.rejectingDistributionId);
export const isRejectingDistributionOrderSelector = createSelector(rootSelector, state => state.isRejectingDistributionOrder);
export const rejectingDistributionSelector = createSelector(distributionsListSelector, rejectingDistributionIdSelector, (distributions, rejectingDistributionId) => distributions.find(({
  _id
}) => _id === rejectingDistributionId) || null);
export const haveUpcomingDistributionsForProductsSelector = createSelector(advancedDistributionsSelector, rejectingDistributionIdSelector, (distributions, rejectingDistributionId) => {
  return Boolean(distributions.filter(({
    distributionId
  }) => distributionId !== rejectingDistributionId).length);
});