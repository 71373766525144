import { ShipoutShutdownState } from 'swag-common/interfaces';
export const isShipoutPreShutdown = shipoutState => shipoutState === ShipoutShutdownState.PRE_SHUTDOWN;
export const isShipoutShutdown = shipoutState => shipoutState === ShipoutShutdownState.SHUTDOWN;
export const isShipoutPreShutdownOrShutdown = shipoutState => shipoutState === ShipoutShutdownState.SHUTDOWN || shipoutState === ShipoutShutdownState.PRE_SHUTDOWN;
export const dayInRange = ({
  startDate,
  endDate,
  day
}) => {
  return Boolean(startDate && endDate && new Date(startDate) < day && day < new Date(endDate));
};