import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { isDeactivatedStoreSelector, isResellerContractPendingSignWidgetShownSelector, isResellerContractSignedSelector } from 'swag-client-common/redux/tenant-store/tenant-store.selectors';
import { isUserResellerCompanyOwnerSelector } from 'swag-client-common/redux/user/user.selectors';
import { ResellerContractPendingSignWidgetComponent } from 'swag-client-common/components/reseller-contract-pending-sign-widget/reseller-contract-pending-sign-widget';
import { signResellerContract } from 'swag-client-common/redux/user/user.actions';
import { isCuratedBrandStoreInDraftSelector } from '../../../customer/redux/curated-brand-store/curated-brand-store-other.selector';
const isDeactivatedStoreForWidgetSelector = createSelector(isDeactivatedStoreSelector, isResellerContractSignedSelector, (isDeactivatedStore, isResellerContractSigned) => isDeactivatedStore && isResellerContractSigned);
const mapStateToProps = createStructuredSelector({
  isWidgetShown: isResellerContractPendingSignWidgetShownSelector,
  isResellerOwner: isUserResellerCompanyOwnerSelector,
  isCBSStoreInDraft: isCuratedBrandStoreInDraftSelector,
  isDeactivatedStore: isDeactivatedStoreForWidgetSelector
});
const mapDispatchToProps = dispatch => ({
  acceptResellerContract: () => {
    dispatch(signResellerContract());
  }
});
export const ResellerContractPendingSignWidget = connect(mapStateToProps, mapDispatchToProps)(ResellerContractPendingSignWidgetComponent);