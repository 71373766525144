import _isFinite from "lodash/isFinite";
import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dateFormat from 'dateformat';
import { Carrier } from 'swag-common/constants/shipping.constants';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { DesignUploadMode } from '../interfaces';
import * as ITEM_STATUSES from '../constants/item-statuses.constants';
import { NET_15, NET_30, UPFR_50_NET_15, UPFR_50_NET_30, ACH, CREDIT_CARD, PAYMENT_METHODS, CREDITS, STRIPE_CARD, CREDITS_COMMON_TITLE, REPLACEMENT_PAYMENT, DUMMY_PAYMENT, NET_45, NET_60 } from '../constants/payment-methods';
import { getDataForGettingItemShippingCost, getItemShippingCost } from '../business-logic/order/get-item-shipping-cost.service';
import { addBusinessDays } from '../utils/add-business-days.util';
import { IN_HAND_DATE_INTERVAL } from '../constants/main-swag.constants';
import { Price } from './price';
import { isBox } from './order/item-is-box.util';
import { formatTo_MMM_Do } from './date-lib';
import { getArtificialClientStatus } from './items/artificial-cbs-item-status.utils';
const getItemStatus = ({
  code,
  paymentMethod = CREDIT_CARD,
  isFinalPaymentReceived,
  isBoxItem,
  isReviewPantonesByCustomerAllowed,
  isReviewMockupsByCustomerAllowed
}) => {
  switch (code) {
    case 0:
      return 'Display all';
    case ITEM_STATUSES.INITIAL:
      return getStatusTextByPaymentMethod(paymentMethod);
    case ITEM_STATUSES.PO_RECEIVED:
      return 'PO received';
    case ITEM_STATUSES.REFUNDED:
    case ITEM_STATUSES.CANCELLED:
      return 'Cancelled';
    case ITEM_STATUSES.PREPAYMENT_RECEIVED:
      return 'Prepayment received';
    case ITEM_STATUSES.PAID:
      return 'Paid';
    case ITEM_STATUSES.PENDING_APPROVAL:
      return 'Pending approval';
    case ITEM_STATUSES.COLORS_REVIEW_BY_RESELLER_REQUESTED:
      return 'Requested colors review by Reseller';
    case ITEM_STATUSES.COLORS_APPROVED_BY_RESELLER:
      return 'Colors approved by Reseller';
    case ITEM_STATUSES.COLORS_REVIEW_BY_CUSTOMER_REQUESTED:
      return getCustomerColorsReviewStatusText(isReviewPantonesByCustomerAllowed);
    case ITEM_STATUSES.COLORS_APPROVED_BY_CUSTOMER:
      return 'Colors approved by Customer';
    case ITEM_STATUSES.SENT_TO_VENDOR:
      return 'Order sent to vendor';
    case ITEM_STATUSES.MOCKUP_APPROVAL_BY_RESELLER:
      return 'Mockup sent to Reseller';
    case ITEM_STATUSES.MOCKUP_REVISION_BY_RESELLER:
      return 'Reseller asked for revisions';
    case ITEM_STATUSES.MOCKUP_APPROVED_BY_RESELLER:
      return 'Mockup approved by Reseller';
    case ITEM_STATUSES.MOCKUP_APPROVAL_BY_CUSTOMER:
      return getCustomerMockupReviewStatusText(isReviewMockupsByCustomerAllowed);
    case ITEM_STATUSES.MOCKUP_REVISION_BY_CUSTOMER:
      return 'Customer asked for revisions';
    case ITEM_STATUSES.MOCKUP_APPROVED_BY_CUSTOMER:
      return 'Mockup approved by Customer';
    case ITEM_STATUSES.IN_PRODUCTION:
      return 'In production';
    case ITEM_STATUSES.CONTINUED_PRODUCTION:
      return 'Continued production';
    case ITEM_STATUSES.NOTICE_SENT_WAREHOUSE:
      return 'Notice sent to Warehouse';
    case ITEM_STATUSES.SHIPPED:
      return 'Product shipped';
    case ITEM_STATUSES.SHIPPED_TO_CUSTOMER:
      return 'Product shipped to customer';
    case ITEM_STATUSES.DELIVERED:
      return getFinalDeliveredText(isBoxItem);
    case ITEM_STATUSES.DELIVERED_TO_WAREHOUSE:
      return 'Delivered to warehouse';
    case ITEM_STATUSES.READY_FOR_DISTRIBUTION:
      return 'Ready for distribution';
    case ITEM_STATUSES.FINAL_PAYMENT:
      return getFinalPaymentText(isFinalPaymentReceived);
    case ITEM_STATUSES.ABANDONED:
      return 'Abandoned item';
    case ITEM_STATUSES.REJECTED:
      return 'Rejected';
    default:
      return `unexpected code ${code}`;
  }
};
const getClientStatus = ({
  status,
  variants,
  paymentMethod,
  isFinalPaymentReceived,
  itemSample = false,
  itemFullDesign = false,
  isWarehouseFlow = false,
  isBlank = false,
  isResellerTenant = false,
  boxType,
  isReviewPantonesByCustomerAllowed = true,
  isReviewMockupsByCustomerAllowed = true,
  shouldUseArtificialStatusCode
}) => {
  if (shouldUseArtificialStatusCode && variants) {
    return getArtificialClientStatus({
      isFullDesign: itemFullDesign,
      status,
      variants,
      paymentMethod
    });
  }
  switch (status) {
    case ITEM_STATUSES.INITIAL:
      return getClientTextByPaymentMethod(paymentMethod);
    case ITEM_STATUSES.PO_RECEIVED:
      return 'PO sended';
    case ITEM_STATUSES.CANCELLED:
    case ITEM_STATUSES.REFUNDED:
      return 'Cancelled';
    case ITEM_STATUSES.PREPAYMENT_RECEIVED:
      return 'Prepayment received';
    case ITEM_STATUSES.PAID:
      {
        const isColoredBox = boxType === SPECIAL_PRODUCT_TYPES.COLORED_BOX;
        const isBrownBox = boxType === SPECIAL_PRODUCT_TYPES.BROWN_BOX;
        const isCustomBox = boxType === SPECIAL_PRODUCT_TYPES.CUSTOM_BOX;
        const isFullyCustomBox = boxType === SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX;
        if (itemSample || isBlank || isColoredBox || isBrownBox || isResellerTenant) {
          return 'Processing order';
        }
        if (itemFullDesign || isCustomBox || isFullyCustomBox) {
          return 'Creating production mockup';
        }
        return 'Review colors';
      }
    case ITEM_STATUSES.PENDING_APPROVAL:
      return 'Pending approval';
    case ITEM_STATUSES.COLORS_REVIEW_BY_RESELLER_REQUESTED:
      return 'Processing order';
    case ITEM_STATUSES.COLORS_REVIEW_BY_CUSTOMER_REQUESTED:
      {
        if (!isReviewPantonesByCustomerAllowed) {
          return 'Creating production mockup';
        }
        return 'Review colors';
      }
    case ITEM_STATUSES.COLORS_APPROVED_BY_CUSTOMER:
    case ITEM_STATUSES.SENT_TO_VENDOR:
    case ITEM_STATUSES.MOCKUP_REVISION_BY_RESELLER:
    case ITEM_STATUSES.MOCKUP_APPROVAL_BY_RESELLER:
      {
        if (itemSample || isBlank) {
          return 'Processing order';
        }
        return 'Creating production mockup';
      }
    case ITEM_STATUSES.MOCKUP_APPROVAL_BY_CUSTOMER:
      {
        if (!isReviewMockupsByCustomerAllowed) {
          return 'In production';
        }
        return 'View production mockup';
      }
    case ITEM_STATUSES.MOCKUP_APPROVED_BY_CUSTOMER:
    case ITEM_STATUSES.IN_PRODUCTION:
    case ITEM_STATUSES.CONTINUED_PRODUCTION:
    case ITEM_STATUSES.NOTICE_SENT_WAREHOUSE:
      return 'In production';
    case ITEM_STATUSES.MOCKUP_REVISION_BY_CUSTOMER:
      return 'Requested revisions';
    case ITEM_STATUSES.SHIPPED:
      return getRegularTracking(isWarehouseFlow);
    case ITEM_STATUSES.SHIPPED_TO_CUSTOMER:
      return 'Tracking number';
    case ITEM_STATUSES.DELIVERED:
      return 'Delivered';
    case ITEM_STATUSES.FINAL_PAYMENT:
      return getFinalPaymentText(isFinalPaymentReceived);
    case ITEM_STATUSES.REJECTED:
      return 'Rejected';
    case ITEM_STATUSES.DELIVERED_TO_WAREHOUSE:
      return 'Delivered to Warehouse';
    case ITEM_STATUSES.READY_FOR_DISTRIBUTION:
      return 'Ready for distribution';
    default:
      return `unexpected code ${status}`;
  }
};
function getRegularTracking(isWarehouseFlow) {
  return isWarehouseFlow ? 'In production' : 'Tracking number';
}
function getFinalPaymentText(isFinalPaymentReceived) {
  return isFinalPaymentReceived ? 'Final payment is received' : 'Waiting for final payment';
}
function getFinalDeliveredText(isBoxItem) {
  return isBoxItem ? 'Delivered to customer' : 'Delivered';
}
function getStatusTextByPaymentMethod(payMethod) {
  return statusTextHelper('Waiting for', payMethod);
}
function getCustomerColorsReviewStatusText(isReviewPantonesByCustomerAllowed) {
  return isReviewPantonesByCustomerAllowed ? 'Requested colors review by Customer' : 'Colors approved by Reseller';
}
function getCustomerMockupReviewStatusText(isReviewMockupsByCustomerAllowed) {
  return isReviewMockupsByCustomerAllowed ? 'Mockup sent to Customer' : 'Mockup approved by Reseller';
}
export function getClientTextByPaymentMethod(payMethod) {
  return statusTextHelper('', payMethod, 'needed');
}
function statusTextHelper(prefix = '', payMethod, suffix = '') {
  if ([DUMMY_PAYMENT, CREDIT_CARD, REPLACEMENT_PAYMENT].includes(payMethod)) {
    return '';
  }
  if (ACH === payMethod) {
    return `${prefix} Prepayment ${suffix}`;
  }
  if ([NET_15, NET_30, NET_45, NET_60, UPFR_50_NET_15, UPFR_50_NET_30].includes(payMethod)) {
    return `${prefix} PO ${suffix}`;
  }
  throw new Error(`Unexpected payment type ${payMethod}`);
}
const isPaymentCC = paymentMethod => paymentMethod === CREDIT_CARD;
const isPaymentStripeCreditCard = paymentMethod => paymentMethod === STRIPE_CARD;
const isPaymentCredits = paymentMethod => paymentMethod === CREDITS;
const isPaymentNet = paymentMethod => [NET_15, NET_30, NET_45, NET_60].includes(paymentMethod);
const isPaymentNetUpfront = paymentMethod => [UPFR_50_NET_15, UPFR_50_NET_30].includes(paymentMethod);
const isPaymentAch = paymentMethod => paymentMethod === ACH;
const isPaymentInvoice = paymentMethod => isPaymentNetUpfront(paymentMethod) || isPaymentNet(paymentMethod) || isPaymentAch(paymentMethod);
const isReplacementPayment = paymentMethod => paymentMethod === REPLACEMENT_PAYMENT;
const getShipment = order => _get(order, 'shipmentCustomerTotal', _get(order, 'paymentPrices.shipment'));
const getTaxes = order => _get(order, 'taxesPrice', _get(order, 'paymentPrices.taxes'));
const getShipmentTotalByItems = items => items.reduce((acc, item) => {
  if (item.boxItemsId && !isBox(item)) {
    return acc;
  }
  const shippingCost = getItemShippingCost(getDataForGettingItemShippingCost(item, items));
  return acc + shippingCost;
}, 0);
const getNotRejectedItemTaxes = order => {
  if (order && Array.isArray(order.items)) {
    return order.items.filter(isStatedItem).reduce((acc, item) => {
      var _item$tax;
      return acc + ((_item$tax = item.tax) !== null && _item$tax !== void 0 ? _item$tax : 0);
    }, 0);
  }
  return 0;
};
const getTotal = order => _get(order, 'total', _get(order, 'paymentPrices.total'));
const getGrandTotal = order => {
  const replacementDiscount = Price.getReplacementDiscount(order.items);
  const total = _get(order, 'paymentPrices.grandTotal') - replacementDiscount;
  return total || 0;
};
const getKsFulfillment = order => _get(order, 'ksFulfillment', 0);
function getPaymentMethodByCode(paymentMethod) {
  return PAYMENT_METHODS.find(payMethod => payMethod.code === paymentMethod) || PAYMENT_METHODS[0];
}
function getPaymentMethodForOrderItem(paymentMethod) {
  var _getPaymentMethodByCo;
  if (paymentMethod === CREDITS) {
    return CREDITS_COMMON_TITLE;
  }
  return ((_getPaymentMethodByCo = getPaymentMethodByCode(paymentMethod)) === null || _getPaymentMethodByCo === void 0 ? void 0 : _getPaymentMethodByCo.name) || 'Unknown';
}
function getPaymentMethodForDistributions(paymentMethod) {
  var _getPaymentMethodByCo2;
  if (paymentMethod === CREDITS) {
    return CREDITS_COMMON_TITLE;
  }
  const paymentMethodToCalculate = paymentMethod === STRIPE_CARD ? CREDIT_CARD : paymentMethod;
  return ((_getPaymentMethodByCo2 = getPaymentMethodByCode(paymentMethodToCalculate)) === null || _getPaymentMethodByCo2 === void 0 ? void 0 : _getPaymentMethodByCo2.name) || 'N/A';
}
function getFlatItems(items) {
  return items.reduce((all, item) => {
    if (Array.isArray(item)) {
      return [...all, ...item];
    }
    return [...all, item];
  }, []);
}
function getWorstItemStatus(order) {
  if (!order || !order.items || !order.items[0]) {
    return null;
  }
  return order.items.reduce((acc, item) => {
    if (item.status < acc) {
      return item.status;
    }
    return acc;
  }, order.items[0].status);
}
function groupItemsIntoBoxes(items) {
  return items.reduce((all, item) => {
    const boxItems = items.filter(i => i.boxItemsId && i.boxItemsId === item.boxItemsId && String(i._id) !== String(item._id));
    if (item.prod && item.prod.designUploadMode === DesignUploadMode.box) {
      const updatedItem = _objectSpread({}, item, {
        products: boxItems
      });
      return [...all, updatedItem];
    }
    if (!boxItems.length) {
      return [...all, item];
    }
    return all;
  }, []);
}
export function getItemWithLowestStatus(items) {
  const isAllItemsHasNegativeStatus = items.every(item => item.status <= ITEM_STATUSES.REJECTED);
  const defaultItemWithLowestStatus = isAllItemsHasNegativeStatus ? items[0] : items.find(isStatedItem);
  return (items || []).reduce((acc, item) => {
    if (!isAllItemsHasNegativeStatus && item.status <= ITEM_STATUSES.REJECTED) {
      return acc;
    }
    if (isAllItemsHasNegativeStatus && item.status > acc.status) {
      return item;
    }
    if (item.status < acc.status) {
      return item;
    }
    return acc;
  }, defaultItemWithLowestStatus);
}
export const getItemWithEarliestInHandDate = items => {
  if (!items || items && !items.length) {
    return;
  }
  const earliestInHand = Math.min(...items.map(item => item.expectedByCustomerDates.inHandDate).filter(Boolean));
  const earliestItem = items.filter(item => item.expectedByCustomerDates.inHandDate === earliestInHand);
  return earliestItem[0];
};
export const getItemWithEarliestOperationsInHandDate = items => {
  if (!(items !== null && items !== void 0 && items.length)) {
    return;
  }
  const earliestOperationsInHandDate = Math.min(...items.map(item => item.expectedByCustomerDates.operationsInHandDate).filter(Boolean));
  const earliestItem = items.find(item => item.expectedByCustomerDates.operationsInHandDate === earliestOperationsInHandDate);
  return earliestItem;
};
export const getInHandDateFormatted = ({
  inHandDate,
  isStrictInHandDate,
  format = 'MMM Do'
}) => {
  if (!inHandDate || !_isFinite(new Date(inHandDate).getTime())) {
    return null;
  }
  const formatter = format === 'MMM Do' ? formatTo_MMM_Do : date => dateFormat(date, format);
  return isStrictInHandDate ? formatter(new Date(inHandDate)) : `${formatter(new Date(inHandDate))} - ${formatter(addBusinessDays({
    date: new Date(inHandDate !== null && inHandDate !== void 0 ? inHandDate : Date.now()),
    daysToAdd: IN_HAND_DATE_INTERVAL,
    cutOffTime: false
  }))}`;
};
function getFullyDeliveredBoxes(items, skipFilter = false) {
  let boxes = groupItemsIntoBoxes(items);
  if (!skipFilter) {
    boxes = boxes.filter(box => !box.warehouseShipmentNumber);
  }
  return boxes.reduce((all, next) => {
    if (next.products && next.products.length) {
      const fullBox = [next, ...next.products];
      const isFullBoxDelivered = fullBox.filter(item => item.status !== ITEM_STATUSES.CANCELLED).every(i => isDeliveredToWarehouseItem(i));
      if (isFullBoxDelivered) {
        return _objectSpread({}, all, {
          [next.boxItemsId]: fullBox.map(i => String(i._id))
        });
      }
    }
    return all;
  }, {});
}
function getTrackingLink(shippingCompany, trackingNumber) {
  if (!shippingCompany || !trackingNumber) {
    return '';
  }
  switch (shippingCompany.toUpperCase()) {
    case Carrier.FEDEX:
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}&cntry_code=in`;
    case Carrier.UPS:
      return `https://www.ups.com/WebTracking/track?loc=en_us&track=yes&trackNums=${trackingNumber}`;
    case Carrier.DHL:
      return `https://webtrack.dhlglobalmail.com/orders?trackingNumber=${trackingNumber}`;
    case Carrier.USPS:
      return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}%2C`;
    default:
      return `https://www.ups.com/WebTracking/track?loc=en_us&track=yes&trackNums=${trackingNumber}`;
  }
}
export function isAllItemsCancelled(items) {
  return items.every(isCancelledItem);
}
export const isCancelledItem = item => item.status ? item.status <= ITEM_STATUSES.CANCELLED : false;
export const isRefundedItem = item => item.status ? item.status <= ITEM_STATUSES.REFUNDED : false;
export const excludeRejectedAndAbandoned = item => item.status !== ITEM_STATUSES.REJECTED && item.status !== ITEM_STATUSES.ABANDONED;
export const isStatedItem = item => item.status ? item.status > ITEM_STATUSES.ABANDONED : false;
export const isDeliveredToWarehouseItem = item => item.status ? item.status >= ITEM_STATUSES.DELIVERED_TO_WAREHOUSE : false;
export const isItemShippedToCustomer = item => item.status ? item.status >= ITEM_STATUSES.SHIPPED_TO_CUSTOMER : false;
export const isWholeBoxDeliveredToWH = items => {
  return items.length ? items.every(item => isDeliveredToWarehouseItem(item)) : false;
};
export const isItemRejected = item => item.status ? item.status === ITEM_STATUSES.REJECTED : false;
export const isLEItem = item => {
  var _item$prod, _item$prod$limitedEdi;
  return Boolean((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$limitedEdi = _item$prod.limitedEdition) === null || _item$prod$limitedEdi === void 0 ? void 0 : _item$prod$limitedEdi.isLimitedEdition);
};
export const isReadyForDistribution = item => Boolean(item.isDeliveredToFulfillmentCenter && item.status && item.status >= ITEM_STATUSES.READY_FOR_DISTRIBUTION);
export const isBrownBoxTape = item => {
  var _item$prod2;
  return ((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_TAPE;
};
export const isGiveawayNotecard = obj => (obj === null || obj === void 0 ? void 0 : obj.specialProductType) === SPECIAL_PRODUCT_TYPES.GIFT_GIVEAWAY_NOTECARD;
export const calculateOrderShipmentByItems = order => {
  return order.items.reduce((acc, item) => {
    if (isCancelledItem(item) || item.IS_FREE_SHIPPING) {
      return acc += 0;
    }
    acc += Price.getItemShippingWithoutBoxContent(item, order.items) || 0;
    return acc;
  }, 0);
};
export { getItemStatus, getClientStatus, getPaymentMethodByCode, getPaymentMethodForDistributions, getPaymentMethodForOrderItem, isPaymentCC, isPaymentStripeCreditCard, isPaymentNet, isPaymentNetUpfront, isPaymentAch, isPaymentInvoice, isPaymentCredits, isReplacementPayment, getShipment, getShipmentTotalByItems, getTaxes, getNotRejectedItemTaxes, getTotal, getGrandTotal, getKsFulfillment, getFlatItems, groupItemsIntoBoxes, getWorstItemStatus, getFullyDeliveredBoxes, getTrackingLink };