import { ProductApi } from 'swag-client-common/api/product.api';
import { SWAG_BUNDLE_ORDER_MORE_POPUP } from 'swag-client-common/constants';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import applyQuantityToProduct from 'swag-common/utils/product/apply-quantity-to-product.util';
import applyQuantityToVariant from 'swag-common/utils/product/apply-quantity-to-variant.util';
import { emailSelector, userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { userClickedOrderMoreButton } from 'swag-client-common/redux/analytics/analytics.actions';
import { startBoxReorder } from '../order-more-box/order-more-box.actions';
import { boxProductsSelector } from '../order-more-box/order-more-box.selectors';
import { singleProductSelectorByProductId } from '../products/products.selectors';
import { selectedInventorySelector } from '../inventory/inventory.selectors';
import { minQuantitySelector, selectedProductSelector, selectedSizeIndexSelector, selectedVariantsInStockLevelsSelector, sizeSelector, swagProductSelector, variantIndexesInCustomStoreSelector, variantIndexesSelector } from './order-more.selectors';
const PREFIX = 'ORDER_MORE_';
export const GET_SUPPLIER_INVENTORY_LEVELS_REQUEST = `${PREFIX}GET_SUPPLIER_INVENTORY_LEVELS_REQUEST`;
export const GET_SUPPLIER_INVENTORY_LEVELS_SUCCESS = `${PREFIX}GET_SUPPLIER_INVENTORY_LEVELS_SUCCESS`;
export const GET_SUPPLIER_INVENTORY_LEVELS_ERROR = `${PREFIX}GET_SUPPLIER_INVENTORY_LEVELS_ERROR`;
export const getSupplierStockLevels = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_SUPPLIER_INVENTORY_LEVELS_REQUEST
  }),
  fn: ProductApi.getSupplierStockLevels,
  success: response => ({
    type: GET_SUPPLIER_INVENTORY_LEVELS_SUCCESS,
    payload: response
  }),
  error: errorAction(GET_SUPPLIER_INVENTORY_LEVELS_ERROR),
  postSuccess: (dispatch, getState) => {
    const product = selectedProductSelector(getState());
    if (product) {
      dispatch(prefillWithMinQuant(getState()));
    }
  }
});
export const handleOrderMoreRequest = productId => (dispatch, getState) => {
  dispatch(selectProductToOrder(productId));
  const state = getState();
  const currentProductData = singleProductSelectorByProductId(state, {
    productId
  });
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state) || '';
  dispatch(userClickedOrderMoreButton(userId, email, inventoryId));
  if (isBundle(currentProductData.variants[0])) {
    dispatch(showPopup({
      popup: SWAG_BUNDLE_ORDER_MORE_POPUP
    }));
    return;
  }
  const currentSwagProduct = swagProductSelector(state);
  const productIds = [currentSwagProduct._id];
  const supplierIds = [currentSwagProduct.supplier];
  if (isBox(currentSwagProduct)) {
    const boxProducts = boxProductsSelector(state);
    boxProducts.forEach(item => {
      productIds.push(item._id);
      supplierIds.push(item.supplier);
    });
    dispatch(getSupplierStockLevels({
      productIds,
      supplierIds
    }));
    return dispatch(startBoxReorder());
  }
  dispatch(getSupplierStockLevels({
    productIds,
    supplierIds
  }));
  dispatch(showPopup({
    popup: 'orderMorePopup'
  }));
};
export const SELECT_PRODUCT = `${PREFIX}SELECT_PRODUCT`;
export const selectProductToOrder = productId => dispatch => {
  dispatch({
    type: SELECT_PRODUCT,
    payload: productId
  });
};
export const PREFILL_QUANTITY = `${PREFIX}PREFILL_QUANTITY`;
export const prefillWithMinQuant = state => {
  const product = selectedProductSelector(state);
  const variantIndexes = variantIndexesInCustomStoreSelector(state);
  const selectedSizeIndex = selectedSizeIndexSelector(state);
  const size = sizeSelector(state);
  const minQuant = minQuantitySelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  const quantities = applyQuantityToProduct({
    threshold: 0,
    product: product === null || product === void 0 ? void 0 : product.swagProductId,
    selectedVariants: variantIndexes,
    selectedSizeIndex,
    sizeSettings: size,
    quantity: minQuant * variantIndexes.length,
    selectedVariantsInStockLevels
  });
  return {
    type: PREFILL_QUANTITY,
    payload: Object.keys(quantities).map(variantIndex => ({
      quantities: quantities[variantIndex],
      variantIndex: +variantIndex
    }))
  };
};
export const resetVariantsAndQuantities = () => {
  return {
    type: PREFILL_QUANTITY,
    payload: []
  };
};
export const ADD_COLOR = `${PREFIX}ADD_COLOR`;
export const addColorToOrder = variant => (dispatch, getState) => {
  const state = getState();
  const product = selectedProductSelector(state);
  const selectedSizeIndex = selectedSizeIndexSelector(state);
  const size = sizeSelector(state);
  const minQuant = minQuantitySelector(state);
  const quantities = applyQuantityToProduct({
    threshold: 0,
    product: product === null || product === void 0 ? void 0 : product.swagProductId,
    selectedVariants: [variant.variantIndex],
    selectedSizeIndex,
    sizeSettings: size,
    quantity: minQuant
  });
  dispatch({
    type: ADD_COLOR,
    payload: {
      quantities: quantities[variant.variantIndex],
      variantIndex: +variant.variantIndex
    }
  });
};
export const REMOVE_COLOR = `${PREFIX}REMOVE_COLOR`;
export const removeColor = variantIndex => ({
  type: REMOVE_COLOR,
  payload: +variantIndex
});
export const QUANTITIES_CHANGE = `${PREFIX}QUANTITIES_CHANGE`;
export const handleGeneralQuantityChange = quantity => (dispatch, getState) => {
  const state = getState();
  const product = selectedProductSelector(state);
  const variantIndexes = variantIndexesSelector(state);
  const selectedSizeIndex = selectedSizeIndexSelector(state);
  const size = sizeSelector(state);
  const selectedVariantsInStockLevels = selectedVariantsInStockLevelsSelector(state);
  const quantities = applyQuantityToProduct({
    threshold: 0,
    product: product === null || product === void 0 ? void 0 : product.swagProductId,
    quantity,
    selectedVariants: variantIndexes,
    selectedSizeIndex,
    sizeSettings: size,
    selectedVariantsInStockLevels
  });
  dispatch({
    type: QUANTITIES_CHANGE,
    payload: Object.keys(quantities).map(variantIndex => ({
      quantities: quantities[variantIndex],
      variantIndex: +variantIndex
    }))
  });
};
export const CHANGE_SINGLE_QUANTITY = `${PREFIX}CHANGE_SINGLE_QUANTITY`;
export const changeSingleQuantity = (quantity, variantIndex, sizeIndex) => ({
  type: CHANGE_SINGLE_QUANTITY,
  payload: {
    variantIndex: +variantIndex,
    quantity,
    sizeIndex
  }
});
export const CHANGE_SINGLE_VARIANT_QUANTITIES = `${PREFIX}CHANGE_SINGLE_VARIANT_QUANTITIES`;
export const changeSingleVariantQuantity = (quantity, variantIndex) => (dispatch, getState) => {
  const state = getState();
  const product = selectedProductSelector(state);
  const selectedSizeIndex = selectedSizeIndexSelector(state);
  const size = sizeSelector(state);
  const quantities = applyQuantityToVariant({
    threshold: 0,
    product: product === null || product === void 0 ? void 0 : product.swagProductId,
    quantity,
    selectedSizeIndex,
    size
  });
  dispatch({
    type: CHANGE_SINGLE_VARIANT_QUANTITIES,
    payload: {
      variantIndex: +variantIndex,
      quantities
    }
  });
};
export const SET_PRODUCT_LIST_AVAILABLE_SIZES_ACTION = `${PREFIX}SET_PRODUCT_LIST_AVAILABLE_SIZES_ACTION`;
export const setProductListAvailableSizesAction = sizeSettings => ({
  type: SET_PRODUCT_LIST_AVAILABLE_SIZES_ACTION,
  payload: sizeSettings
});