import { PERMISSION_LEVELS } from 'swag-client-common/constants/google-tag-manager';
import { emailSelector } from 'swag-client-common/redux/user/user.selectors';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { GiveawayTypes } from 'swag-common/interfaces/giveaway';
import { push } from 'react-router-redux';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { distributionsListSelector, rejectingDistributionIdSelector } from '../../../custom-store-admin/redux/approval-distributions/approval-distributions.selectors';
import { inventoryGroupPermissionLevelSelector, selectedInventorySelector } from '../../../custom-store-admin/redux/inventory/inventory.selectors';
// import { productsListSelector } from 'src/client/scripts/custom-store-admin/redux/products/products.selectors';
import { productsListSelector } from '../../../custom-store-admin/redux/products/products.selectors';
import { getGiveawayItemsForAnalytics, getPendingApprovalItemsForAnalytics } from '../../../custom-store-admin/utils/get-items-for-analytics';
const PREFIX = 'ANALYTICS_';
export const USER_CLICKED_ORDER_MORE_BUTTON = `${PREFIX}USER_CLICKED_ORDER_MORE_BUTTON`;
export const userClickedOrderMoreButton = (userId, email, inventoryId) => ({
  type: USER_CLICKED_ORDER_MORE_BUTTON,
  payload: {
    userId,
    email,
    inventoryId
  }
});
export const USER_CLICKED_SOA_BUTTON = `${PREFIX}USER_CLICKED_SOA_BUTTON`;
export const userClickedSoaButton = ({
  userId,
  email,
  inventoryId,
  btnLocation
}) => ({
  type: USER_CLICKED_SOA_BUTTON,
  payload: {
    userId,
    email,
    inventoryId,
    btnLocation
  }
});
export const USER_CLICKED_SMA_BUTTON = `${PREFIX}USER_CLICKED_SMA_BUTTON`;
export const userClickedSmaButton = ({
  userId,
  email,
  inventoryId,
  btnLocation
}) => ({
  type: USER_CLICKED_SMA_BUTTON,
  payload: {
    userId,
    email,
    inventoryId,
    btnLocation
  }
});
export const USER_CLICKED_GIVEAWAY_BUTTON = `${PREFIX}USER_CLICKED_GIVEAWAY_BUTTON`;
export const userClickedGiveawayButton = ({
  btnLocation,
  userId,
  email,
  inventoryId
}) => ({
  type: USER_CLICKED_GIVEAWAY_BUTTON,
  payload: {
    btnLocation,
    userId,
    email,
    inventoryId
  }
});
export const SUBMIT_GIVEAWAY_LINK = `${PREFIX}SUBMIT_GIVEAWAY_LINK`;
export const clickSubmitGiveawayAction = ({
  userId,
  email,
  inventoryId,
  giveawayId
}) => ({
  type: SUBMIT_GIVEAWAY_LINK,
  payload: {
    userId,
    email,
    inventoryId,
    giveawayId
  }
});
export const ON_GIVEAWAY_EDIT_CLICK = `${PREFIX}ON_GIVEAWAY_EDIT_CLICK`;
export const onGiveawayEditClick = ({
  _id,
  type
}) => (dispatch, getState) => {
  if (type === GiveawayTypes.goodie) {
    return;
  }
  const userId = userIdSelector(getState());
  const email = emailSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  return dispatch({
    type: ON_GIVEAWAY_EDIT_CLICK,
    payload: {
      giveawayId: _id,
      userId,
      email,
      inventoryId
    }
  });
};
export const ON_INVENTORY_SETTINGS_CLICK = `${PREFIX}ON_SETTINGS_CLICK`;
export const onSettingsClick = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  dispatch({
    type: ON_INVENTORY_SETTINGS_CLICK,
    payload: {
      userId,
      email,
      inventoryId
    }
  });
};
export const ON_PAYMENT_METHODS_CLICK = `${PREFIX}ON_PAYMENT_METHODS_CLICK`;
export const onPaymentMethodsClick = ({
  userId,
  email,
  inventoryId
}) => dispatch => {
  dispatch({
    type: ON_PAYMENT_METHODS_CLICK,
    payload: {
      userId,
      email,
      inventoryId
    }
  });
};
export const ADD_NEW_CARD_CLICK = `${PREFIX}ADD_NEW_CARD_CLICK`;
export const addNewCardClick = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  dispatch({
    type: ADD_NEW_CARD_CLICK,
    payload: {
      inventoryId,
      userId,
      email
    }
  });
};
export const ON_INVENTORY_SELECT_CLICK = `${PREFIX}ON_INVENTORY_SELECT_CLICK`;
export const inventorySelectClickAction = inventoryId => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  dispatch({
    type: ON_INVENTORY_SELECT_CLICK,
    payload: {
      userId,
      email,
      inventoryId
    }
  });
};
export const INVENTORY_STORAGE_FEE_CLICK = `${PREFIX}INVENTORY_STORAGE_FEE_CLICK`;
export const inventoryStorageFeeClickAction = () => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  const email = emailSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  dispatch({
    type: INVENTORY_STORAGE_FEE_CLICK,
    payload: {
      inventoryId,
      userId,
      email
    }
  });
  dispatch(push(InventoryUrl.getStorageFeePage(inventoryId)));
};
export const INVENTORY_CREDITS_CLICK = `${PREFIX}INVENTORY_CREDITS_CLICK`;
export const inventoryCreditsClickAction = creditsStatus => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  const email = emailSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  dispatch({
    type: INVENTORY_CREDITS_CLICK,
    payload: {
      userId,
      email,
      inventoryId,
      creditsStatus
    }
  });
};
export const INVENTORY_FAQ_TAB_CLICKED = `${PREFIX}INVENTORY_FAQ_TAB_CLICKED`;
export const faqTabClickedAction = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  return dispatch({
    type: INVENTORY_FAQ_TAB_CLICKED,
    payload: {
      userId,
      email,
      inventoryId
    }
  });
};
export const INVENTORY_SUB_TAB_CLICKED = `${PREFIX}INVENTORY_SUB_TAB_CLICKED`;
export const inventorySubTabClickedAction = activitySubTab => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  return dispatch({
    type: INVENTORY_SUB_TAB_CLICKED,
    payload: {
      userId,
      email,
      inventoryId,
      activitySubTab
    }
  });
};
export const GIVEAWAY_COMPLETED_ANALYTICS = `${PREFIX}_COMPLETED_ANALYTICS`;
export const giveawayCompleted = giveaway => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  const productsList = productsListSelector(state);
  const itemsIds = getGiveawayItemsForAnalytics(giveaway, productsList);
  dispatch({
    type: GIVEAWAY_COMPLETED_ANALYTICS,
    payload: {
      userId,
      email,
      inventoryId,
      items: itemsIds
    }
  });
};
export const UPDATE_INVENTORY_USERS_PERMISSIONS_REQUEST = `${PREFIX}UPDATE_INVENTORY_USERS_PERMISSIONS_REQUEST`;
export const updateInventoryPermissionActions = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  const newUsersPermission = inventoryGroupPermissionLevelSelector(state);
  const analyticsPermissionLevel = PERMISSION_LEVELS[newUsersPermission];
  dispatch({
    type: UPDATE_INVENTORY_USERS_PERMISSIONS_REQUEST,
    payload: {
      userId,
      email,
      inventoryId,
      permissionLevel: analyticsPermissionLevel
    }
  });
};
export const USER_CLICKED_INVENTORY_LINK = `${PREFIX}USER_CLICKED_INVENTORY_LINK`;
export const userClickedInventoryLinkAction = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  dispatch({
    type: USER_CLICKED_INVENTORY_LINK,
    payload: {
      userId,
      email
    }
  });
};
export const APPROVE_DISTRIBUTION_REQUEST = `${PREFIX}APPROVE_DISTRIBUTION_REQUEST`;
export const approveDistributionAnalyticsAction = data => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  const distributions = distributionsListSelector(state);
  const distribution = distributions.find(({
    _id
  }) => _id === data.id);
  const productsList = productsListSelector(state);
  const items = getPendingApprovalItemsForAnalytics(distribution, productsList);
  dispatch({
    type: APPROVE_DISTRIBUTION_REQUEST,
    payload: {
      userId,
      email,
      inventoryId,
      transactionId: data.id,
      items
    }
  });
};
export const REJECT_DISTRIBUTION_REQUEST = `${PREFIX}REJECT_DISTRIBUTION_REQUEST`;
export const rejectDistributionAnalyticsAction = () => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const email = emailSelector(state);
  const inventoryId = selectedInventorySelector(state);
  const rejectId = rejectingDistributionIdSelector(state);
  const distributions = distributionsListSelector(state);
  const distribution = distributions.find(({
    _id
  }) => _id === rejectId);
  const productsList = productsListSelector(state);
  const items = getPendingApprovalItemsForAnalytics(distribution, productsList);
  dispatch({
    type: REJECT_DISTRIBUTION_REQUEST,
    payload: {
      userId,
      email,
      inventoryId,
      transactionId: rejectId,
      items
    }
  });
};