import { createApi } from 'swag-client-common/libs/rapi';
import { getTenantOrdersUrl, proceedOrderUrl, proceedDutyOrderUrl, proceedAdminUrl } from './order-urls.api';
const baseUrl = '/api/v1/order';
export const OrderApi = createApi({
  getAccountOrders: {
    url: `${baseUrl}/account`,
    method: 'get'
  },
  getPreview: {
    url: `${baseUrl}/get-preview/:id/:item/:variant/:view`,
    method: 'get'
  },
  getCart: {
    url: `${baseUrl}/cart/:orderToken/:draftToken`,
    method: 'get'
  },
  getShipmentWithTaxes: {
    url: `${baseUrl}/shipment-with-taxes`,
    method: 'put'
  },
  clearCart: {
    url: `${baseUrl}/cart/:orderToken`,
    method: 'delete'
  },
  convertLogo: {
    url: `${baseUrl}/cart/convert-logo`,
    method: 'post'
  },
  changeColorLogo: {
    url: `${baseUrl}/cart/change-color-logo`,
    method: 'post'
  },
  removeBackground: {
    url: `${baseUrl}/cart/remove-logo-background`,
    method: 'post'
  },
  addItemToCart: {
    url: `${baseUrl}/cart/:orderToken/item`,
    method: 'post'
  },
  addItemToDraft: {
    url: `${baseUrl}/draft/:draftToken/item`,
    method: 'post'
  },
  getDraft: {
    url: `${baseUrl}/draft/:draftToken/:orderToken`,
    method: 'get'
  },
  updateCart: {
    url: `${baseUrl}/cart`,
    method: 'put'
  },
  editItemUpsellWidgetState: {
    url: `${baseUrl}/cart/:orderToken/upsell`,
    method: 'post'
  },
  uploadShippingCSV: {
    url: `${baseUrl}/upload-shipping-csv/:orderToken`,
    method: 'put'
  },
  proceed: {
    url: proceedOrderUrl,
    method: 'post'
  },
  proceedDutyOrder: {
    url: proceedDutyOrderUrl,
    method: 'post'
  },
  proceedDummyOrder: {
    url: `${baseUrl}/proceed-dummy-order`,
    method: 'post'
  },
  proceedAdmin: {
    url: proceedAdminUrl,
    method: 'post'
  },
  getRates: {
    url: `${baseUrl}/rates`,
    method: 'post'
  },
  applyPromocode: {
    url: `${baseUrl}/:orderToken/promocode`,
    method: 'put'
  },
  removePromocode: {
    url: `${baseUrl}/:orderToken/promocode`,
    method: 'delete'
  },
  applyKSFLow: {
    url: `${baseUrl}/apply-ks-flow`,
    method: 'put'
  },
  applyUserPlan: {
    url: `${baseUrl}/apply-user-plan`,
    method: 'put'
  },
  reviewMockup: {
    url: `${baseUrl}/review-mockup`,
    method: 'put'
  },
  uploadDesign: {
    url: `${baseUrl}/cart/upload-design`,
    method: 'post'
  },
  approveColors: {
    url: `${baseUrl}/approve-colors`,
    method: 'put'
  },
  recalculateOrder: {
    url: `${baseUrl}/recalculate-order`,
    method: 'post'
  },
  getByCompany: {
    url: `${baseUrl}/get-by-company`,
    method: 'get'
  },
  getPendingOrders: {
    url: `${baseUrl}/pending-company-orders`,
    method: 'get'
  },
  changePendingOrder: {
    url: `${baseUrl}/:id/change-pending-status`,
    method: 'post'
  },
  getTenantOrders: {
    url: getTenantOrdersUrl,
    method: 'get'
  },
  getFundingSource: {
    url: `${baseUrl}/funding-source`,
    method: 'get'
  },
  proceedSendosoDummyOrder: {
    url: `${baseUrl}/proceed-sendoso-dummy-order`,
    method: 'post'
  },
  proceedPostalDummyOrder: {
    url: `${baseUrl}/proceed-postal-dummy-order`,
    method: 'post'
  },
  proceedGiveawayNotecard: {
    url: `${baseUrl}/proceed-giveaway-notecard`,
    method: 'post'
  },
  setInventoryUpsellWidgetState: {
    url: `${baseUrl}/cart/inventory-upsell`,
    method: 'post'
  },
  getInventoryUpsellWidgetState: {
    url: `${baseUrl}/cart/inventory-upsell`,
    method: 'get'
  },
  proceedReplacement: {
    url: `${baseUrl}/proceed-replacement-order`,
    method: 'post'
  },
  proceedDynamicReplacement: {
    url: `${baseUrl}/proceed-dynamic-replacement-order`,
    method: 'post'
  },
  proceedSendosoReplacement: {
    url: `${baseUrl}/proceed-sendoso-replacement-order`,
    method: 'post'
  },
  proceedPostalReplacement: {
    url: `${baseUrl}/proceed-postal-replacement-order`,
    method: 'post'
  },
  commentPreBuiltLimitedCart: {
    url: `${baseUrl}/pre-built-cart/limited/comment`,
    method: 'post'
  },
  getPreBuildCarts: {
    url: `${baseUrl}/pre-build-carts`,
    method: 'get'
  },
  removePreBuildCart: {
    url: `${baseUrl}/pre-built-cart/:id`,
    method: 'delete'
  }
});