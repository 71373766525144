function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { ACH, CREDITS, CREDIT_CARD, PaymentScreen } from 'swag-common/constants/payment-methods';
import { browserHistory } from 'react-router';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { canUserManagePermissions } from 'swag-common/business-logic/inventory';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { shownPopupSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { updateInventoryPermissionActions } from 'swag-client-common/redux/analytics/analytics.actions';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { Price } from 'swag-client-common/utils/price';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { INVENTORY_PERMISSION_LEVELS, SendToMultipleAddressesStep, INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
import { demoInventoryIdSelector, demoInventoryTourIdSelector } from 'swag-client-common/redux/partials/tours/tours.selectors';
import { getChameleon } from 'swag-client-common/redux/partials/tours/tours.utils';
import { extendDemoInventoryWithRoles } from 'swag-common/utils/inventory/extend-demo-inventory-with-roles.util';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { DEMO_INVENTORY_REMINDER_POPUP, DEMO_INVENTORY_WARNING_POPUP, DISAPPEAR_DEMO_INVENTORY_WARNING_POPUP, GO_BACK_TO_OWN_INVENTORY_POPUP, INVENTORY_BUY_CREDITS_WITHOUT_ACH_POPUP, INVENTORY_WAREHOUSE_PAYMENT_POPUP, START_INVENTORY_SHOPPING_POPUP, TRANSFER_IMPOSSIBLE_POPUP, PFI_PAYMENT_POPUP, GIVEAWAY_BILLING_POPUP, GIVEAWAY_NOTECARD_PAY_POPUP } from 'swag-client-common/constants';
import { getUserInventoryIdBeforeDemo, removeIsForceLaunchedDemo, removeUserInventoryIdBeforeDemo, setUserInventoryIdBeforeDemo } from 'swag-client-common/utils/local-storage.util';
import { BUY_CREDITS_BY_ACH_SUCCESS_POPUP, BUY_CREDITS_BY_CARD_SUCCESS_POPUP, INVENTORY_WITHDRAW_CREDITS_SUCCESS_POPUP } from 'swag-client-common/constants';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { InventoryApi } from '../../api/inventory.api';
import { isLoggedInSelector } from '../../../customer/redux/user/user.selectors';
import { bundlesMapByQuantitySelector, nonEmptyBundleProductsQuantitiesSelector, nonEmptyQuantitiesSelector } from '../pull-from-inventory/pull-from-inventory.selectors';
import ShipOutApi from '../../api/shipout.api';
import { mergeCreateAsyncOptions } from '../redux.utils';
import { goToPaymentMethodsListAction, setDefaultTab } from '../inventory-settings/inventory-settigns.actions';
import { InventorySettingsTabs } from '../../utils/constants';
import { getApprovalSettings } from '../approval-settings/approval-settings.actions';
import { checkAccessRights } from '../../utils/enterprise-access-wrapper';
import { hideLoader, showLoader } from '../../../common/redux/common/common.actions';
import { preparePaymentToInventoryProceed, setInitialPublicAPIDefaultPayment } from '../payment-methods/payment-methods.actions';
import { inventoryPaymentBillingFormSelector, isManualPayment, paymentMethodIdSelector, publicAPIDefaultPaymentSelector } from '../payment-methods/payment-methods.selectors';
import { STRIPE_FORM_SUBMIT_ERROR, STRIPE_FORM_SUBMIT_SUCCESS, STRIPE_FORM_VALIDATION_RESET } from '../stripe-form/stripe-form.actions';
import { locationSelector } from '../common/common.selectors';
import { setActiveStep } from '../send-to-multiple-addresses/send-to-multiple-addresses.actions';
import { setBuyCreditsFromGiveawayFeesPageFlow } from '../giveaway/actions/giveaway-pay-fees-popup.actions';
import { isBuyCreditsFromGiveawayFeesPageSelector } from '../giveaway/selectors/giveaway-pay-fees-popup.selectors';
import { goToCreateSwagBundlesMainPage } from '../swag-bundles/actions/swag-bundles.actions';
import { creditsToBuySelector, creditsToWithdrawSelector, firstInventoryIdSelectorNotIn, inventoriesSelector, inventoryGroupPermissionLevelSelector, inventoryNewUsersSelector, inventoryUpdatedPermissionsSelector, isInventoriesExistsSelector, isSelectedDemoInventorySelector, selectedInventorySelector, isCreditsMoreToBuyFlowSelector, purchaseFlowSelector } from './inventory.selectors';
const PREFIX = 'STORE_INVENTORY/';
export const LIST_INVENTORIES_REQUEST = `${PREFIX}LIST_INVENTORIES_REQUEST`;
export const LIST_INVENTORIES_SUCCESS = `${PREFIX}LIST_INVENTORIES_SUCCESS`;
export const LIST_INVENTORIES_FAILURE = `${PREFIX}LIST_INVENTORIES_FAILURE`;
export const SET_IS_LOADED_INVENTORY_PAGE_DATA = `${PREFIX}SET_IS_LOADED_INVENTORY_PAGE_DATA`;
export const setIsLoadedPageData = isLoaded => ({
  type: SET_IS_LOADED_INVENTORY_PAGE_DATA,
  payload: isLoaded
});
export function inventoryLoadedAsyncParameters() {
  return {
    preFn: dispatch => {
      dispatch(setIsLoadedPageData(true));
    },
    postSuccess: dispatch => {
      dispatch(setIsLoadedPageData(false));
    },
    postError: dispatch => {
      dispatch(setIsLoadedPageData(false));
    }
  };
}
const inventoryLoadedAsyncParametersObject = inventoryLoadedAsyncParameters();
const listInventoriesOptions = {
  preFn: dispatch => {
    dispatch({
      type: LIST_INVENTORIES_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.list,
  success: data => ({
    type: LIST_INVENTORIES_SUCCESS,
    payload: data
  }),
  error: err => ({
    type: LIST_INVENTORIES_FAILURE,
    err,
    error: err
  }),
  postSuccess: (dispatch, getState, transformedData, response) => {
    const userId = userIdSelector(getState());
    const selectedInventoryId = selectedInventorySelector(getState());
    const {
      list,
      demoInventory
    } = response;
    const updatedDemoInventory = extendDemoInventoryWithRoles(demoInventory, list, userId);
    dispatch(setDemoInventory(demoInventory));
    const inventory = [...list, updatedDemoInventory].find(inv => inv._id === selectedInventoryId);
    const isAdmin = inventory && canUserManagePermissions(inventory, userId);
    if (!isAdmin) {
      const tab = InventorySettingsTabs.paymentMethods;
      dispatch(goToPaymentMethodsListAction());
      dispatch(setDefaultTab(tab));
    }
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
};
export const SET_DEMO_INVENTORY = `${PREFIX}SET_DEMO_INVENTORY`;
export const setDemoInventory = inventory => ({
  type: SET_DEMO_INVENTORY,
  payload: inventory
});
export const SET_UNFREEZE_CLICK = `${PREFIX}SET_UNFREEZE_CLICK`;
export const setUnfreezeClick = isClick => ({
  type: SET_UNFREEZE_CLICK,
  payload: isClick
});
export const listInventories = createAsyncAction(listInventoriesOptions);
export const listInventoriesEntering = createAsyncAction(mergeCreateAsyncOptions(listInventoriesOptions, inventoryLoadedAsyncParametersObject));
export const HIDE_PRODUCT_REQUEST = `${PREFIX}HIDE_PRODUCT_REQUEST`;
export const HIDE_PRODUCT_SUCCESS = `${PREFIX}HIDE_PRODUCT_SUCCESS`;
export const HIDE_PRODUCT_FAILURE = `${PREFIX}HIDE_PRODUCT_FAILURE`;
export const hideInventoryProductRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: HIDE_PRODUCT_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.removeProduct,
  success: products => ({
    type: HIDE_PRODUCT_SUCCESS,
    payload: products
  }),
  error: err => ({
    type: HIDE_PRODUCT_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const hideInventoryProduct = checkAccessRights(hideInventoryProductRequest, DemoInventoryWarningTypes.Products);
export const LIST_SHIPOUTS_REQUEST = `${PREFIX}LIST_SHIPOUTS_REQUEST`;
export const LIST_SHIPOUTS_SUCCESS = `${PREFIX}LIST_SHIPOUTS_SUCCESS`;
export const LIST_SHIPOUTS_FAILURE = `${PREFIX}LIST_SHIPOUTS_FAILURE`;
export const listShipouts = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: LIST_SHIPOUTS_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.listShipouts,
  success: shipouts => ({
    type: LIST_SHIPOUTS_SUCCESS,
    payload: shipouts
  }),
  error: err => ({
    type: LIST_SHIPOUTS_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const CREATE_INVENTORY_REQUEST = `${PREFIX}CREATE_INVENTORY_REQUEST`;
export const CREATE_INVENTORY_SUCCESS = `${PREFIX}CREATE_INVENTORY_SUCCESS`;
export const CREATE_INVENTORY_FAILURE = `${PREFIX}CREATE_INVENTORY_FAILURE`;
export const createInventory = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: CREATE_INVENTORY_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.create,
  transformData: name => ({
    name
  }),
  success: shipouts => ({
    type: CREATE_INVENTORY_SUCCESS,
    payload: shipouts
  }),
  error: err => ({
    type: CREATE_INVENTORY_FAILURE,
    err,
    error: err
  }),
  postSuccess: (...[dispatch,,, res]) => {
    dispatch(hideLoader());
    dispatch(listInventories());
    browserHistory.push(`/inventory/${res._id}/products`);
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const SELECT_INVENTORY = `${PREFIX}SELECT_INVENTORY`;
export const selectInventory = ({
  inventoryId,
  mode
}) => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  const inventories = inventoriesSelector(getState());
  const inventory = inventories.find(el => el._id === inventoryId);
  if (inventory) {
    const isAdmin = inventory && canUserManagePermissions(inventory, userId);
    const tab = isAdmin ? InventorySettingsTabs.approvalAndPermissions : InventorySettingsTabs.paymentMethods;
    dispatch(setDefaultTab(tab));
  }
  dispatch({
    type: SELECT_INVENTORY,
    payload: {
      inventoryId,
      mode
    }
  });
};
export const UPDATE_INVENTORY_REQUEST = `${PREFIX}UPDATE_INVENTORY_REQUEST`;
export const UPDATE_INVENTORY_SUCCESS = `${PREFIX}UPDATE_INVENTORY_SUCCESS`;
export const UPDATE_INVENTORY_FAILURE = `${PREFIX}UPDATE_INVENTORY_FAILURE`;
export const updateInventoryRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_INVENTORY_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.update,
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      inventoryId,
      inventory: _objectSpread({}, data)
    };
  },
  success: inventory => ({
    type: UPDATE_INVENTORY_SUCCESS,
    payload: inventory
  }),
  error: err => ({
    type: UPDATE_INVENTORY_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const updateInventoryRemovedProductsIdsAction = payload => ({
  type: UPDATE_INVENTORY_SUCCESS,
  payload
});
export const updateInventory = checkAccessRights(updateInventoryRequest, DemoInventoryWarningTypes.RenameInventory);
export const GET_INVENTORY_USERS_REQUEST = `${PREFIX}GET_INVENTORY_USERS_REQUEST`;
export const GET_INVENTORY_USERS_SUCCESS = `${PREFIX}GET_INVENTORY_USERS_SUCCESS`;
export const GET_INVENTORY_USERS_FAILURE = `${PREFIX}GET_INVENTORY_USERS_FAILURE`;
const getInventoryUsersParams = {
  preFn: dispatch => {
    dispatch({
      type: GET_INVENTORY_USERS_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.getUsers,
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      inventoryId
    };
  },
  success: inventory => ({
    type: GET_INVENTORY_USERS_SUCCESS,
    payload: inventory
  }),
  error: err => ({
    type: GET_INVENTORY_USERS_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
};
export const getInventoryUsers = createAsyncAction(getInventoryUsersParams);
export const getInventoryUsersEntering = createAsyncAction(mergeCreateAsyncOptions(getInventoryUsersParams, inventoryLoadedAsyncParametersObject));
export const CHANGE_GROUP_PERMISSION_LEVEL = `${PREFIX}CHANGE_GROUP_PERMISSION_LEVEL`;
export const changeGroupPermissionLevel = permissionLevel => ({
  type: CHANGE_GROUP_PERMISSION_LEVEL,
  payload: permissionLevel
});
export const ADD_NEW_USER_TO_INVENTORY = `${PREFIX}ADD_NEW_USER_TO_INVENTORY`;
export const addNewUserToInventory = email => ({
  type: ADD_NEW_USER_TO_INVENTORY,
  payload: email
});
export const REMOVE_NEW_USER_FROM_INVENTORY = `${PREFIX}REMOVE_NEW_USER_FROM_INVENTORY`;
export const removeNewUserFromInventory = email => ({
  type: REMOVE_NEW_USER_FROM_INVENTORY,
  payload: email
});
export const RESET_NEW_INVENTORY_USERS = `${PREFIX}RESET_NEW_INVENTORY_USERS`;
export const resetNewInventoryUsers = () => ({
  type: RESET_NEW_INVENTORY_USERS
});
export const CHANGE_CURRENT_USER_PERMISSION_LEVEL = `${PREFIX}CHANGE_CURRENT_USER_PERMISSION_LEVEL`;
export const changeCurrentUserPermissionLevel = (_id, permission, skipConfirmation = false) => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.RevokeAccess
    }));
  }
  if (permission === INVENTORY_PERMISSION_LEVELS.NO_ACCESS && !skipConfirmation) {
    return dispatch(showPopup({
      popup: 'confirmInventoryUserRemovalPopup',
      userId: _id
    }));
  }
  dispatch({
    type: CHANGE_CURRENT_USER_PERMISSION_LEVEL,
    payload: {
      _id,
      permission
    }
  });
  dispatch(updateInventoryUsersPermissions());
};
export const REMOVE_CURRENT_USER_FROM_UPDATED_PERMISSION_LIST = `${PREFIX}REMOVE_CURRENT_USER_FROM_UPDATED_PERMISSION_LIST`;
export const removeCurrentUserFromUpdatedPermissionList = _id => ({
  type: REMOVE_CURRENT_USER_FROM_UPDATED_PERMISSION_LIST,
  payload: _id
});
export const UPDATE_INVENTORY_USERS_PERMISSIONS_SUCCESS = `${PREFIX}UPDATE_INVENTORY_USERS_PERMISSIONS_SUCCESS`;
export const UPDATE_INVENTORY_USERS_PERMISSIONS_FAILURE = `${PREFIX}UPDATE_INVENTORY_USERS_PERMISSIONS_FAILURE`;
export const updateInventoryUsersPermissionsRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch(updateInventoryPermissionActions());
    dispatch(showLoader());
  },
  fn: InventoryApi.updatePermissions,
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const newUsers = inventoryNewUsersSelector(state);
    const newUsersPermission = inventoryGroupPermissionLevelSelector(state);
    const currentUsersPermissions = inventoryUpdatedPermissionsSelector(state);
    return {
      inventoryId,
      newUsers,
      newUsersPermission,
      currentUsersPermissions
    };
  },
  success: inventory => ({
    type: UPDATE_INVENTORY_USERS_PERMISSIONS_SUCCESS,
    payload: inventory
  }),
  error: err => ({
    type: UPDATE_INVENTORY_USERS_PERMISSIONS_FAILURE,
    err,
    error: err
  }),
  postSuccess: (dispatch, getState, transformedData) => {
    const {
      newUsers
    } = transformedData;
    dispatch(getApprovalSettings());
    dispatch(hideLoader());
    dispatch(showNotification({
      text: `${newUsers.length > 1 ? 'Users' : 'User'} permissions has been successfully updated`
    }));
  },
  postError: (dispatch, getState, err) => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const updateInventoryUsersPermissions = checkAccessRights(updateInventoryUsersPermissionsRequest, DemoInventoryWarningTypes.InviteToInventory);
export const REMOVE_CURRENT_USER_FROM_INVENTORY_REQUEST = `${PREFIX}REMOVE_CURRENT_USER_FROM_INVENTORY_REQUEST`;
export const REMOVE_CURRENT_USER_FROM_INVENTORY_SUCCESS = `${PREFIX}REMOVE_CURRENT_USER_FROM_INVENTORY_SUCCESS`;
export const REMOVE_CURRENT_USER_FROM_INVENTORY_FAILURE = `${PREFIX}REMOVE_CURRENT_USER_FROM_INVENTORY_FAILURE`;
export const removeCurrentUserFromInventory = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REMOVE_CURRENT_USER_FROM_INVENTORY_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.removeUser,
  transformData: (userId, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      userId,
      inventoryId
    };
  },
  success: inventory => ({
    type: REMOVE_CURRENT_USER_FROM_INVENTORY_SUCCESS,
    payload: inventory
  }),
  error: err => ({
    type: REMOVE_CURRENT_USER_FROM_INVENTORY_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'User has been successfully removed from inventory'
    }));
  },
  postError: (dispatch, getState, err) => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const REMOVE_UNREGISTERED_USER_INVITE_REQUEST = `${PREFIX}REMOVE_UNREGISTERED_USER_INVITE_REQUEST`;
export const REMOVE_UNREGISTERED_USER_INVITE_SUCCESS = `${PREFIX}REMOVE_UNREGISTERED_USER_INVITE_SUCCESS`;
export const REMOVE_UNREGISTERED_USER_INVITE_FAILURE = `${PREFIX}REMOVE_UNREGISTERED_USER_INVITE_FAILURE`;
export const removeUnregisteredUserInviteFromInventory = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REMOVE_UNREGISTERED_USER_INVITE_REQUEST
    });
    dispatch(showLoader());
  },
  fn: InventoryApi.removeInvite,
  transformData: inviteId => ({
    inviteId
  }),
  success: invite => ({
    type: REMOVE_UNREGISTERED_USER_INVITE_SUCCESS,
    payload: invite._id
  }),
  error: err => ({
    type: REMOVE_UNREGISTERED_USER_INVITE_FAILURE,
    err,
    error: err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'User has been successfully removed from inventory'
    }));
  },
  postError: (dispatch, getState, err) => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const TRANSFER_PRODUCTS_STARTED = `${PREFIX}TRANSFER_PRODUCTS_STARTED`;
export const TRANSFER_PRODUCTS_SUCCESS = `${PREFIX}TRANSFER_PRODUCTS_SUCCESS`;
export const TRANSFER_PRODUCTS_ERROR = `${PREFIX}TRANSFER_PRODUCTS_ERROR`;
export const transferProductsRequest = createAsyncAction({
  preFn: (dispatch, getState) => {
    const isLoggedIn = isLoggedInSelector(getState());
    if (!isLoggedIn) {
      return true;
    }
    dispatch({
      type: TRANSFER_PRODUCTS_STARTED
    });
  },
  transformData: (toInventoryId, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const bundlesMap = bundlesMapByQuantitySelector(state);
    const productsMap = nonEmptyQuantitiesSelector(state);
    const bundlesProductsMap = nonEmptyBundleProductsQuantitiesSelector(state);
    const data = {
      inventoryId,
      toInventoryId,
      fromInventoryId: inventoryId,
      productsMap,
      bundlesMap,
      bundlesProductsMap
    };
    return data;
  },
  fn: ShipOutApi.transfer,
  success: ({
    products
  }) => ({
    type: TRANSFER_PRODUCTS_SUCCESS,
    payload: products
  }),
  error: error => ({
    type: TRANSFER_PRODUCTS_ERROR,
    error
  }),
  postSuccess: (dispatch, getState, _, res) => {
    const text = res && res.isApprovalFlow ? 'Transfer request has been created and is awaiting approval.' : 'Product(s) has been successfully transferred';
    dispatch(hidePopup());
    dispatch(showNotification({
      text,
      dataAttr: res.transferId
    }));
  },
  postError: (dispatch, getState, err) => {
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const transferProducts = checkAccessRights(transferProductsRequest, DemoInventoryWarningTypes.Products);
export const prepareTransferProducts = transferTo => (dispatch, getState) => {
  const inventoriesList = inventoriesSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  const {
    warehouseLocation
  } = inventoriesList.find(inventory => inventory._id === transferTo) || {};
  const fromInventory = inventoriesList.find(inventory => inventory._id === inventoryId);
  if (warehouseLocation !== (fromInventory === null || fromInventory === void 0 ? void 0 : fromInventory.warehouseLocation)) {
    return dispatch(showPopup({
      popup: TRANSFER_IMPOSSIBLE_POPUP
    }));
  }
  dispatch(transferProducts(transferTo));
};
export const UPDATE_EXPORT_DATE = `${PREFIX}UPDATE_EXPORT_DATE`;
export const updateExportDate = (dateType, dateValue) => ({
  type: UPDATE_EXPORT_DATE,
  payload: {
    dateType,
    dateValue
  }
});
export const GET_LAST_INVENTORY_STARTED = `${PREFIX}GET_LAST_INVENTORY_STARTED`;
export const GET_LAST_INVENTORY_SUCCESS = `${PREFIX}GET_LAST_INVENTORY_SUCCESS`;
export const GET_LAST_INVENTORY_ERROR = `${PREFIX}GET_LAST_INVENTORY_ERROR`;
export const getLastInventoryId = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_LAST_INVENTORY_STARTED
  }),
  fn: InventoryApi.last,
  success: ({
    lastInventoryId
  }) => ({
    type: GET_LAST_INVENTORY_SUCCESS,
    payload: lastInventoryId
  }),
  error: error => ({
    type: GET_LAST_INVENTORY_ERROR,
    payload: error,
    error
  })
});
export const SET_ACTIVE_INVENTORY = `${PREFIX}SET_ACTIVE_INVENTORY`;
export const setActiveInventory = createAsyncAction({
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      inventoryId
    };
  },
  fn: InventoryApi.setActive,
  success: (_, transformedData) => ({
    type: SET_ACTIVE_INVENTORY,
    payload: transformedData.inventoryId
  }),
  error: error => ({
    type: 'ERROR',
    error
  })
});
export const DELETE_INVENTORY_SUCCESS = `${PREFIX}DELETE_INVENTORY_SUCCESS`;
export const DELETE_INVENTORY_ERROR = `${PREFIX}DELETE_INVENTORY_ERROR`;
export const deleteInventoryAction = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  fn: InventoryApi.deleteInventory,
  success: (data, categoryId) => ({
    type: DELETE_INVENTORY_SUCCESS,
    payload: categoryId
  }),
  error: error => ({
    type: DELETE_INVENTORY_ERROR,
    payload: error,
    error
  }),
  postSuccess: (dispatch, getState, {
    inventoryId
  }) => {
    dispatch(hideLoader());
    dispatch(hidePopup());
    dispatch(listInventories());
    dispatch(showNotification({
      text: 'Inventory was deleted'
    }));
    const firstId = firstInventoryIdSelectorNotIn(inventoryId)(getState());
    if (firstId) {
      browserHistory.push(`/inventory/${firstId}/products`);
    } else {
      browserHistory.push(APP_ROUTES.HOME);
      window.location.reload();
    }
  },
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const GET_INVENTORY_PENDING_ACTIVITIES_SUCCESS = `${PREFIX}PENDING_ACTIVITIES_SUCCESS`;
export const GET_INVENTORY_PENDING_ACTIVITIES_ERROR = `${PREFIX}PENDING_ACTIVITIES_ERROR`;
const getPendingActivitiesActionParams = {
  fn: InventoryApi.getPendingActivities,
  success: (pendingActivities, {
    inventoryId
  }) => ({
    type: GET_INVENTORY_PENDING_ACTIVITIES_SUCCESS,
    payload: {
      pendingActivities,
      inventoryId
    }
  }),
  error: () => ({
    type: GET_INVENTORY_PENDING_ACTIVITIES_ERROR
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
};
export const getPendingActivitiesAction = createAsyncAction(getPendingActivitiesActionParams);
export const getPendingActivitiesActionEntering = createAsyncAction(mergeCreateAsyncOptions(getPendingActivitiesActionParams, inventoryLoadedAsyncParametersObject));
export const CHANGE_HISTORY_TAB = `${PREFIX}CHANGE_HISTORY_TAB`;
export const changeHistoryTab = value => ({
  type: CHANGE_HISTORY_TAB,
  payload: value
});
export const GET_INVENTORY_HISTORY_STARTED = 'GET_INVENTORY_HISTORY_STARTED';
export const GET_INVENTORY_HISTORY_SUCCESS = 'GET_INVENTORY_HISTORY_SUCCESS';
export const GET_INVENTORY_HISTORY_ERROR = 'GET_INVENTORY_HISTORY_ERROR';
export const getInventoryHistoryAction = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_INVENTORY_HISTORY_STARTED
  }),
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState()) || '';
    return {
      inventoryId,
      page: data.page,
      limit: data.limit
    };
  },
  fn: InventoryApi.history,
  success: (response, incomeData) => ({
    type: GET_INVENTORY_HISTORY_SUCCESS,
    payload: {
      items: response,
      limit: incomeData.limit
    }
  }),
  error: error => ({
    type: GET_INVENTORY_HISTORY_ERROR,
    payload: error
  })
});
export const CLEAN_INVENTORY_HISTORY = `${PREFIX}CLEAN_INVENTORY_HISTORY`;
export const cleanInventoryHistoryAction = () => ({
  type: CLEAN_INVENTORY_HISTORY
});
export const openShareInventoryPermissionsPopup = () => dispatch => {
  dispatch(showPopup({
    popup: 'shareInventoryPermissionAccessPopup'
  }));
};
export const MARK_DEMO_INVENTORY_AS_SHOWN_REQUEST = 'MARK_DEMO_INVENTORY_AS_SHOWN_REQUEST';
export const MARK_DEMO_INVENTORY_AS_SHOWN_SUCCESS = 'MARK_DEMO_INVENTORY_AS_SHOWN_SUCCESS';
export const MARK_DEMO_INVENTORY_AS_SHOWN_FAILURE = 'MARK_DEMO_INVENTORY_AS_SHOWN_FAILURE';
export const markUserAsDemoInventoryTourShown = createAsyncAction({
  preFn: dispatch => dispatch({
    type: MARK_DEMO_INVENTORY_AS_SHOWN_REQUEST
  }),
  transformData: (data, getState) => {
    const state = getState();
    const userId = userIdSelector(state);
    return {
      userId
    };
  },
  fn: InventoryApi.markUserAsDemoShown,
  success: data => ({
    type: MARK_DEMO_INVENTORY_AS_SHOWN_SUCCESS,
    payload: data
  }),
  postSuccess: (dispatch, getState, transformedData, res, data) => {
    if (data.showTour) {
      const inventoryId = selectedInventorySelector(getState());
      const demoInventoryTourId = demoInventoryTourIdSelector();
      const chameleon = getChameleon();
      if (chameleon && demoInventoryTourId) {
        setUserInventoryIdBeforeDemo(inventoryId);
        chameleon.show(demoInventoryTourId);
      }
      dispatch(hidePopup());
    }
  },
  error: error => ({
    type: MARK_DEMO_INVENTORY_AS_SHOWN_FAILURE,
    payload: error,
    error
  })
});
export const demoTutorialCloseClick = () => (dispatch, getState) => {
  const state = getState();
  const inventories = inventoriesSelector(state);
  const demoInventoryId = demoInventoryIdSelector();
  const inventoriesWithoutDemo = inventories.filter(i => String(i._id) !== demoInventoryId);
  if (inventoriesWithoutDemo.every(i => i.isEmpty)) {
    return dispatch(showPopup({
      popup: START_INVENTORY_SHOPPING_POPUP
    }));
  }
  const userInventoryIdBeforeDemo = getUserInventoryIdBeforeDemo();
  dispatch(showPopup({
    popup: GO_BACK_TO_OWN_INVENTORY_POPUP,
    inventoryId: userInventoryIdBeforeDemo
  }));
  removeUserInventoryIdBeforeDemo();
};
export const startInventoryShoppingContinue = () => {
  const inventoryId = getUserInventoryIdBeforeDemo();
  const demoInventoryId = demoInventoryIdSelector();
  removeIsForceLaunchedDemo();
  removeUserInventoryIdBeforeDemo();
  if (inventoryId !== demoInventoryId) {
    return showPopup({
      popup: GO_BACK_TO_OWN_INVENTORY_POPUP,
      inventoryId
    });
  }
  return showPopup({
    popup: DISAPPEAR_DEMO_INVENTORY_WARNING_POPUP
  });
};
export const closeFirstDemoInventoryEnter = () => (dispatch, getState) => {
  const isInventoriesExists = isInventoriesExistsSelector(getState());
  dispatch(markUserAsDemoInventoryTourShown({
    showTour: false
  }));
  if (!isInventoriesExists) {
    return dispatch(showPopup({
      popup: DISAPPEAR_DEMO_INVENTORY_WARNING_POPUP
    }));
  }
  return dispatch(showPopup({
    popup: DEMO_INVENTORY_REMINDER_POPUP
  }));
};
export const FETCH_INVENTORY_CREDITS_STARTED = 'FETCH_INVENTORY_CREDITS_STARTED';
export const FETCH_INVENTORY_CREDITS_SUCCESS = 'FETCH_INVENTORY_CREDITS_SUCCESS';
export const FETCH_INVENTORY_CREDITS_FAILURE = 'FETCH_INVENTORY_CREDITS_FAILURE';
export const getInventoryCredits = createAsyncAction({
  fn: InventoryApi.credits.get,
  preFn: dispatch => {
    dispatch({
      type: FETCH_INVENTORY_CREDITS_STARTED
    });
  },
  success: data => ({
    type: FETCH_INVENTORY_CREDITS_SUCCESS,
    payload: data
  }),
  error: data => ({
    type: FETCH_INVENTORY_CREDITS_FAILURE,
    payload: data
  })
});
export const SET_INVENTORY_ACTIVITIES_SEARCH_QUERY = `${PREFIX}SET_INVENTORY_ACTIVITIES_SEARCH_QUERY`;
export const setInventoryActivitiesSearchQuery = value => ({
  type: SET_INVENTORY_ACTIVITIES_SEARCH_QUERY,
  payload: value
});
export const SET_INVENTORY_ACTIVITIES_SEARCH_PREV_RESULT = `${PREFIX}SET_INVENTORY_ACTIVITIES_SEARCH_PREV_RESULT`;
export const setInventoryActivitiesSearchPrevResult = () => ({
  type: SET_INVENTORY_ACTIVITIES_SEARCH_PREV_RESULT
});
export const SET_INVENTORY_ACTIVITIES_SEARCH_NEXT_RESULT = `${PREFIX}SET_INVENTORY_ACTIVITIES_SEARCH_NEXT_RESULT`;
export const setInventoryActivitiesSearchNextResult = () => ({
  type: SET_INVENTORY_ACTIVITIES_SEARCH_NEXT_RESULT
});
export const RESET_INVENTORY_ACTIVITIES_SEARCH_RESET_QUERY = `${PREFIX}RESET_INVENTORY_ACTIVITIES_SEARCH_RESET_QUERY`;
export const resetInventoryActivitiesSearchQuery = () => ({
  type: RESET_INVENTORY_ACTIVITIES_SEARCH_RESET_QUERY
});
export const SET_CREDITS_TO_BUY = `${PREFIX}SET_CREDITS_TO_BUY`;
export const onChangeCredits = value => ({
  type: SET_CREDITS_TO_BUY,
  payload: value
});
export const SET_CREDITS_TO_WITHDRAW = `${PREFIX}SET_CREDITS_TO_WITHDRAW`;
export const onChangeWithdraw = value => ({
  type: SET_CREDITS_TO_WITHDRAW,
  payload: value
});
export const resetStripeElementsValidation = () => ({
  type: STRIPE_FORM_VALIDATION_RESET
});
export const CHANGE_PAYMENT_METHOD = `${PREFIX}CHANGE_PAYMENT_METHOD`;
export const changePaymentMethod = paymentMethod => dispatch => {
  dispatch({
    type: CHANGE_PAYMENT_METHOD,
    payload: paymentMethod
  });
};
export const onChangePaymentMethod = paymentMethod => (dispatch, getState) => {
  const state = getState();
  const isBuyMoreCreditsFlow = isCreditsMoreToBuyFlowSelector(state);
  const shouldNotReset = paymentMethod === CREDITS && isBuyMoreCreditsFlow;
  if (!shouldNotReset) {
    dispatch(resetStripeElementsValidation());
  }
  dispatch(changePaymentMethod(paymentMethod));
};
export const BUY_CREDITS_SUCCESS = `${PREFIX}BUY_CREDITS_SUCCESS`;
const buyCreditsSuccess = paymentMethod => (dispatch, getState) => {
  const state = getState();
  const shownPopup = shownPopupSelector(state);
  const location = locationSelector(state);
  dispatch({
    type: BUY_CREDITS_SUCCESS
  });
  const isCreditsMoreToBuyFlow = isCreditsMoreToBuyFlowSelector(state);
  if (isCreditsMoreToBuyFlow) {
    dispatch(hidePopup());
    return dispatch(onCloseInventoryBuyCreditsByCardSuccessPopup());
  }
  dispatch({
    type: STRIPE_FORM_SUBMIT_SUCCESS
  });
  if (shownPopup === INVENTORY_BUY_CREDITS_WITHOUT_ACH_POPUP && location !== null && location !== void 0 && location.pathname.includes('/storage-fees')) {
    dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
    dispatch(showPopup({
      popup: INVENTORY_WAREHOUSE_PAYMENT_POPUP
    }));
    return;
  }
  if (paymentMethod === CREDIT_CARD) {
    return dispatch(showPopup({
      popup: BUY_CREDITS_BY_CARD_SUCCESS_POPUP
    }));
  }
  return dispatch(showPopup({
    popup: BUY_CREDITS_BY_ACH_SUCCESS_POPUP
  }));
};
export const SET_CREDITS_TO_BUY_MORE_FLOW = `${PREFIX}SET_CREDITS_MORE_TO_BUY_FLOW`;
export const setCreditsToBuyMoreFlow = ({
  isCreditsToBuyMoreFlow
}) => ({
  type: SET_CREDITS_TO_BUY_MORE_FLOW,
  payload: isCreditsToBuyMoreFlow
});
export const SET_CREDITS_MORE_TO_BUY = `${PREFIX}SET_CREDITS_MORE_TO_BUY`;
const setCreditsMoreToBuy = creditsMoreToBuy => ({
  type: SET_CREDITS_MORE_TO_BUY,
  payload: creditsMoreToBuy
});
export const resetCreditsMoreToBuyFlow = () => dispatch => {
  dispatch(setCreditsToBuyMoreFlow({
    isCreditsToBuyMoreFlow: false
  }));
  dispatch(setCreditsMoreToBuy(0));
};
export const SET_FORCE_SCREEN_TO_SHOW = `${PREFIX}SET_FORCE_SCREEN_TO_SHOW`;
export const setForceScreenToShow = screen => ({
  type: SET_FORCE_SCREEN_TO_SHOW,
  payload: {
    screen
  }
});
export const SET_INVENTORY_PURCHASE_FLOW = `${PREFIX}SET_INVENTORY_PURCHASE_FLOW`;
export const setInventoryPurchaseFlow = purchaseFlow => ({
  type: SET_INVENTORY_PURCHASE_FLOW,
  payload: purchaseFlow
});
export const onCloseInventoryBuyCreditsByCardSuccessPopup = () => (dispatch, getState) => {
  const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(getState());
  if (isCreditsToBuyMoreFlow) {
    const state = getState();
    const inventoryPurchaseFlow = purchaseFlowSelector(state);
    switch (true) {
      case inventoryPurchaseFlow === INVENTORY_PURCHASE_FLOWS.SOA:
        {
          dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
          dispatch(showPopup({
            popup: PFI_PAYMENT_POPUP
          }));
          dispatch(resetStripeElementsValidation());
          return;
        }
      case inventoryPurchaseFlow === INVENTORY_PURCHASE_FLOWS.SMA:
        {
          dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
          dispatch(setActiveStep(SendToMultipleAddressesStep.billing));
          dispatch(resetStripeElementsValidation());
          return;
        }
      case inventoryPurchaseFlow === INVENTORY_PURCHASE_FLOWS.GIVEAWAY:
        {
          dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
          dispatch(showPopup({
            popup: GIVEAWAY_BILLING_POPUP
          }));
          dispatch(resetStripeElementsValidation());
          return;
        }
      case inventoryPurchaseFlow === INVENTORY_PURCHASE_FLOWS.STORAGE_FEE:
        {
          dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
          dispatch(showPopup({
            popup: INVENTORY_WAREHOUSE_PAYMENT_POPUP
          }));
          dispatch(resetStripeElementsValidation());
          return;
        }
      case inventoryPurchaseFlow === INVENTORY_PURCHASE_FLOWS.GIVEAWAY_NOTECARD:
        {
          dispatch(setForceScreenToShow(PaymentScreen.payByCredits));
          dispatch(showPopup({
            popup: GIVEAWAY_NOTECARD_PAY_POPUP
          }));
          dispatch(resetStripeElementsValidation());
          return;
        }
      default:
        break;
    }
  }
  dispatch(hidePopup());
};
export const BUY_CREDITS_ERROR = `${PREFIX}BUY_CREDITS_ERROR`;
export const BUY_INVENTORY_CREDITS_SUCCESS = `${PREFIX}BUY_INVENTORY_CREDITS_SUCCESS`;
export const payForCreditsFactory = ({
  paymentMethod = CREDIT_CARD
}) => {
  const request = createAsyncLoadedAction({
    transformData: (data, getState) => {
      const state = getState();
      const inventoryId = selectedInventorySelector(state);
      const amount = creditsToBuySelector(state);
      const publicAPIDefaultPayment = publicAPIDefaultPaymentSelector(state);
      const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(getState());
      const commonBody = {
        inventoryId,
        paymentMethod,
        isCreditsToBuyMoreFlow,
        amount: Price.addDecimalToNumber(amount),
        publicAPIDefaultPayment: {
          paymentMethod: publicAPIDefaultPayment.value,
          card: publicAPIDefaultPayment.card
        }
      };
      if (paymentMethod === ACH) {
        return _objectSpread({}, commonBody);
      }
      const {
        stripeToken,
        savedCard
      } = data;
      if (savedCard) {
        const cardId = paymentMethodIdSelector(state);
        return _objectSpread({}, commonBody, {
          cardId
        });
      }
      const form = inventoryPaymentBillingFormSelector(state);
      if (form) {
        const {
          savePaymentDetails
        } = form.values;
        return _objectSpread({}, commonBody, {
          stripeToken,
          savePaymentDetails
        });
      }
      return _objectSpread({}, commonBody, {
        stripeToken
      });
    },
    fn: InventoryApi.credits.buy,
    success: credits => (dispatch, getState) => {
      const state = getState();
      dispatch({
        type: BUY_INVENTORY_CREDITS_SUCCESS,
        payload: credits
      });
      dispatch(buyCreditsSuccess(paymentMethod));
      const isBuyCreditsFromGiveawayFeesPage = isBuyCreditsFromGiveawayFeesPageSelector(state);
      if (isBuyCreditsFromGiveawayFeesPage) {
        dispatch(setBuyCreditsFromGiveawayFeesPageFlow(false));
      }
    },
    error: error => dispatch => {
      dispatch({
        type: STRIPE_FORM_SUBMIT_ERROR
      });
      dispatch({
        type: BUY_CREDITS_ERROR
      });
      dispatch(showNotification({
        text: (error === null || error === void 0 ? void 0 : error.message) || 'Oops, something went wrong'
      }));
    },
    postSuccess: (dispatch, getState, data) => {
      dispatch(setInitialPublicAPIDefaultPayment(data.publicAPIDefaultPayment.paymentMethod, data.publicAPIDefaultPayment.card));
    }
  });
  return checkAccessRights(request, DemoInventoryWarningTypes.BuyCredits);
};
export const payForCreditsByCard = payForCreditsFactory({
  paymentMethod: CREDIT_CARD
});
export const payForCreditsByInvoice = payForCreditsFactory({
  paymentMethod: ACH
});
export const onBuyCreditsByCard = (stripe, card) => (dispatch, getState) => {
  const isManualCard = isManualPayment(getState());
  if (isManualCard && card) {
    dispatch(preparePaymentToInventoryProceed(stripe, card, token => dispatch(payForCreditsByCard({
      stripeToken: token.id
    }))));
  } else {
    dispatch(payForCreditsByCard({
      savedCard: true
    }));
  }
};
export const onBuyCreditsByACH = () => dispatch => {
  dispatch(payForCreditsByInvoice());
};
export const WITHDRAW_INVENTORY_CREDITS_SUCCESS = `${PREFIX}WITHDRAW_INVENTORY_CREDITS_SUCCESS`;
export const onWithdrawCredits = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const amount = creditsToWithdrawSelector(state);
    return {
      amount: Price.addDecimalToNumber(amount),
      inventoryId
    };
  },
  fn: InventoryApi.credits.withdraw,
  success: credits => dispatch => {
    dispatch(showPopup({
      popup: INVENTORY_WITHDRAW_CREDITS_SUCCESS_POPUP
    }));
    dispatch({
      type: WITHDRAW_INVENTORY_CREDITS_SUCCESS,
      payload: credits
    });
  },
  postSuccess: dispatch => {
    dispatch(onChangeWithdraw(0));
  },
  error: error => dispatch => {
    dispatch(showNotification({
      text: (error === null || error === void 0 ? void 0 : error.message) || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  },
  postError: dispatch => {
    dispatch(onChangeWithdraw(0));
  }
});
export const handleCreditsToBuyMoreFlow = ({
  total,
  credits,
  isCreditsToBuyMoreFlow
}) => dispatch => {
  const creditsMoreToBuy = Math.ceil((total - credits) / 100);
  dispatch(setCreditsMoreToBuy(creditsMoreToBuy));
  dispatch(setCreditsToBuyMoreFlow({
    isCreditsToBuyMoreFlow
  }));
  dispatch(onChangeCredits(creditsMoreToBuy));
};
export const GET_INVENTORY_STATUS_SUCCESS = `${PREFIX}GET_INVENTORY_STATUS_SUCCESS`;
export const GET_INVENTORY_STATUS_FAILURE = `${PREFIX}GET_INVENTORY_STATUS_FAILURE`;
export const getInventoryStatus = createAsyncAction({
  fn: InventoryApi.getStatus,
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      inventoryId
    };
  },
  success: ({
    _id,
    status,
    frozenAt
  }) => ({
    type: GET_INVENTORY_STATUS_SUCCESS,
    payload: {
      _id,
      status,
      frozenAt
    }
  }),
  error: error => ({
    type: GET_INVENTORY_STATUS_FAILURE,
    payload: error,
    error
  })
});
export const createBundleButtonClicked = () => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.CreateBundle
    }));
  } else {
    dispatch(goToCreateSwagBundlesMainPage({
      productId: undefined,
      isSelectProductsSkipped: false
    }));
  }
};
export const createButtonFromProductCard = productId => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.CreateBundle
    }));
  } else {
    dispatch(goToCreateSwagBundlesMainPage({
      productId,
      isSelectProductsSkipped: true
    }));
  }
};
export const createBundleFromManagePanel = () => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.CreateBundle
    }));
  } else {
    dispatch(goToCreateSwagBundlesMainPage({
      productId: undefined,
      isSelectProductsSkipped: true
    }));
  }
};