import { shippingFieldsMap } from 'swag-common/interfaces';
export const formatHistoryText = (shippingMargin, adminEmail, isSpace = false) => {
  const fieldsLength = shippingMargin.length;
  const updatedFieldsText = shippingMargin.reduce((acc, {
    field,
    oldValue,
    newValue
  }, index) => {
    const fieldName = shippingFieldsMap[field];
    const punctuationMark = index < fieldsLength - 1 ? ';' : '.';
    return `${acc} \n - ${fieldName}: from "${+oldValue * 100}%" to "${+newValue * 100}%"${punctuationMark}`;
  }, '');
  return `${isSpace ? 'Space ' : ''}Shipping margin setting was changed by ${adminEmail}: ${updatedFieldsText}`;
};