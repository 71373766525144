import { calculateSetupFeeValue } from 'swag-common/business-logic';
import { DesignUploadMode } from 'swag-common/interfaces';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
export const getSetupFeeForBoxProduct = (item, cartItems, isItemSample) => {
  const {
    prodTime
  } = item;
  const products = cartItems.filter(cartItem => {
    var _cartItem$prod;
    return ((_cartItem$prod = cartItem.prod) === null || _cartItem$prod === void 0 ? void 0 : _cartItem$prod.designUploadMode) !== DesignUploadMode.box && cartItem.boxItemsId === item.boxItemsId;
  });
  if (!products || !products.length || isBox(item)) {
    return 0;
  }
  let setupFee = products.reduce((all, product) => {
    return all + calculateSetupFeeValue({
      product: product.prod,
      logos: product.logos,
      texts: null,
      isSample: isItemSample,
      prodTime,
      curatedBrandStoreProductId: product.curatedBrandStoreProductId
    });
  }, 0);
  if (!!item.setupFeeDeductionDiscount && !!setupFee) {
    setupFee -= item.setupFeeDeductionDiscount;
  }
  if (setupFee < 0) {
    setupFee = 0;
  }
  return setupFee;
};