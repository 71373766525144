import _flattenDeep from "lodash/flattenDeep";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-common/utils/price';
import { SPLIT_SHIPPED_ITEM_TYPE } from 'swag-common/constants/item-sources';
import { checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { getMaxSetupFee } from '../get-max-setup-fee.logic';
import calculateSetupFeeValue from '../calculate-setup-fee-value.logic';
import { DesignUploadMode } from '../../interfaces';
import { isBox } from '../../utils/order/item-is-box.util';
import { isInBox } from '../../utils/order/item-is-in-box.util';
import { sortBoxContentInSwagItems, sortIncludingCorrectBoxOrder } from './get-order-of-box-content-items.logic';
export const groupItemsAsRelatedAndDefault = (items, {
  forEmail = false,
  unwindBoxItems = true,
  deliverToFulfillmentCenter = false,
  skipInventory = false,
  newItemsFirst = false
} = {}) => {
  const fees = getMaxSetupFee(items);
  const nonBoxItems = items.filter(i => !i.boxItemsId && i.splitShippedType !== SPLIT_SHIPPED_ITEM_TYPE.toInventory);
  const boxOnlyItems = items.filter(i => i.boxItemsId);
  const splitShippedItems = items.filter(checkIsSplitShippedToInventory);
  const newNoneBoxItemsListObject = formatItemsList({
    items: nonBoxItems,
    deliverToFulfillmentCenter,
    skipInventory,
    forEmail,
    fees,
    unwindBoxItems
  });
  const newSplitShippedItems = formatItemsList({
    items: splitShippedItems,
    deliverToFulfillmentCenter,
    skipInventory,
    forEmail,
    fees,
    unwindBoxItems,
    areSplitShippedToInventoryItems: true
  });
  const newBoxItemsListObject = formatItemsList({
    items: boxOnlyItems,
    deliverToFulfillmentCenter,
    skipInventory,
    forEmail,
    fees,
    unwindBoxItems
  });
  if (newItemsFirst) {
    return {
      items: [...Object.values(newNoneBoxItemsListObject).reverse(), ...Object.values(newBoxItemsListObject).reverse(), ...Object.values(newSplitShippedItems).reverse()]
    };
  }
  return {
    items: [...Object.values(newNoneBoxItemsListObject), ...Object.values(newBoxItemsListObject), ...Object.values(newSplitShippedItems)]
  };
};
const updateRelatedItems = (items, initialSetupFee) => items.map(item => {
  const {
    setupFeeDeductionDiscount,
    blendedQuantity,
    quantity
  } = item;
  let setupFee = initialSetupFee;
  let oldSetupFee = 0;
  let price = item.price;
  if (!!setupFeeDeductionDiscount && !!setupFee) {
    oldSetupFee = setupFee;
    setupFee -= setupFeeDeductionDiscount;
    const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
      setupFeeDeductionDiscount,
      blendedQuantity,
      quantity
    });
    price -= deductionAmount;
  }
  const screenPrice = Price.getScreenPriceForRelatedProduct(setupFee, blendedQuantity || quantity);
  const pricePerItem = Price.getPricePerItem({
    assemblyFee: item.prod.assemblyFee,
    quantity: quantity,
    screenPrice,
    totalPriceForItems: price
  });
  return _objectSpread({}, item, {
    oldSetupFee,
    setupFee,
    setupFeeDeductionDiscount,
    price,
    pricePerItem
  });
});
export const getItemPricing = (item, featureFlags) => {
  var _item$prod;
  const {
    isSample,
    isPrintedSample,
    setupFeeDeductionDiscount,
    isReorder,
    prodTime,
    prod,
    logos,
    texts,
    blendedQuantity,
    quantity
  } = item;
  const {
    designUploadMode
  } = prod || {};
  const isSampleItem = isSample || isPrintedSample;
  const isLogoTypeProduct = designUploadMode === DesignUploadMode.logo && !isSampleItem;
  const multiplier = getItemPriceMultiplier(item);
  let oldSetupFee = 0;
  let setupFee = 0;
  let price = item.price;
  if (isLogoTypeProduct) {
    setupFee = calculateSetupFeeValue({
      product: prod,
      logos,
      texts,
      isSample: isSampleItem,
      prodTime,
      multiplier,
      featureFlags,
      curatedBrandStoreProductId: item.curatedBrandStoreProductId
    });
  }
  if (isReorder && !!setupFeeDeductionDiscount && !!setupFee) {
    oldSetupFee = setupFee;
    setupFee -= setupFeeDeductionDiscount;
    const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
      setupFeeDeductionDiscount,
      blendedQuantity,
      quantity
    });
    price -= deductionAmount;
  }
  if (setupFee < 0) {
    setupFee = 0;
  }
  const screenPrice = Price.getScreenPriceForRelatedProduct(setupFee, blendedQuantity || quantity);
  const pricePerItem = Price.getPricePerItem({
    assemblyFee: (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.assemblyFee,
    quantity: quantity,
    screenPrice,
    totalPriceForItems: price
  });
  return {
    setupFee,
    oldSetupFee,
    price,
    pricePerItem
  };
};
export function isInventoryItem(item) {
  return Boolean(item.inventoryId);
}
function isBoxSampleWithoutBox(item) {
  return item.isBoxSampleWithoutBox && item.products;
}
function getInventoryItems({
  items,
  item,
  updatedListItem,
  fees,
  areSplitShippedToInventoryItems = false
}) {
  const inventoryItems = updatedListItem;
  if (item.boxItemsId) {
    const boxItems = items.filter(i => i.boxItemsId === item.boxItemsId).map(recalculateItem());
    const boxesInInventory = inventoryItems.filter(i => Array.isArray(i) && i[0].boxItemsId === item.boxItemsId);
    const boxInInventoryExists = Boolean(boxesInInventory.length);
    if (!boxInInventoryExists) {
      inventoryItems.push(boxItems);
    }
  } else if (areSplitShippedToInventoryItems) {
    inventoryItems.push(items.map(item => {
      const {
        setupFee,
        oldSetupFee,
        price,
        pricePerItem
      } = getItemPricing(item);
      return _objectSpread({}, item, {
        oldSetupFee,
        setupFee,
        price,
        pricePerItem
      });
    }));
  } else if (item.asRelatedItemId) {
    const relatedItems = updateRelatedItems(items.filter(i => i.asRelatedItemId === item.asRelatedItemId), fees[item.asRelatedItemId]);
    const existsRelatedItemsArr = inventoryItems.find(arr => {
      var _arr$;
      return ((_arr$ = arr[0]) === null || _arr$ === void 0 ? void 0 : _arr$.asRelatedItemId) === item.asRelatedItemId;
    });
    const isPrevBox = isPrevArrBox(inventoryItems);
    const isPrevRelated = isPrevArrRelated(inventoryItems);
    const isInventoryItemsEmpty = !inventoryItems.length;
    if (relatedItems.length === 1 && inventoryItems.length > 0) {
      if (isPrevBox || isPrevRelated || isInventoryItemsEmpty) {
        inventoryItems.push(...relatedItems);
      } else {
        const isArray = Array.isArray(inventoryItems[inventoryItems.length - 1]);
        if (isArray) {
          inventoryItems[inventoryItems.length - 1].push(...relatedItems);
        } else {
          inventoryItems[inventoryItems.length - 1] = [inventoryItems[inventoryItems.length - 1], ...relatedItems];
        }
      }
    } else if (!existsRelatedItemsArr) {
      if (isPrevBox || isPrevRelated || isInventoryItemsEmpty) {
        inventoryItems.push(relatedItems);
      } else {
        inventoryItems.push(relatedItems);
      }
    }
  } else {
    const {
      setupFee,
      oldSetupFee,
      price,
      pricePerItem
    } = getItemPricing(item);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isPrevArrBox(inventoryItems) || isPrevArrRelated(inventoryItems) || !inventoryItems.length ? inventoryItems.push([_objectSpread({}, item, {
      oldSetupFee,
      setupFee,
      price,
      pricePerItem
    })]) : inventoryItems[inventoryItems.length - 1].push(_objectSpread({}, item, {
      oldSetupFee,
      setupFee,
      price,
      pricePerItem
    }));
  }
  return inventoryItems;
}
export function getRelatedItems({
  items,
  item
}) {
  if (item.asRelatedItemId) {
    return items.filter(i => i.asRelatedItemId === item.asRelatedItemId);
  }
  return [];
}
function getBoxItems({
  items,
  item
}) {
  const sortedItems = items.filter(i => i.boxItemsId && i.boxItemsId === item.boxItemsId).map(recalculateItem()).sort(i => !isBox(i));
  return sortBoxContentInSwagItems(sortedItems);
}
function recalculateItem() {
  return item => _objectSpread({}, item, getItemPricing(item));
}
const isPrevArrBox = inventoryItems => {
  var _lastItem$;
  const lastItem = inventoryItems[inventoryItems.length - 1];
  return lastItem && ((_lastItem$ = lastItem[0]) === null || _lastItem$ === void 0 ? void 0 : _lastItem$.boxItemsId);
};
const isPrevArrRelated = inventoryItems => {
  var _lastItem$2, _lastItem, _lastItem$3, _lastItem2;
  const lastItem = inventoryItems[inventoryItems.length - 1];
  return lastItem && ((_lastItem$2 = lastItem[0]) === null || _lastItem$2 === void 0 ? void 0 : _lastItem$2.asRelatedItemId) && ((_lastItem = lastItem[lastItem.length - 1]) === null || _lastItem === void 0 ? void 0 : _lastItem.asRelatedItemId) && ((_lastItem$3 = lastItem[0]) === null || _lastItem$3 === void 0 ? void 0 : _lastItem$3.asRelatedItemId) === ((_lastItem2 = lastItem[lastItem.length - 1]) === null || _lastItem2 === void 0 ? void 0 : _lastItem2.asRelatedItemId) && lastItem.length > 1;
};
const getItemByKey = (updatedItemsList, key) => {
  const keys = Object.keys(updatedItemsList);
  return keys.find(i_k => i_k.includes(key));
};
const isItemAlreadyInUpdatedItemsList = (updatedItemsList, item) => {
  return (_flattenDeep(Object.values(updatedItemsList)) || []).find(
  // @todo. auto generated. fix this error
  // @ts-ignore
  i => i._id === item._id);
};
const shouldFormatInventoryItems = ({
  item,
  deliverToFulfillmentCenter,
  skipInventory
}) => {
  return (isInventoryItem(item) || checkIsSplitShippedToInventory(item) || deliverToFulfillmentCenter) && !skipInventory;
};
const formatItemsList = ({
  items,
  deliverToFulfillmentCenter,
  skipInventory,
  forEmail,
  fees,
  unwindBoxItems,
  areSplitShippedToInventoryItems = false
}) => {
  const itemsReduced = items.reduce((updatedItemsList, item, index) => {
    if (isItemAlreadyInUpdatedItemsList(updatedItemsList, item)) {
      return updatedItemsList;
    }
    const relatedItems = getRelatedItems({
      items,
      item
    });
    const isRelatedItemsExist = item.asRelatedItemId && relatedItems.length > 1;
    const shouldFormat = shouldFormatInventoryItems({
      item,
      deliverToFulfillmentCenter,
      skipInventory
    });
    if (shouldFormat) {
      const index_key = getItemByKey(updatedItemsList, String(item.inventoryId));
      const inventoryItems = getInventoryItems({
        items: sortIncludingCorrectBoxOrder(items),
        item,
        updatedListItem: index_key && updatedItemsList[index_key] || [],
        fees,
        areSplitShippedToInventoryItems
      });
      return index_key ? _objectSpread({}, updatedItemsList, {
        [index_key]: inventoryItems
      }) : _objectSpread({}, updatedItemsList, {
        [`${index}_${String(item.inventoryId)}`]: inventoryItems
      });
    }
    if (item.boxItemsId) {
      const index_key = getItemByKey(updatedItemsList, item.boxItemsId);
      if (!index_key) {
        const boxItems = items.filter(i => i.boxItemsId === item.boxItemsId).map(recalculateItem());
        return _objectSpread({}, updatedItemsList, {
          [`${index}_${item.boxItemsId}`]: sortBoxContentInSwagItems(boxItems)
        });
      }
      return updatedItemsList;
    }
    if (isRelatedItemsExist) {
      const index_key = getItemByKey(updatedItemsList, String(item.asRelatedItemId));
      if (!index_key) {
        const updatedRelatedItems = updateRelatedItems(relatedItems, fees[item.asRelatedItemId]);
        return _objectSpread({}, updatedItemsList, {
          [`${index}_${item.asRelatedItemId}`]: updatedRelatedItems
        });
      }
      return updatedItemsList;
    }
    if (forEmail) {
      if (isInBox(item)) {
        return updatedItemsList;
      }
      if (isBox(item)) {
        return _objectSpread({}, updatedItemsList, {
          [`${index}_${item.boxItemsId}`]: getBoxItems({
            items,
            item
          })
        });
      }
    }
    if (unwindBoxItems && isBoxSampleWithoutBox(item)) {
      var _item$products;
      const list = _objectSpread({}, updatedItemsList);
      (_item$products = item.products) === null || _item$products === void 0 ? void 0 : _item$products.forEach((itemProduct, i) => {
        list[`${index}-${i}_${String(itemProduct._id)}`] = itemProduct;
      });
      return list;
    }
    return _objectSpread({}, updatedItemsList, {
      [`${index}_${String(item._id)}`]: [_objectSpread({}, item, getItemPricing(item))]
    });
  }, {});
  return itemsReduced;
};