import _reducer from "redux-form/es/reducer";
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import { featureTogglesReducer } from 'swag-client-common/redux/feature-toggles/feature-toggles.reducer';
import { IS_TEST, IS_STAGING, IS_PRODUCTION } from 'swag-common/config/constants';
import popupManager from 'swag-client-common/redux/popup-manager/popup-manager.reducer';
import notification from 'swag-client-common/redux/notification/notification.reducer';
import snackbar from 'swag-client-common/redux/snackbar/snackbar.reducer';
import { blogReducer } from 'swag-client-common/redux/blog/blog.reducer';
import favouriteProducts from 'swag-client-common/redux/favourite-products/favourite-products.reducer';
import { settingsReducer as settings } from 'swag-client-common/redux/settings/settings.reducer';
import { intercomMiddleware } from 'swag-client-common/redux/middleware/intercom.middleware';
import { autocompleteReducer } from 'swag-client-common/redux/partials/autocomplete/autocomplete.reducer';
import { authUnauthorizedHandler } from 'swag-client-common/redux/middleware/auth-unauthorized-handler.middleware';
import { getIsDynamicBundle } from 'swag-client-common/utils/reseller/get-is-dynamic-bundle.util';
import { gaTrackingMiddleware } from '../middleware/ga-tracking.middleware';
import { googleTagManagerMiddleware } from '../../common/redux/middleware/google-tag-manager.middleware';
import instantQuoteMiddleware from '../redux/instant-quote/instant-quote.middleware';
import itemPantonesMiddleware from '../redux/item-pantones/item-pantones.middleware';
import postponeActions from '../middleware/postpone-actions.middleware';
import productMiddleware from '../redux/product/product.midleware';
import searchMiddleware from '../middleware/search.middleware';
import referralRockMiddleware from '../middleware/referreral-rock-middleware';
import filterUrlSync from '../middleware/filter-url-sync.middleware';
import checkoutMiddleware from '../middleware/checkout.middleware';
import partnerstackMiddleware from '../middleware/partnerstack.middleware';
import popupAutoShowMiddleware from '../middleware/popup-auto-show.middleware';
import { csvDownloadMiddleware } from '../middleware/csv-download.middleware';
import { replaceDispatcher } from '../prefetch-api/dispatcher-accumulator';
import { setIncludesSearchableOnlyMiddleware } from '../middleware/set-includes-searchable-only.middleware';
import { tenantStoreReducer } from '../../common/redux/tenant-store/tenant-store.reducer';
import common from './common/common.reducer';
import { resellerHomePageReducer } from './reseller-home-page/reseller-home-page.reducer';
import user from './user/user.reducer';
import instantQuote from './instant-quote/instant-quote.reducer';
import loginForm from './login-form/login-form.reducer';
import { signupFormReducer as signupForm } from './signup-form/signup-form.reducer';
import { productListingReducer } from './product-listing/product-listing.reducer';
import { productReducer } from './product/product.reducer';
import relatedProduct from './related-product-builder-popup/related-product.reducer';
import { cartReducer as cart } from './cart/cart.reducer';
import checkout from './checkout/checkout.reducer';
import sendEmail from './send-email/send-email.reducer';
import { contactReducer } from './contact/contact.reducer';
import billingAddress from './billing-address/billing-address.reducer';
import stripeForm from './stripe-form/stripe-form.reducer';
import enterpriseForm from './enterprise-form/enterprise-form.reducer';
import customSwag from './custom-swag/custom-swag.reducer';
import customStore from './custom-store/custom-store.reducer';
import authorizePopup from './authorize-popup/authorize-popup.reducer';
import accountVerification from './account-verification/account-verification.reducer';
import { accountOrdersReducer } from './account-orders/account-orders.reducer';
import profilePasswordForm from './profile-password-form/profile-password-form.reducer';
import setPassword from './set-password/set-password.reducer';
import { apiSettingsReducer } from './api-settings/api-setttings.reducer';
import kickstarter from './kickstarter/kickstarter.reducer';
import kickstarterShipping from './kickstarter-shipping/kickstarter-shipping.reducer';
import mockupManager from './mockup-manager/mockup-manager.reducer';
import logoEditing from './logo-editing/logo-editing.reducer';
import showMockups from './show-mockups/show-mockups.reducer';
import searchProducts from './search-products/search-products.reducer';
import shareDesign from './share-design/share-design.reducer';
import accountMockups from './account-mockups/account-mockups.reducer';
import logo from './logo/logo.reducer';
import itemPantones from './item-pantones/item-pantones.reducer';
import pages from './pages/pages.reducer';
import companyOrders from './company-orders/company-orders.reducer';
import searchResults from './search-results/search-results.reducer';
import navigation from './navigation/navigation.reducer';
import gamification from './gamification/gamification.reducer';
import { landingReducer } from './landings/landing.reducer';
import companyDepartments from './company-departments/company-departments.reducer';
import pendingOrders from './pending-orders/pending-orders.reducer';
import inventory from './inventory/inventory.reducer';
import { invitationReducer } from './invitation/invitation.reducer';
import pastWork from './past-work/past-works.reducers';
import { distributionFeeEstimationReducer } from './distribution-fee-estimation/distribution-fee-estimation.reducer';
import prefetch from './prefetch/prefetch.reducer';
import supervisorDashboard from './supervisor-dashboard/supervisor-dashboard.reducer';
import boxBuilder from './box-builder/box-builder.reducer';
import { collectSizesReducer } from './collect-sizes/collect-sizes.reducer';
import { boxNotecardEditorReducer } from './box-notecard-editor/box-notecard-editor.reducer';
import { notecardEditorReducer } from './notecard-editor/notecard-editor.reducer';
import { companyArtFolderReducer } from './company-art-folder/company-art-folder.reducer';
import { routingReducer } from './routing/routing.reducer';
import { debossedPopupReducer } from './debossed-popup/debosses-popup.reducer';
import { resellerInvoiceReducer } from './reseller-invoice/reseller-invoice.reducer';
import { curatedBrandStoreReducer } from './curated-brand-store/curated-brand-store.reducer';
const reducers = combineReducers({
  routing: routingReducer,
  common,
  user,
  loginForm,
  signupForm,
  productListing: productListingReducer,
  product: productReducer,
  relatedProduct,
  cart,
  checkout,
  ambassadorRequest: sendEmail,
  contact: contactReducer,
  stripeForm,
  billingAddress,
  enterpriseForm,
  customSwag,
  customStore,
  authorizePopup,
  accountVerification,
  accountOrders: accountOrdersReducer,
  profilePasswordForm,
  setPassword,
  kickstarter,
  popupManager,
  kickstarterShipping,
  mockupManager,
  showMockups,
  logoEditing,
  instantQuote,
  shareDesign,
  form: _reducer,
  notification,
  searchProducts,
  accountMockups,
  logo,
  itemPantones,
  pages,
  companyOrders,
  searchResults,
  navigation,
  landing: landingReducer,
  blog: blogReducer,
  gamification,
  companyDepartments,
  pendingOrders,
  inventory,
  invitation: invitationReducer,
  favouriteProducts,
  snackbar,
  pastWork,
  distributionFeeEstimation: distributionFeeEstimationReducer,
  prefetch,
  // @todo. auto generated. fix this error
  // @ts-ignore
  supervisorDashboard,
  boxBuilder,
  apiSettings: apiSettingsReducer,
  autocomplete: autocompleteReducer,
  settings,
  collectSizes: collectSizesReducer,
  boxNotecardEditor: boxNotecardEditorReducer,
  notecardEditor: notecardEditorReducer,
  companyArtFolder: companyArtFolderReducer,
  debossedPopup: debossedPopupReducer,
  tenantStore: tenantStoreReducer,
  featureToggles: featureTogglesReducer,
  resellerInvoice: resellerInvoiceReducer,
  resellerHomePage: resellerHomePageReducer,
  curatedBrandStore: curatedBrandStoreReducer
});
const middleware = [thunk, postponeActions, routerMiddleware(browserHistory), instantQuoteMiddleware, itemPantonesMiddleware, productMiddleware, searchMiddleware, filterUrlSync, referralRockMiddleware, checkoutMiddleware, csvDownloadMiddleware, setIncludesSearchableOnlyMiddleware, partnerstackMiddleware, popupAutoShowMiddleware, authUnauthorizedHandler, intercomMiddleware];
const isDynamicBundle = getIsDynamicBundle();
if ((IS_TEST || IS_STAGING) && !isDynamicBundle) {
  middleware.push(gaTrackingMiddleware, googleTagManagerMiddleware);
}
if (IS_PRODUCTION && !isDynamicBundle) {
  middleware.push(gaTrackingMiddleware, googleTagManagerMiddleware);
}
const composeEnhancers = !IS_PRODUCTION && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const customerStore = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)));
for (const action of window.swag.prefetchActions) {
  customerStore.dispatch(action);
}
replaceDispatcher(customerStore.dispatch);