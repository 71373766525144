import _change from "swag-client-common/redux-form-actions/change";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAutocompleteActions } from 'swag-client-common/redux/partials/autocomplete/autocomplete.action';
import { setAddressAction } from 'swag-client-common/redux/partials/shipping-form/shipping-form.actions';
import { DeliveryKeys } from 'swag-client-common/constants/delivery';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { hideLoader, showLoader } from 'swag-client-common/redux/common/common.actions';
import { formValuesSelector } from '../pull-from-inventory/pull-from-inventory.selectors';
import { PULL_FROM_INVENTORY_REDUX_FORM } from '../redux.constants';
const changeField = (field, value) => {
  return _change(PULL_FROM_INVENTORY_REDUX_FORM, field, value);
};
export const setSelectedAddress = (address = {
  state: 'AL',
  country: COUNTRY_CODES.US,
  city: '',
  zip: '',
  unit: ''
}) => dispatch => {
  const fields = [DeliveryKeys.State, DeliveryKeys.City, DeliveryKeys.Zip, DeliveryKeys.Country, DeliveryKeys.Address, DeliveryKeys.Unit];
  fields.forEach(field => {
    dispatch(changeField(field, address[field]));
  });
};
const {
  getPlaceById,
  getAddressByPlace,
  getAddresses,
  setManualAddressEnter
} = createAutocompleteActions({
  showAction: showLoader,
  hideAction: hideLoader,
  setAddressAction,
  getByIdPostAction: setSelectedAddress
});
export const getAddressesAction = () => (dispatch, getState) => {
  const values = formValuesSelector(getState());
  if (!values.address) {
    return true;
  }
  dispatch(getAddresses({
    country: values.country,
    place: values.address
  }));
};
export const getAddressByPlaceAction = params => (dispatch, getState) => {
  const values = formValuesSelector(getState());
  dispatch(getAddressByPlace(_objectSpread({}, params, {
    country: values.country
  })));
};
export const getPlaceByIdAction = getPlaceById;
export const setManualAddressEnterAction = setManualAddressEnter;