import { ChameleonToursLaunch } from 'swag-common/constants/chameleon-tours';
const LS = localStorage;
import { RECENTLY_VIEWED, RECENTLY_VIEWED_THRESHOLD } from 'swag-common/constants/user-recently-viewed.constants';
const parse = data => JSON.parse(data);
const stringify = data => JSON.stringify(data);
export const getOrderMoreBoxItemsValidityMap = () => {
  const orderMoreBoxSlicesJSON = LS.getItem('orderMoreBoxSlices');
  return orderMoreBoxSlicesJSON ? parse(orderMoreBoxSlicesJSON) : {};
};
export const setOrderMoreBoxItemsValidityMap = map => {
  return LS.setItem('orderMoreBoxSlices', stringify(map));
};
export const removeOrderMoreBoxItemsValidityMap = () => {
  return LS.removeItem('orderMoreBoxSlices');
};
export const setIsOrderTokenShouldBeRemoved = () => {
  return LS.setItem('isOrderTokenShouldBeRemoved', stringify(true));
};
export const getIsOrderTokenShouldBeRemoved = () => {
  const isOrderTokenShouldBeRemovedJSON = LS.getItem('isOrderTokenShouldBeRemoved');
  return isOrderTokenShouldBeRemovedJSON && parse(isOrderTokenShouldBeRemovedJSON);
};
export const getSwagUser = () => {
  const swagUserJSON = LS.getItem('swagUser');
  return swagUserJSON ? parse(swagUserJSON) : {};
};
export const removeOrderToken = () => LS.removeItem('orderToken');
export const setOrderToken = token => LS.setItem('orderToken', token);
export const getOrderToken = () => LS.getItem('orderToken');
export const removeOriginalUserRole = () => LS.removeItem('originalUserRole');
export const getRecentlyViewedFromLS = () => {
  var _LS$getItem;
  return (_LS$getItem = LS.getItem(RECENTLY_VIEWED)) !== null && _LS$getItem !== void 0 ? _LS$getItem : '[]';
};
export const setDraftToken = token => LS.setItem('draftToken', token);
export const getDraftToken = () => LS.getItem('draftToken');
export const removeDraftToken = () => LS.removeItem('draftToken');
export const removeIsOrderTokenShouldBeRemoved = () => {
  return LS.removeItem('isOrderTokenShouldBeRemoved');
};
export const setIsForceLaunchedDemo = () => {
  return LS.setItem(ChameleonToursLaunch.Force, true.toString());
};
export const getIsForceLaunchedDemo = () => {
  const forceJSON = LS.getItem(ChameleonToursLaunch.Force);
  return forceJSON ? parse(forceJSON) : '';
};
export const setUserInventoryIdBeforeDemo = userInventoryIdBeforeDemo => {
  return LS.setItem('userInventoryIdBeforeDemo', stringify(userInventoryIdBeforeDemo));
};
export const getUserInventoryIdBeforeDemo = () => {
  const userInventoryIdBeforeDemoJSON = LS.getItem('userInventoryIdBeforeDemo');
  return userInventoryIdBeforeDemoJSON && parse(userInventoryIdBeforeDemoJSON);
};
export const removeUserInventoryIdBeforeDemo = () => {
  return LS.removeItem('userInventoryIdBeforeDemo');
};
export const removeIsForceLaunchedDemo = () => {
  LS.removeItem(ChameleonToursLaunch.Force);
};
export const getShouldSkipNonVectorPopupAppearing = () => {
  const valueJSON = LS.getItem('shouldSkipNonVectorPopupAppearing');
  return valueJSON ? !!parse(valueJSON) : false;
};
export const addProductToRecentlyViewed = productId => {
  createStorageIfNecessary();
  const recentlyViewedJSON = LS.getItem(RECENTLY_VIEWED);
  const recentlyViewed = recentlyViewedJSON ? parse(recentlyViewedJSON) : [];
  const updatedRecentlyViewed = recentlyViewed.filter(rec => rec.productId !== productId);
  updatedRecentlyViewed.unshift({
    productId,
    viewedAt: Date.now()
  });
  if (updatedRecentlyViewed.length > RECENTLY_VIEWED_THRESHOLD) {
    updatedRecentlyViewed.pop();
  }
  LS.setItem(RECENTLY_VIEWED, stringify(updatedRecentlyViewed));
};
export const getGuestRecentlyViewed = () => {
  createStorageIfNecessary();
  const recentlyViewedJSON = LS.getItem(RECENTLY_VIEWED);
  return recentlyViewedJSON ? parse(recentlyViewedJSON) : [];
};
export const bulkRemoveProductFromRecentlyViewed = productsIds => {
  createStorageIfNecessary();
  const recentlyViewedJSON = LS.getItem(RECENTLY_VIEWED);
  const recentlyViewed = recentlyViewedJSON ? parse(recentlyViewedJSON) : [];
  LS.setItem(RECENTLY_VIEWED, stringify(recentlyViewed.filter(rec => !productsIds.includes(rec.productId))));
};
const createStorageIfNecessary = () => {
  const recentlyViewedJSON = LS.getItem(RECENTLY_VIEWED);
  if (!recentlyViewedJSON) {
    LS.setItem(RECENTLY_VIEWED, stringify([]));
  }
};
export const setHotProductsToken = token => token ? LS.setItem('hotProductsToken', token) : LS.removeItem('hotProductsToken');
export const getHotProductsToken = () => LS.getItem('hotProductsToken');
export const getSkipShipoutCancelConfirmationPopup = () => LS.getItem('skipShipoutCancelConfirmationPopup');
export const setGoodieGiveawaySelectedProducts = (list, link) => {
  const key = `goodieGiveawaySelection:${link}`;
  return list ? LS.setItem(key, stringify(list)) : LS.removeItem(key);
};
export const getGoodieGiveawaySelectedProducts = link => {
  try {
    const item = LS.getItem(`goodieGiveawaySelection:${link}`);
    const list = item ? parse(item) : {};
    if (!list) {
      return {};
    }
    return list;
  } catch (e) {
    return {};
  }
};
export const setProductColorToLS = color => {
  LS.setItem('productColor', color);
};
export const getAndRemoveItemFromLS = item => {
  const parsedItem = parse(LS.getItem(item) || '');
  LS.removeItem(item);
  return parsedItem;
};
export const getSkipCreateGiveawayLeaveAlert = () => {
  const valueJSON = LS.getItem('skipCreateGiveawayLeaveAlert');
  return valueJSON ? !!parse(valueJSON) : false;
};
export const setPreviousServiceCode = (itemId, serviceCode) => {
  LS.setItem(`shipping-${itemId}`, serviceCode);
};
export const removePreviousServiceCodes = items => {
  items.forEach(({
    _id
  }) => {
    LS.removeItem(`shipping-${_id}`);
  });
};
export const removeSingleItemPreviousServiceCode = itemId => {
  LS.removeItem(`shipping-${itemId}`);
};
export const getPreviousServiceCode = itemId => {
  LS.getItem(`shipping-${itemId}`);
};
export const getSkipCreateSwagBundleLeaveAlert = () => {
  const valueJSON = LS.getItem('skipCreateBundleLeaveAlert');
  return valueJSON ? !!parse(valueJSON) : false;
};
export const setSkipCreateSwagBundleLeaveAlert = value => {
  return LS.setItem('skipCreateBundleLeaveAlert', stringify(value));
};
export const getShipoutShutdownNoticeShownPopup = () => {
  const valueJSON = LS.getItem('showShipoutShutdownNoticePopup');
  return valueJSON ? !!parse(valueJSON) : false;
};
export const setShipoutShutdownNoticeShownPopup = value => {
  return LS.setItem('showShipoutShutdownNoticePopup', stringify(value));
};
export const getDistributionUnavailableNoticeShownPopup = () => {
  const valueJSON = LS.getItem('distributionUnavailableNoticeShownPopup');
  return valueJSON ? !!parse(valueJSON) : false;
};
export const setDistributionUnavailableNoticeShownPopup = value => {
  return LS.setItem('distributionUnavailableNoticeShownPopup', stringify(value));
};