import _isEmpty2 from "lodash/isEmpty";
import _isEqual2 from "lodash/isEqual";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dateFormat from 'dateformat';
import { createSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import * as utils from 'swag-common/utils/order';
import { isItemRejected, isStatedItem } from 'swag-common/utils/order';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { getTaxes, getGrandTotal, getInHandDateFormatted, isCancelledItem } from 'swag-common/utils/order';
import { isDeliverToFulfillmentCenter } from 'swag-common/utils/order/is-deliver-to-fulfillment-center.util';
import * as ITEM_STATUSES from 'swag-common/constants/item-statuses.constants';
import { isAllItemsCancelled } from 'swag-common/utils/order';
import { calculateSetupFeeValue, getMaxSetupFee, getReceivingFeeForItemsList } from 'swag-common/business-logic';
import checkIsItemAssociated from 'swag-common/utils/order/check-is-item-assosiated.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isItemSampleBox } from 'swag-common/utils/order/is-item-sample-box.util';
import { isSharedOrRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { DesignUploadMode } from 'swag-common/interfaces';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { getOrderInvoiceLink } from 'swag-common/services/links/get-download-link-from-s3.service';
import { isOrderItemRejected } from 'swag-common/business-logic/order/is-order-item-rejected.logic';
import { getItemShippingCost, getDataForGettingItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { IS_TENANT_INVENTORY_ENABLED } from 'swag-client-common/constants/tenant-name';
import { groupInitialProductsWithOrderBoxItems } from 'swag-common/business-logic/order/group-initial-products-with-order-box-items.logic';
import { checkIsWholeProceededBoxSuperSpeed } from 'swag-common/utils/items/check-is-whole-box-super-speed.util';
import { SWAG_IN_A_BOX } from 'swag-common/constants/box-titles';
import { getFormattedProductNameFromItem, getModifiedProductNameFromItem } from 'swag-common/business-logic/inventory-product-name/get-modified-item-name-and-original.logic';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { getRealSetupFee } from 'swag-common/utils/items/get-real-setup-fee';
import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import { DUMMY_PAYMENT } from 'swag-common/constants/payment-methods';
import { checkIsSplitShippedToCustomer, checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { isDynamicBundleSelector } from 'swag-client-common/redux/tenant-store/tenant-store.selectors';
import { getInventoryNameById } from 'swag-common/utils/inventory/get-inventory-name-by-id.util';
import { getHasResellerOrderNetOrACHPaymentMethod } from 'swag-common/utils/reseller/get-has-reseller-order-net-or-ach';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { STATE_LOADING } from '../redux.constants';
import { inventoriesSelector } from '../inventory/inventory.selectors';
import { isLoggedInSelector, isOrderApprovalFlowSelector } from '../user/user.selectors';
import { itemsToReviewPantoneColorsSelector } from './account-orders-other.selectors';
import { getApprovedProductionMockup, getColumnSpan, getListOfSampleDeductionDiscounts, getRelatedItems, preparePaginationData } from './account-orders.utils';
export const samplePromoItemsInCartSelector = state => state.accountOrders.samplePromoItemsInCart;
export const ordersToDisplaySelector = state => state.accountOrders.ordersToDisplay;
export const allOrdersSelector = state => state.accountOrders.orders;
export const hasNoOrdersSelector = state => state.accountOrders.hasNoOrders;
export const exportDateSelector = state => state.accountOrders.exportDate;
export const loadingStateSelector = state => state.accountOrders.state === STATE_LOADING;
export const singleOrderSelector = (state, props) => {
  const {
    orderId
  } = props;
  return state.accountOrders.orders.find(o => o._id === orderId);
};
export const paymentMethodSelector = createSelector(singleOrderSelector, order => order.paymentMethod);
export const isFinalPaymentReceivedSelector = createSelector(singleOrderSelector, order => order.isPaymentReceived);
export const singleOrderNumberSelector = createSelector(singleOrderSelector, order => order.num);
export const singleOrderShippingAddressSelector = createSelector(singleOrderSelector, ({
  fnameShipping,
  lnameShipping,
  email,
  unit,
  address,
  country,
  state,
  city,
  zip
}) => ({
  name: `${fnameShipping} ${lnameShipping}`,
  email,
  address: `${address}, ${unit ? `${unit},` : ''} ${country}, ${state}, ${city}, ${zip}`
}));
export const singleOrderTotalSelector = createSelector(singleOrderSelector, order => getGrandTotal(order) || Price.getPriceOfOrder(order));
export const isAllOrderItemsRejectedSelector = createSelector(singleOrderSelector, order => (order.items || []).every(isOrderItemRejected));
export const isAdjustmentPriceTooltipShownSelector = createSelector(singleOrderSelector, order => (order.items || []).some(item => item.isDummyOrder && item.numWithoutPrefix !== order.numWithoutPrefix));
export const singleOrderMeasurementSelector = createSelector(singleOrderSelector, order => order.measurement);
export const singleOrderKsFlowSelector = createSelector(singleOrderSelector, order => order.ksFlow);
export const singleOrderKsFulfillmentSelector = createSelector(singleOrderSelector, order => order.ksFulfillment);
export const singleOrderKsTrackingsSelector = createSelector(singleOrderSelector, order => order.ksTrackings);
export const singleOrderItemsSelector = createSelector(singleOrderSelector, order => utils.groupItemsIntoBoxes(order.items));
export const singleOrderItemsNumberSelector = createSelector(singleOrderItemsSelector, items => items.length);
export const singleOrderInvoiceLinkSelector = createSelector(singleOrderSelector, getOrderInvoiceLink);
export const chargeIdSelector = createSelector(singleOrderSelector, order => order.charge && order.charge.id);
export const singleOrderIsSwagOrderSelector = createSelector(singleOrderSelector, order => order.tenant === PREDEFINED_TENANTS.SWAG);
export const singleOrderItemsIdsSelector = createSelector(singleOrderItemsSelector, items => {
  const relatedItems = items.reduce((acc, item) => {
    if (item.asRelatedItemId && !item.boxItemsId) {
      return _objectSpread({}, acc, {
        [item.asRelatedItemId]: (acc[item.asRelatedItemId] || []).concat(item)
      });
    }
    return acc;
  }, {});
  const itemIds = [];
  const itemIdsBoxesOnly = [];
  items.forEach(item => {
    if (relatedItems[item.asRelatedItemId]) {
      const relatedIds = relatedItems[item.asRelatedItemId].map(i => i._id);
      const isIdsExistAlready = itemIds.find(element => _isEqual2(element, relatedIds));
      if (!isIdsExistAlready) {
        itemIds.push(relatedIds);
      }
    } else {
      if (item._id) {
        if (item.boxItemsId) {
          itemIdsBoxesOnly.push([item._id]);
        } else {
          itemIds.push([item._id]);
        }
      }
    }
  });
  return [...itemIds, ...itemIdsBoxesOnly];
});
export const singleOrderTaxesPriceSelector = createSelector(singleOrderSelector, getTaxes);
export const singleOrderPromocodeDiscountPriceSelector = createSelector(singleOrderSelector, order => {
  var _order$paymentPrices$, _order$paymentPrices;
  return (_order$paymentPrices$ = order === null || order === void 0 ? void 0 : (_order$paymentPrices = order.paymentPrices) === null || _order$paymentPrices === void 0 ? void 0 : _order$paymentPrices.discount) !== null && _order$paymentPrices$ !== void 0 ? _order$paymentPrices$ : 0;
});
export const singleOrderConfirmedAtPriceSelector = createSelector(singleOrderSelector, order => order.confirmedAt);
export const singleOrdersFulfillmentPerItemSelector = createSelector(singleOrderItemsSelector, singleOrderKsFulfillmentSelector, (items, ksFulfillment) => {
  const ksItemsNumber = items.reduce((number, item) => item.ksFlow ? number + 1 : number, 0);
  return ksFulfillment / ksItemsNumber;
});
export const singleOrderDeductionDiscountsSelector = createSelector(singleOrderSelector, ({
  items
}) => getListOfSampleDeductionDiscounts(items));
export const singleItemInOrderSelector = (state, props) => {
  const items = singleOrderItemsSelector(state, props);
  const {
    itemId
  } = props;
  let item = items.find(i => i._id === itemId);
  if (!item) {
    const order = singleOrderSelector(state, props);
    item = order.items.find(i => i._id === itemId);
  }
  return item;
};
export const singleItemLogosSelector = createSelector(singleItemInOrderSelector, item => item.logos);
export const itemProdSelector = createSelector(singleItemInOrderSelector, item => item.prod);
export const itemProductsSelector = createSelector(singleItemInOrderSelector, item => item.products);
export const isItemSampleSelector = createSelector(singleItemInOrderSelector, item => isItemBlankSampleOrPrintedSample(item));
export const boxSampleLabelSelector = createSelector(singleItemInOrderSelector, item => {
  if (item.isSample) {
    return 'Blank sample';
  }
  if (item.isPrintedSample) {
    return 'Printed sample';
  }
});
export const itemStatusSelector = createSelector(singleItemInOrderSelector, item => item.status);
export const itemFullDesignSelector = createSelector(singleItemInOrderSelector, ({
  prod,
  variantInd
}) => {
  if (!prod || !prod.designUploadMode) {
    return false;
  }
  if (prod.designUploadMode === DesignUploadMode.box) {
    return variantInd === 1;
  }
  return isProductFullDesign(prod);
});
export const isLimitedEditionSelector = createSelector(singleItemInOrderSelector, ({
  prod
}) => {
  var _prod$limitedEdition;
  return Boolean(prod === null || prod === void 0 ? void 0 : (_prod$limitedEdition = prod.limitedEdition) === null || _prod$limitedEdition === void 0 ? void 0 : _prod$limitedEdition.isLimitedEdition);
});
export const itemSampleSelector = createSelector(singleItemInOrderSelector, ({
  isSample
}) => isSample);
export const itemNameSelector = createSelector(singleItemInOrderSelector, getFormattedProductNameFromItem);
export const modifiedProductNameSelector = createSelector(singleItemInOrderSelector, getModifiedProductNameFromItem);
export const itemQuantitySelector = createSelector(singleItemInOrderSelector, item => item.quantity);
export const itemBlendedQuantitySelector = createSelector(singleItemInOrderSelector, item => item.blendedQuantity);
export const itemVariantsSelector = createSelector(singleItemInOrderSelector, item => item.variants);
export const singleOrderShipmentDiscountSelector = createSelector(singleOrderSelector, order => order.shipmentDiscount);
export const singleOrderSetupFeeDiscountSelector = createSelector(singleOrderSelector, order => order.setupFeeDiscount);
export const singleItemSetupFeeSelector = createSelector(singleItemInOrderSelector, featureTogglesSelector, (item, featureFlags) => {
  let setupFee = 0;
  const {
    isSample,
    isPrintedSample,
    prodTime,
    prod,
    logos,
    curatedBrandStoreProductId
  } = item;
  const multiplier = getItemPriceMultiplier(item);
  const isSampleItem = isSample || isPrintedSample;
  if (isSampleItem) {
    return setupFee;
  }
  const isBoxTypeProduct = prod.designUploadMode === DesignUploadMode.box;
  if (isBoxTypeProduct) {
    return setupFee;
  }
  const isLogoTypeProduct = prod.designUploadMode === DesignUploadMode.logo;
  if (isLogoTypeProduct) {
    setupFee = calculateSetupFeeValue({
      product: prod,
      logos,
      texts: null,
      isSample: isSample || isPrintedSample,
      prodTime,
      multiplier,
      featureFlags,
      curatedBrandStoreProductId
    });
  }
  if (item.setupFeeDeductionDiscount) {
    const delta = setupFee - item.setupFeeDeductionDiscount;
    setupFee = delta < 0 ? 0 : delta;
  }
  return setupFee;
});
export const singleItemsIsRelatedSelector = createSelector(singleOrderItemsSelector, singleItemInOrderSelector, checkIsItemAssociated);
export const singleItemsIsSharedSelector = createSelector(singleOrderSelector, singleItemInOrderSelector, (order, item) => isSharedItem(item, order.items));
export const getBoxItemPriceSelector = createSelector(singleItemInOrderSelector, item => {
  if (isItemBlankSampleOrPrintedSample(item)) {
    return item.price;
  }
  const multiplier = getItemPriceMultiplier(item);
  return Price.getBoxPrice({
    orderItem: item,
    product: item.prod,
    isBoxItemsPriceAdded: false,
    multiplier
  });
});
export const boxAssemblyFeeSelector = createSelector(singleItemInOrderSelector, item => {
  return item.prod.assemblyFee;
});
export const getIsBoxSampleWithoutBox = createSelector(singleItemInOrderSelector, item => item.isBoxSampleWithoutBox);
export const isItemBoxProductTypeSelector = createSelector(itemProdSelector, prod => prod.designUploadMode === DesignUploadMode.box);
export const boxLabelSelector = createSelector(itemProdSelector, isItemBoxProductTypeSelector, (product, isBox) => {
  switch (true) {
    case isBox:
      return SWAG_IN_A_BOX;
    default:
      return '';
  }
});
export const maxPrintColorsSelector = createSelector(itemProdSelector, isItemBoxProductTypeSelector, (prod, isBoxTypeProduct) => {
  if (isBoxTypeProduct) {
    return Math.min.apply(null, prod.products.map(p => p.maxPrintColors).filter(color => !!color));
  }
  return prod.maxPrintColors;
});
export const isDeliverToFulfillmentCenterSelector = createSelector(singleOrderSelector, isDeliverToFulfillmentCenter);
export const itemShipmentPriceSelector = createSelector(singleItemInOrderSelector, singleOrderSelector, (item, order) => {
  return getItemShippingCost(getDataForGettingItemShippingCost(item, order.items));
});
export const itemPriceSelector = createSelector(singleItemInOrderSelector, singleOrderItemsSelector, isItemBoxProductTypeSelector, getBoxItemPriceSelector, (item, items, isBoxTypeProduct, boxPrice) => {
  const {
    setupFeeDeductionDiscount = 0,
    price,
    blendedQuantity,
    quantity
  } = item;
  if (isBoxTypeProduct) {
    return boxPrice;
  }
  const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
    setupFeeDeductionDiscount,
    blendedQuantity,
    quantity
  });
  return price - deductionAmount;
});
export const itemAssemblyFeeSelector = createSelector(singleItemInOrderSelector, isItemBoxProductTypeSelector, getBoxItemPriceSelector, (item, isBoxTypeProduct) => {
  const {
    prod: {
      assemblyFee
    }
  } = item;
  return isBoxTypeProduct ? assemblyFee : null;
});
export const itemBoxPriceSelector = createSelector(singleItemInOrderSelector, itemShipmentPriceSelector, (item, shipment) => {
  const {
    setupFeeDeductionDiscount = 0,
    products
  } = item;
  const allItems = [item, ...(products || [])];
  let itemsToCalculateFrom;
  if (allItems.every(item => item.status <= ITEM_STATUSES.CANCELLED)) {
    itemsToCalculateFrom = allItems;
  } else {
    itemsToCalculateFrom = allItems.filter(isStatedItem);
  }
  const price = itemsToCalculateFrom.map(p => p.price).reduce((all, price) => all + price, 0);
  return price - setupFeeDeductionDiscount + shipment;
});
export const itemSizeIndSelector = createSelector(singleItemInOrderSelector, item => item.sizeInd);
export const itemKsFlowSelector = createSelector(singleItemInOrderSelector, item => item.ksFlow);
export const itemCustomerMockupsSelector = createSelector(singleItemInOrderSelector, item => item.customerMockups);
export const itemProductionMockupsSelector = createSelector(singleItemInOrderSelector, item => item.productionMockups);
export const itemReadyForPromoSelector = createSelector(singleItemInOrderSelector, item => !!item.readyForPromo);
export const itemDeliveryDateSelector = createSelector(singleItemInOrderSelector, item => item.actualTimeline[ITEM_STATUSES.DELIVERED]);
export const itemIsStrictInHandDateSelector = createSelector(singleItemInOrderSelector, item => item.isStrictInHandDate);
const checkIsStatusDelivered = status => {
  return status === ITEM_STATUSES.DELIVERED || status === ITEM_STATUSES.DELIVERED_TO_WAREHOUSE || status === ITEM_STATUSES.FINAL_PAYMENT;
};
export const itemInHandDateSelector = createSelector(singleItemInOrderSelector, itemDeliveryDateSelector, itemIsStrictInHandDateSelector, (item, deliveryDate, isStrictInHandDate) => {
  const {
    expectedByCustomerDates,
    status
  } = item;
  const date = expectedByCustomerDates.inHandDate;
  if (isCancelledItem(item) || !date) {
    return null;
  }
  const formattedDate = getInHandDateFormatted({
    inHandDate: date,
    isStrictInHandDate
  });
  if (checkIsStatusDelivered(status)) {
    return dateFormat(deliveryDate, 'dd mmm');
  } else {
    return formattedDate;
  }
});
export const inHandDateLabelSelector = createSelector(singleItemInOrderSelector, item => {
  const {
    status,
    inventoryId
  } = item;
  const inHandDateText = inventoryId ? 'Available for distribution:' : 'Est. in-hand:';
  if (checkIsStatusDelivered(status)) {
    return 'Delivery date:';
  } else {
    return inHandDateText;
  }
});
export const samplePromoItemPriceSelector = createSelector(singleOrderMeasurementSelector, singleOrderPromocodeDiscountPriceSelector, singleOrderItemsNumberSelector, singleItemInOrderSelector, (measurement, promocodeDiscount, itemsCount, item) => {
  const items = [item];
  if (isItemSampleBox(item)) {
    return getItemShippingCost(getDataForGettingItemShippingCost(item));
  }
  if (item.products && item.products.length) {
    items.push(...item.products);
  }
  return items.reduce((all, next) => {
    const {
      price,
      quantity
    } = next;
    const discountValue = Price.applyDiscount(price / quantity, promocodeDiscount, PromocodeMeasurementEnum.ABSOLUTE);
    return all + discountValue;
  }, 0);
});
export const promoItemWasReorderedSelector = createSelector(singleItemInOrderSelector, samplePromoItemsInCartSelector, ({
  _id
}, promoItemsInCart) => !!promoItemsInCart[_id]);
export const itemImageSelector = createSelector(itemVariantsSelector, itemProdSelector, itemCustomerMockupsSelector, itemSizeIndSelector, (variants, prod, customerMockups, sizeInd) => {
  const variantInd = variants && variants[0].variantIndex;
  const imageData = getOrderItemImage({
    product: prod,
    customerMockups,
    variantInd,
    sizeInd
  });
  return imageData && imageData.url;
});
export const itemApprovedProductionMockupSelector = createSelector(itemProductionMockupsSelector, productionMockups => getApprovedProductionMockup(productionMockups));
export const singleOrderReplacementDiscountSelector = createSelector(singleOrderSelector, order => Price.getReplacementDiscount(order.items));
export const discountValueToShowSelector = createSelector(singleOrderPromocodeDiscountPriceSelector, singleOrderReplacementDiscountSelector, (promocodeDiscount, replacementDiscount) => promocodeDiscount + replacementDiscount);
export const typeOrderSelector = () => 'account';
const itemsByIdsSelector = (state, props) => {
  const {
    relatedItems
  } = props;
  const items = singleOrderItemsSelector(state, props);
  return items.filter(item => relatedItems.find(id => id === item._id));
};
export const shipmentItemsTotalSelector = createSelector(itemsByIdsSelector, items => {
  let relatedItems;
  if (isAllItemsCancelled(items)) {
    relatedItems = items;
  } else {
    relatedItems = items.filter(isStatedItem);
  }
  return relatedItems.reduce((shipmentTotal, item) => getItemShippingCost(getDataForGettingItemShippingCost(item, items)) + shipmentTotal, 0);
});
export const isOrderForCustomStoreSelector = createSelector(singleOrderSelector, order => !!order.deliverToFulfillmentCenter);
export const isOrderSplitShippedSelector = createSelector(singleOrderSelector, order => !!order.isSplitShipped);
export const isOrderPendingApprovalSelector = createSelector(singleOrderItemsSelector, items => items.find(item => item.status === ITEM_STATUSES.PENDING_APPROVAL));
export const getRelatedItemsIdsSelector = (state, props) => props.relatedItems;
export const getRelatedItemsSelector = createSelector(singleOrderSelector, getRelatedItemsIdsSelector, ({
  items
}, relateIds) => items.filter(({
  _id
}) => relateIds.includes(_id)));
export const footerEmptyColumnsNumberSelector = createSelector(isDeliverToFulfillmentCenterSelector, isToFulfillmentCenter => getColumnSpan(5, isToFulfillmentCenter));
export const isAllRelatedItemsCancelledSelector = createSelector(getRelatedItemsSelector, isAllItemsCancelled);
export const relatedItemsTotalSelector = createSelector(getRelatedItemsSelector, shipmentItemsTotalSelector, isDeliverToFulfillmentCenterSelector, isAllRelatedItemsCancelledSelector, (rawRelatedItems, shipmentTotal, isDeliverToFulfillmentCenter, isAllRelatedItemsCancelled) => {
  let relatedItems;
  if (isAllRelatedItemsCancelled) {
    relatedItems = rawRelatedItems;
  } else {
    relatedItems = rawRelatedItems.filter(isStatedItem);
  }
  const totalPriceForItems = relatedItems.reduce((total, item) => item.price + total, 0);
  const receivingFee = isDeliverToFulfillmentCenter ? getReceivingFeeForItemsList(relatedItems) : 0;
  return totalPriceForItems + shipmentTotal + receivingFee;
});
export const relatedItemsSetupFeeSelector = createSelector(itemsByIdsSelector, items => {
  const {
    asRelatedItemId,
    setupFeeDeductionDiscount
  } = items[0];
  let relatedItems;
  if (isAllItemsCancelled(items)) {
    relatedItems = items;
  } else {
    relatedItems = items.filter(isStatedItem);
  }
  const setupFee = getMaxSetupFee(relatedItems);
  return setupFee[asRelatedItemId] - setupFeeDeductionDiscount;
});
export const itemPricePerPieceSelector = createSelector(singleItemSetupFeeSelector, itemPriceSelector, singleItemsIsRelatedSelector, singleItemsIsSharedSelector, itemQuantitySelector, itemBlendedQuantitySelector, isItemBoxProductTypeSelector, getBoxItemPriceSelector, boxAssemblyFeeSelector, isItemSampleSelector, (setupFee, price, isRelated, isShared, quantity, blendedQuantity, isBoxTypeProduct, boxPrice, boxAssemblyFee, isSample) => {
  if (isSample) {
    return price;
  }
  if (isBoxTypeProduct) {
    return Price.getPricePerItem({
      assemblyFee: boxAssemblyFee,
      quantity,
      screenPrice: 0,
      totalPriceForItems: boxPrice
    });
  }
  if (isShared || isRelated) {
    const realSetupFee = getRealSetupFee({
      isShared,
      isRelated,
      item: {
        quantity,
        blendedQuantity,
        setupFee
      }
    });
    return (price - realSetupFee) / quantity;
  }
  return (price - setupFee) / quantity;
});
export const customProductIdSelector = createSelector(singleItemInOrderSelector, ({
  customProductId
}) => customProductId);
export const userOrdersPagination = state => state.accountOrders.pagination;
export const userSearchSelector = state => state.accountOrders.search;
export const userOrdersPaginationSelector = createSelector(userOrdersPagination, pagination => preparePaginationData(pagination));
export const withPurpleWaveSelector = createSelector(loadingStateSelector, hasNoOrdersSelector, ordersToDisplaySelector, (loading, hasNoOrders, ordersToDisplay) => !loading && (hasNoOrders || ordersToDisplay.length === 0));
const itemIsReorderSelector = createSelector(singleItemInOrderSelector, item => item.isReorder);
export const itemBoxProductsSelector = createSelector(itemProdSelector, itemProductsSelector, itemIsReorderSelector, singleOrderSelector, (prod, products, isReorder, order) => {
  if (prod.designUploadMode === DesignUploadMode.box) {
    const baseProducts = (products || []).map(p => p.prod);
    return groupInitialProductsWithOrderBoxItems(baseProducts, products, isReorder, order.items);
  }
  return [];
});
export const isWarehouseFlowSelector = createSelector(singleItemInOrderSelector, singleOrderSelector, isDeliverToFulfillmentCenterSelector, (item, {
  items
}, isDeliverToFulfillment) => {
  if (!items || !items.length) {
    return false;
  }
  const boxItems = items.filter(i => i.boxItemsId && item.boxItemsId && i.boxItemsId === item.boxItemsId);
  if (boxItems.length > 1) {
    return true;
  }
  return isDeliverToFulfillment;
});
export const itemTrackingNumbersSelector = createSelector(singleItemInOrderSelector, item => item.trackingNumbers);
export const isSwagSampleBoxSelector = createSelector(singleItemInOrderSelector, item => !_isEmpty2(item.savedBoxInfo) && item.isSample);
export const isItemCancelledSelector = createSelector(singleItemInOrderSelector, isCancelledItem);
export const isItemSplitShippedToInventorySelector = createSelector(singleItemInOrderSelector, item => checkIsSplitShippedToInventory(item));
export const isItemRejectedSelector = createSelector(singleItemInOrderSelector, isItemRejected);
export const isSharedOrRelatedItemSelector = createSelector(singleItemInOrderSelector, singleOrderSelector, (item, order) => {
  return isSharedOrRelatedItem(item, order.items);
});
export const singleOrderHasDiscountsSelector = createSelector(singleOrderDeductionDiscountsSelector, listOfSampleDeductionDiscounts => {
  return (listOfSampleDeductionDiscounts === null || listOfSampleDeductionDiscounts === void 0 ? void 0 : listOfSampleDeductionDiscounts.length) > 0;
});
export const singleOrderHasShippingPriceSelector = createSelector((_, props) => props.orderShipping, orderShipping => {
  return orderShipping || orderShipping === 0;
});
export const singleItemsIsDummyPaymentSelector = createSelector(paymentMethodSelector, paymentMethod => paymentMethod === DUMMY_PAYMENT);
export const singleOrderNonInventoryItemsIdsSelector = createSelector(singleOrderItemsSelector, items => {
  const relatedItemsIdsArray = getRelatedItems(items);
  const boxItems = items.reduce((acc, item) => {
    var _item$prod;
    if (((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.designUploadMode) === 'box') {
      acc.push(item._id, ...item.products.map(item => item._id));
    }
    return acc;
  }, []);
  const updatedItems = items.reduce((acc, item) => {
    const isInBox = boxItems && boxItems.some(boxItem => boxItem === item._id);
    const isRelatedItem = relatedItemsIdsArray && relatedItemsIdsArray.some(relItem => relItem === item._id);
    if (checkIsSplitShippedToInventory(item)) {
      return acc;
    }
    if (isInBox) {
      acc.boxItems.push(item._id);
    }
    if (isRelatedItem && !item.splitShippedType) {
      acc.relatedItems.push(item._id);
    } else if (!isInBox && !isRelatedItem) {
      acc.defaultItems.push(item._id);
    } else if (isRelatedItem && item.splitShippedType) {
      acc.defaultItems.push(item._id);
    }
    return acc;
  }, {
    boxItems: [],
    relatedItems: [],
    defaultItems: []
  });
  return updatedItems;
});
export const singleOrderInventoryItemsIdsGroupByNameSelector = createSelector(isOrderForCustomStoreSelector, singleOrderItemsSelector, inventoriesSelector, isOrderSplitShippedSelector, (isInventoryOrder, items, inventories, isOrderSplitShipped) => {
  if (!isInventoryOrder && !isOrderSplitShipped) {
    return {};
  }
  const relatedItemsIdsArray = getRelatedItems(items);
  const boxItems = items.reduce((acc, item) => {
    var _item$prod2;
    if (((_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.designUploadMode) === 'box') {
      acc.push(item._id, ...item.products.map(item => item._id));
    }
    return acc;
  }, []);
  return items.reduce((acc, item) => {
    if (checkIsSplitShippedToCustomer(item) || isOrderSplitShipped && !item.inventoryId) {
      return acc;
    }
    const inventoryName = getInventoryNameById(inventories, item.inventoryId);
    const isInBox = boxItems && boxItems.some(boxItem => boxItem === item._id);
    const isRelatedItem = relatedItemsIdsArray && relatedItemsIdsArray.some(relItem => relItem === item._id);
    if (!acc[inventoryName]) {
      acc[inventoryName] = {};
      acc[inventoryName].boxItems = [];
      acc[inventoryName].relatedItems = [];
      acc[inventoryName].defaultItems = [];
    }
    if (isInBox) {
      acc[inventoryName].boxItems.push(item._id);
    }
    if (isRelatedItem && !item.splitShippedType) {
      acc[inventoryName].relatedItems.push(item._id);
    } else if (!isInBox && !isRelatedItem) {
      if (checkIsSplitShippedToInventory(item) || !isOrderSplitShipped) {
        acc[inventoryName].defaultItems.push(item._id);
      }
    } else if (isRelatedItem && item.splitShippedType) {
      acc[inventoryName].defaultItems.push(item._id);
    }
    return acc;
  }, {});
});
export const showInventoryItemsListSelector = createSelector(isOrderForCustomStoreSelector, singleOrderInventoryItemsIdsGroupByNameSelector, (isInventoryOrder, inventoryItemsIdsGroupByName) => {
  return isInventoryOrder && inventoryItemsIdsGroupByName && IS_TENANT_INVENTORY_ENABLED;
});
export const checkIsWholeBoxSuperSpeedMyOrdersSelector = createSelector(singleItemInOrderSelector, itemBoxProductsSelector, (item, boxItems) => {
  if (!boxItems.length) {
    return false;
  }
  return checkIsWholeProceededBoxSuperSpeed([item, ...boxItems]);
});
export const hasItemsToReviewPantoneColorsSelector = createSelector(itemsToReviewPantoneColorsSelector, items => Boolean(items.length));
export const hasMoreItemsToReviewPantoneColorsSelector = createSelector(itemsToReviewPantoneColorsSelector, items => items.length > 1);
export const shouldTriggerReviewPantoneColorsSelector = createSelector(itemsToReviewPantoneColorsSelector, isOrderApprovalFlowSelector, isLoggedInSelector, isDynamicBundleSelector, (items, isOrderApprovalFlow, isLoggedInSelector, isDynamicBundle) => Boolean(items.length) && !isOrderApprovalFlow && isLoggedInSelector && !isDynamicBundle);
export const hasResellerAccountOrderNetOrACHPaymentMethodSelector = createSelector(singleOrderSelector, singleOrder => {
  return getHasResellerOrderNetOrACHPaymentMethod(singleOrder);
});