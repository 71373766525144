import { DEMO_INVENTORY_WARNING_POPUP, DISTRIBUTION_SHUTDOWN_RESTRICTION_POPUP, INVENTORY_FROZEN_ACCOUNT_WARNING_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isShipoutInShutdownStateSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { isCurrentInventoryFrozenSelector, isSelectedDemoInventorySelector } from '../redux/inventory/inventory.selectors';
export const checkAccessRights = (asyncFunction, warningType, handler) => params => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return handler ? dispatch(handler(params)) : dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType
    }));
  }
  dispatch(asyncFunction(params));
};
export const checkAccountStatus = functionToExecute => params => (dispatch, getState) => {
  if (isCurrentInventoryFrozenSelector(getState())) {
    dispatch(showPopup({
      popup: INVENTORY_FROZEN_ACCOUNT_WARNING_POPUP
    }));
    return;
  }
  dispatch(functionToExecute(params));
};
export const checkShutdownRestriction = functionToExecute => params => (dispatch, getState) => {
  if (isShipoutInShutdownStateSelector(getState())) {
    dispatch(showPopup({
      popup: DISTRIBUTION_SHUTDOWN_RESTRICTION_POPUP
    }));
    return;
  }
  dispatch(functionToExecute(params));
};