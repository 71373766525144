import { getDistributionUnavailableNoticeShownPopup, getShipoutShutdownNoticeShownPopup, setDistributionUnavailableNoticeShownPopup, setShipoutShutdownNoticeShownPopup } from 'swag-client-common/utils/local-storage.util';
import { DISTRIBUTION_SHUTDOWN_RESTRICTION_POPUP, SHIPOUT_SHUTDOWN_NOTICE_POPUP } from 'swag-client-common/constants';
import { SettingsApi } from 'swag-client-common/api/settings.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showPopup } from '../popup-manager/popup-manager.actions';
import { isShipoutInPreShutdownState, isShipoutInShutdownStateSelector, shipoutShutdownDatesSelector } from './settings.selectors';
import { PREFIX } from './settings.actions';
export const showShutdownPopupBasedOnShutdownState = (dispatch, getState) => {
  const state = getState();
  const isPreShutdown = isShipoutInPreShutdownState(state);
  const isShutdown = isShipoutInShutdownStateSelector(state);
  if (isPreShutdown) {
    dispatch(showPopup({
      popup: SHIPOUT_SHUTDOWN_NOTICE_POPUP
    }));
  } else if (isShutdown) {
    dispatch(showPopup({
      popup: DISTRIBUTION_SHUTDOWN_RESTRICTION_POPUP
    }));
  }
};
export const showShipoutShutdownNoticePopup = popupToShowAfter => (dispatch, getState) => {
  const isPreShutdownAlreadyShown = getShipoutShutdownNoticeShownPopup() === true;
  const isShutdownAlreadyShown = getDistributionUnavailableNoticeShownPopup() === true;
  const state = getState();
  const isPreShutdown = isShipoutInPreShutdownState(state);
  const isShutdown = isShipoutInShutdownStateSelector(state);
  const dates = shipoutShutdownDatesSelector(state);
  const now = Date.now();
  const isEnded = dates.endDate ? now > new Date(dates.endDate).getTime() : true;
  if (isEnded || isPreShutdownAlreadyShown && isShutdownAlreadyShown || !isPreShutdown && !isShutdown) {
    if (popupToShowAfter) {
      dispatch(showPopup({
        popup: popupToShowAfter
      }));
    }
    return;
  }
  let popupToShow = popupToShowAfter !== null && popupToShowAfter !== void 0 ? popupToShowAfter : '';
  if (!isPreShutdownAlreadyShown && isPreShutdown) {
    popupToShow = SHIPOUT_SHUTDOWN_NOTICE_POPUP;
    setShipoutShutdownNoticeShownPopup(true);
  } else if (!isShutdownAlreadyShown && isShutdown) {
    popupToShow = DISTRIBUTION_SHUTDOWN_RESTRICTION_POPUP;
    setDistributionUnavailableNoticeShownPopup(true);
  }
  dispatch(showPopup({
    popup: popupToShow,
    popupToShowAfter
  }));
};
export const GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS = `${PREFIX}GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS`;
export const GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR = `${PREFIX}GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR`;
const shipoutShutdownSettingsArgs = {
  fn: SettingsApi.getShipoutShutdownSettings,
  success: payload => ({
    type: GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS,
    payload
  }),
  error: errorAction(GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR)
};
export const getShipoutShutdownSettings = createAsyncAction(shipoutShutdownSettingsArgs);