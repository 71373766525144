import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import calculateSetupFeeValue from './calculate-setup-fee-value.logic';
import { getUniqueRelatedIds } from './get-unique-related-ids.logic';
export const getMaxSetupFee = items => {
  const relatedItemIds = getUniqueRelatedIds(items);
  const maxSetupFees = {};
  relatedItemIds.forEach(id => {
    maxSetupFees[id] = Math.max.apply(null, items.filter(i => i.asRelatedItemId === id).map(i => {
      const multiplier = getItemPriceMultiplier(i);
      return calculateSetupFeeValue({
        product: i.prod,
        logos: i.logos,
        texts: i.texts,
        multiplier,
        curatedBrandStoreProductId: i.curatedBrandStoreProductId
      });
    }));
  });
  return maxSetupFees;
};