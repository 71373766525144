import { DesignUploadMode } from 'swag-common/interfaces';
import { applyMultiplier } from 'swag-common/utils/tenant-price-settings';
import { transformProductSettingsForFeature } from 'swag-common/utils/product-settings/transform-product-settings-for-feature';
import { getDefaultProductSettings } from 'swag-common/utils/items/get-item-selected-product-settings.util';
import * as Colors from '../utils/colors';
const calculateSetupFeeValue = ({
  product,
  logos = {},
  texts,
  isSample = false,
  prodTime = 0,
  multiplier = 0,
  featureFlags,
  curatedBrandStoreProductId
}) => {
  var _product$productSetti, _product$productSetti2;
  if (isSample || !product || product.designUploadMode === DesignUploadMode.fullDesign || product.designUploadMode === DesignUploadMode.box || curatedBrandStoreProductId) {
    return 0;
  }
  const productSettings = product.productSettings ? transformProductSettingsForFeature(product.productSettings, product, featureFlags) : getDefaultProductSettings(product, featureFlags);
  const {
    colorIndependentPrice,
    colorIndependentScreenPrice,
    screenPrice
  } = productSettings[0];
  const colors = Colors.getUniqueColorsNumberForAllSides(logos, texts);
  let colorsAmount = Object.values(colors).reduce((colorsSum, number) => {
    let amount = number;
    if (product.addOneColor) {
      amount += 1;
    }
    return amount + colorsSum;
  }, 0);
  if (colorIndependentPrice || colorIndependentScreenPrice) {
    colorsAmount = 1 * Object.keys(logos).length;
  }
  const productionTimeList = ((_product$productSetti = product.productSettings) === null || _product$productSetti === void 0 ? void 0 : _product$productSetti.length) && ((_product$productSetti2 = product.productSettings) === null || _product$productSetti2 === void 0 ? void 0 : _product$productSetti2[0].productionTimeList) || [];
  const timeCost = productionTimeList[prodTime] ? productionTimeList[prodTime].cost : 0;
  const setupFee = screenPrice * colorsAmount;
  const additionalProductionCost = setupFee * timeCost;
  const total = setupFee + additionalProductionCost;
  const result = applyMultiplier(total, multiplier);
  return Number(result);
};
export default calculateSetupFeeValue;