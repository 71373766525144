import { PREFIX } from './settings.actions';
export { showShipoutShutdownNoticePopup, GET_SHIPOUT_SHUTDOWN_SETTINGS_SUCCESS, GET_SHIPOUT_SHUTDOWN_SETTINGS_ERROR, getShipoutShutdownSettings } from './settings-shared.actions';
export const GET_SUPER_SPEED_SUCCESS = `${PREFIX}GET_SUPER_SPEED_SUCCESS`;
export const GET_SUPER_SPEED_ERROR = `${PREFIX}GET_SUPER_SPEED_ERROR`;
const payload = {
  config: {
    enabled: false,
    multiplier: 0,
    numOfDays: 1
  }
};
export const getSuperSpeedSettings = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const getSuperSpeedSettingsForProductBuilder = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const validateSuperSpeedItemInCart = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});
export const validateSuperSpeedForCheckout = () => ({
  type: GET_SUPER_SPEED_SUCCESS,
  payload
});