function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { push } from 'react-router-redux';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { SendToMultipleAddressesStep, INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
import { downloadFileInBrowser } from 'swag-client-common/utils/download-file-in-browser';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { DEMO_INVENTORY_WARNING_POPUP } from 'swag-client-common/constants';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { CREDITS } from 'swag-common/constants/payment-methods';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { emailSelector } from 'swag-client-common/redux/user/user.selectors';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { userClickedSmaButton } from 'swag-client-common/redux/analytics/analytics.actions';
import { promocodeSelector } from '../payment/payment.selectors';
import { inventoryActivitiesSearchQuerySelector, isCreditsMoreToBuyFlowSelector, isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { deselectSimpleProduct, listInventoryProducts } from '../products/products.actions';
import { selectedProductsOutOfStockSelector } from '../products/products-stocks.selectors';
import { resetCalculation, setActiveStep } from '../send-to-multiple-addresses/send-to-multiple-addresses.actions';
import { isShipoutPaymentMethodDefault, selectedDomesticShippingMethodSelector, selectedInternationalShippingMethodSelector, sendToMultipleAddressesIdSelector, shipoutPaymentMethodSelector } from '../send-to-multiple-addresses/send-to-multiple-addresses.selectors';
import { getInventoryCredits, getPendingActivitiesAction, inventoryLoadedAsyncParameters, resetCreditsMoreToBuyFlow } from '../inventory/inventory.actions';
import { removePromocode } from '../payment/payment.actions';
import { mergeCreateAsyncOptions } from '../redux.utils';
import { inventoryPaymentBillingFormSelector, isManualPayment, paymentMethodIdSelector } from '../payment-methods/payment-methods.selectors';
import { preparePaymentToInventoryProceed } from '../payment-methods/payment-methods.actions';
import { TRACKING_INFO_CSV_KEYS } from '../../utils/constants';
import { checkAccessRights, checkShutdownRestriction } from '../../utils/enterprise-access-wrapper';
import { hideLoader, showLoader } from '../../../common/redux/common/common.actions';
import ShipOutApi from '../../api/shipout.api';
import { getItemsForAnalytics } from '../../utils/get-items-for-analytics';
import { getAdvancedDistributionForProducts } from '../advanced-distributions/advanced-distributions.actions';
import { setInventoryPurchaseFlow } from './../inventory/inventory.actions';
import { STRIPE_FORM_SUBMIT_SUCCESS, STRIPE_FORM_SUBMIT_ERROR, resetInventoryStripeFormValidations } from './../stripe-form/stripe-form.actions';
import { getShipoutDetailsCsvData } from './store-shipouts.selectors.utils';
import { csvShipoutsSelector, getShipoutByIdSelector, selectedShipoutIdSelector, shipoutPageSelector } from './store-shipouts.selectors';
const PREFIX = 'STORE_SHIPOUTS_';
const inventoryLoadedAsyncParametersObject = inventoryLoadedAsyncParameters();
const startSMAWithSelectedProducts = buttonLocation => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  const email = emailSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  dispatch(userClickedSmaButton({
    userId,
    email,
    inventoryId,
    btnLocation: buttonLocation
  }));
  const selectedProductsOutOfStock = selectedProductsOutOfStockSelector(getState());
  if (selectedProductsOutOfStock.length > 0) {
    dispatch(deselectSimpleProduct());
    return dispatch(showNotification({
      text: `Products that are not in stock can not be shipped out: ${selectedProductsOutOfStock.map(({
        name
      }) => name).join(', ')}`,
      hideIn: 8000
    }));
  }
  dispatch(push(InventoryUrl.getCreateSMA(inventoryId)));
};
export const startSMAWithSelectedProductsWrappedIntoCheckAccountStatus = buttonLocation => dispatch => {
  dispatch(startSMAWithSelectedProducts(buttonLocation));
};
export const onShipOutSelectedProductsClick = checkShutdownRestriction(startSMAWithSelectedProductsWrappedIntoCheckAccountStatus);
export const SELECT_SHIPOUT = `${PREFIX}SELECT_SHIPOUT`;
export const selectShipout = id => ({
  type: SELECT_SHIPOUT,
  payload: id
});
export const LIST_CSV_SHIPOUTS_REQUEST = `${PREFIX}LIST_CSV_SHIPOUTS_REQUEST`;
export const LIST_CSV_SHIPOUTS_SUCCESS = `${PREFIX}LIST_CSV_SHIPOUTS_SUCCESS`;
export const LIST_CSV_SHIPOUTS_FAIL = `${PREFIX}LIST_CSV_SHIPOUTS_FAIL`;
const listCsvShipoutsParams = {
  transformData: (data, getState) => {
    const state = getState();
    const selectedInventoryId = selectedInventorySelector(state);
    const page = shipoutPageSelector(state);
    const search = inventoryActivitiesSearchQuerySelector(state);
    const params = {
      page,
      inventoryId: selectedInventoryId
    };
    if (search) {
      params.search = search;
    }
    return params;
  },
  preFn: dispatch => {
    dispatch({
      type: LIST_CSV_SHIPOUTS_REQUEST
    });
    dispatch(showLoader());
  },
  fn: ShipOutApi.listCsv,
  success: ({
    total,
    shipouts,
    searchResults
  }) => ({
    type: LIST_CSV_SHIPOUTS_SUCCESS,
    payload: {
      shipouts,
      total,
      searchResults
    }
  }),
  error: err => ({
    type: LIST_CSV_SHIPOUTS_FAIL,
    err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
};
export const listCsvShipouts = createAsyncAction(listCsvShipoutsParams);
export const listCsvShipoutsEntering = createAsyncAction(mergeCreateAsyncOptions(listCsvShipoutsParams, inventoryLoadedAsyncParametersObject));
export const LIST_PFI_SHIPOUTS_REQUEST = `${PREFIX}LIST_PFI_SHIPOUTS_REQUEST`;
export const LIST_PFI_SHIPOUTS_SUCCESS = `${PREFIX}LIST_PFI_SHIPOUTS_SUCCESS`;
export const LIST_PFI_SHIPOUTS_FAIL = `${PREFIX}LIST_PFI_SHIPOUTS_FAIL`;
const listPfiShipoutsParams = {
  transformData: (data, getState) => {
    const state = getState();
    const selectedInventoryId = selectedInventorySelector(state);
    const page = shipoutPageSelector(state);
    const search = inventoryActivitiesSearchQuerySelector(state);
    const params = {
      page,
      inventoryId: selectedInventoryId
    };
    if (search) {
      params.search = search;
    }
    return params;
  },
  preFn: dispatch => {
    dispatch({
      type: LIST_PFI_SHIPOUTS_REQUEST
    });
    dispatch(showLoader());
  },
  fn: ShipOutApi.listPfi,
  success: ({
    shipouts,
    total,
    searchResults
  }) => ({
    type: LIST_PFI_SHIPOUTS_SUCCESS,
    payload: {
      shipouts,
      total,
      searchResults
    }
  }),
  error: err => ({
    type: LIST_PFI_SHIPOUTS_FAIL,
    err
  }),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
};
export const listPfiShipouts = createAsyncAction(listPfiShipoutsParams);
export const listPfiShipoutsEntering = createAsyncAction(mergeCreateAsyncOptions(listPfiShipoutsParams, inventoryLoadedAsyncParametersObject));
export const DESELECT_SHIPOUT = `${PREFIX}DESELECT_SHIPOUT`;
export const deselectShipout = () => ({
  type: DESELECT_SHIPOUT
});
function getEnclosedFields(fields, keys) {
  return fields.map(field => (field || '').replace('"', '""')).map((field, index) => {
    if (keys && keys[index] === TRACKING_INFO_CSV_KEYS.TRACKING_NUMBER) {
      return field.length ? `="${field}"` : field;
    }
    return field.length ? `"${field}"` : field;
  }).join(',');
}
export const downloadShipmentDetailsCsv = shipoutId => (dispatch, getState) => {
  const shipouts = csvShipoutsSelector(getState());
  const shipout = shipouts.find(({
    _id
  }) => _id === shipoutId);
  if (!shipout) {
    return dispatch(showNotification({
      text: 'Sorry, such shipout not found'
    }));
  }
  const {
    headers,
    data,
    keys
  } = getShipoutDetailsCsvData(shipout);
  const csvData = `${getEnclosedFields(headers)}\n${data.map(row => `${getEnclosedFields(row, keys)}\n`).join('')}`;
  const blob = new Blob(['', csvData], {
    type: 'text/csv'
  });
  downloadFileInBrowser({
    blob,
    name: 'swag_shipout_details.csv'
  });
};
export const REMOVE_SHIPOUT_ERROR = `${PREFIX}REMOVE_SHIPOUT_ERROR`;
export const REMOVE_SHIPOUT_SUCCESS = `${PREFIX}REMOVE_SHIPOUT_SUCCESS`;
export const removeSelectedShipoutRequest = createAsyncAction({
  preFn: dispatch => {
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const shipoutId = selectedShipoutIdSelector(getState());
    const inventoryId = selectedInventorySelector(getState());
    return {
      shipoutId,
      inventoryId
    };
  },
  fn: ShipOutApi.removeShipout,
  success: (receiver, {
    shipoutId
  }) => ({
    type: REMOVE_SHIPOUT_SUCCESS,
    payload: shipoutId
  }),
  error: errorAction(REMOVE_SHIPOUT_ERROR),
  postSuccess: dispatch => {
    dispatch(hideLoader());
    dispatch(hidePopup());
    dispatch(resetCalculation());
    dispatch(showNotification({
      text: 'Shipment was removed'
    }));
  },
  postError: dispatch => {
    dispatch(hideLoader());
    dispatch(showNotification({
      text: "Sorry, we couldn't remove this shipment"
    }));
  }
});
export const removeSelectedShipout = () => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return;
  }
  dispatch(removeSelectedShipoutRequest());
};
export const SHIPOUT_PAYMENT_STARTED = `${PREFIX}SHIPOUT_PAYMENT_STARTED`;
export const SHIPOUT_PAYMENT_SUCCESS = `${PREFIX}SHIPOUT_PAYMENT_SUCCESS`;
export const SHIPOUT_PAYMENT_ERROR = `${PREFIX}SHIPOUT_PAYMENT_ERROR`;
export const payForShipoutFactory = ({
  showNotifications
}) => {
  const request = createAsyncLoadedAction({
    preFn: dispatch => {
      dispatch(showLoader());
      dispatch({
        type: SHIPOUT_PAYMENT_STARTED
      });
    },
    transformData: ({
      stripeToken,
      savedCard
    } = {
      stripeToken: null
    }, getState) => {
      const state = getState();
      const shipoutId = sendToMultipleAddressesIdSelector(state);
      const promocode = promocodeSelector(state);
      const paymentMethod = shipoutPaymentMethodSelector(state);
      const selectedDomesticShippingMethod = selectedDomesticShippingMethodSelector(state);
      const selectedInternationalShippingMethod = selectedInternationalShippingMethodSelector(state);
      const inventoryId = selectedInventorySelector(state);
      const commonBody = {
        shipoutId,
        inventoryId,
        paymentMethod,
        promocode: promocode ? promocode.code : null,
        selectedDomesticShippingMethod,
        selectedInternationalShippingMethod
      };
      if (savedCard) {
        const cardId = paymentMethodIdSelector(state);
        return _objectSpread({}, commonBody, {
          cardId
        });
      }
      const form = inventoryPaymentBillingFormSelector(state);
      if (form) {
        const {
          savePaymentDetails
        } = form.values;
        return _objectSpread({}, commonBody, {
          stripeToken,
          savePaymentDetails
        });
      }
      return _objectSpread({}, commonBody, {
        stripeToken
      });
    },
    fn: ShipOutApi.payForShipout,
    success: shipout => (dispatch, getState) => {
      dispatch({
        type: STRIPE_FORM_SUBMIT_SUCCESS
      });
      const state = getState();
      const userId = userIdSelector(state);
      const email = emailSelector(state);
      const inventoryId = selectedInventorySelector(state);
      const items = getItemsForAnalytics(shipout);
      dispatch({
        type: SHIPOUT_PAYMENT_SUCCESS,
        payload: _objectSpread({}, shipout, {
          analytics: {
            userId,
            email,
            inventoryId,
            totalPaid: shipout.totalAmountPaid,
            items
          }
        })
      });
      const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(state);
      if (isCreditsToBuyMoreFlow) {
        dispatch(resetCreditsMoreToBuyFlow());
      }
      const paymentMethod = shipoutPaymentMethodSelector(state);
      if (paymentMethod === CREDITS) {
        const inventoryId = selectedInventorySelector(state);
        dispatch(getInventoryCredits({
          inventoryId
        }));
      }
    },
    error: error => dispatch => {
      dispatch({
        type: STRIPE_FORM_SUBMIT_ERROR
      });
      dispatch({
        type: SHIPOUT_PAYMENT_ERROR,
        error
      });
    },
    postError: (dispatch, getState, error) => {
      dispatch(hideLoader());
      dispatch(setInventoryPurchaseFlow(null));
      dispatch(showNotification({
        text: error.message || USER_ERROR_ACTIONS.INVOICE_COULD_NOT_SEND
      }));
    },
    postSuccess: (dispatch, getState, _, res) => {
      const inventoryId = selectedInventorySelector(getState());
      dispatch(resetInventoryStripeFormValidations());
      dispatch(hideLoader());
      dispatch(removePromocode());
      dispatch(getPendingActivitiesAction({
        inventoryId
      }));
      dispatch(listInventoryProducts({
        inventoryId: selectedInventorySelector(getState())
      }));
      dispatch(setInventoryPurchaseFlow(null));
      dispatch(setActiveStep(SendToMultipleAddressesStep.success));
      if (showNotifications) {
        dispatch(showNotification({
          text: 'Please check your email for a shipout invoice'
        }));
      } else if (res.totalAmountPaid === 0) {
        dispatch(showPopup({
          popup: 'freePaymentSuccessPopup',
          params: {
            pfiId: res._id
          }
        }));
      }
      dispatch(hidePopup());
    }
  });
  return checkAccessRights(request, DemoInventoryWarningTypes.Products);
};
export const payForShipoutCreditCard = payForShipoutFactory({
  showNotifications: false
});
export const payForShipoutInvoice = payForShipoutFactory({
  showNotifications: true
});
export const handlePaymentRequest = () => (dispatch, getState) => {
  const state = getState();
  if (isSelectedDemoInventorySelector(state)) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP
    }));
  }
  const isCreditCardPaymentMethod = isShipoutPaymentMethodDefault(state);
  dispatch(setInventoryPurchaseFlow(INVENTORY_PURCHASE_FLOWS.SMA));
  if (!isCreditCardPaymentMethod) {
    dispatch(payForShipoutInvoice({
      stripeToken: null
    }));
  } else {
    dispatch(setActiveStep(SendToMultipleAddressesStep.billing));
  }
};
export const handleInventoryPaymentForSMASubmit = (stripe, card) => (dispatch, getState) => {
  const isManualCard = isManualPayment(getState());
  if (isManualCard) {
    dispatch(preparePaymentToInventoryProceed(stripe, card, token => dispatch(payForShipoutCreditCard({
      stripeToken: token.id
    }))));
  } else {
    dispatch(payForShipoutCreditCard({
      savedCard: true
    }));
  }
};
export const SET_PAGE = `${PREFIX}SET_PAGE`;
export const setPage = page => ({
  type: SET_PAGE,
  payload: {
    page
  }
});
export const getAdvancedDistributionForDeletingShipout = () => (dispatch, getState) => {
  const state = getState();
  const shipoutId = selectedShipoutIdSelector(state);
  const shipout = getShipoutByIdSelector(state, shipoutId);
  const productIds = ((shipout === null || shipout === void 0 ? void 0 : shipout.products) || []).map(({
    _id
  }) => String(_id));
  if (productIds.length) {
    dispatch(getAdvancedDistributionForProducts({
      productIds
    }));
  }
};